import React from 'react';
import photo1 from "../../../resources/images/blog/diploma/Background Photo.png"
import photo2 from "../../../resources/images/blog/diploma/Double Mat.png";
import photo3 from "../../../resources/images/blog/diploma/Single Mat.png";
import photo4 from "../../../resources/images/blog/diploma/Double Mat with Writing and Logo.png";
import photo5 from "../../../resources/images/blog/diploma/Single Mat with Logo.png";
import blogArticle1Regular32 from "../../../resources/images/gallery/1-articleOne/reduced/3-2.png";

import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";
import { TikTok } from "react-tiktok";



export default class StylishAffordableWaysToFrameYourDegree extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>Stylish and Affordable Ways to Frame your University Degrees</title>
                <meta name="description" content="Custom matboards allow you to choose your own size, color, and design to create a one-of-a-kind diploma frame that’s perfect for your diploma. You can even add a personal touch by having your school name and/or logo, your name, or even your favorite quote engraved on your matboard."/>
            </Helmet>



            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Stylish and Affordable Ways to Frame your University Degrees
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Diplomas & Certificates
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Danielle M<br/>Custom Mat Project Blogger
            </div>

            <TikTok url="https://www.tiktok.com/@custommat/video/7106680001117916422?is_from_webapp=1&sender_device=pc&web_id=7101339422562797062" />


            <div className={"blog-article-content"}>

                One of the most expensive things I have is my college degree. 🤣 When I got my college degree, I had an option to have my university degree framed by the school of school bookstore, but it costed hundreds of dollars, so I have been delaying the day of framing my degree.
                <br/><br/>

                So if you’re anything like me, your degrees have been sitting in storage (in my case, at my parent’s house) for months, maybe even years. Well it’s time to show them off, cuz you earned them! Whether you’re a fresh graduate or you finished school decades ago, here are some stylish and affordable ways to display your degrees.

                <br/><br/>

                One of the most common ways to display degrees is by using a custom matboard. College diplomas are usually 14x17 inches while high school diplomas are smaller, so finding a frame that fits both sizes can be difficult and expensive.

                <br/><br/>

                You can get them custom framed -- however, getting a custom frames for your university diploma often hovers over $200 dollars. So that's why we came up with an alternative approach:

                <br/><br/>

                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <br/><br/>

                <b>
                    CUSTOM MATBOARD + ANY PICTURE FRAME = CUSTOM DIPLOMA FRAME
                </b>

                <br/><br/>

                Custom matboards allow you to choose your own size, color, and design to create a one-of-a-kind diploma frame that’s perfect for your diploma. You can even add a personal touch by having your school name and/or logo, your name, or even your favorite quote engraved on your matboard.

                <br/><br/>

                To make things even easier, we’ve created a few matboard designs that are perfect for displaying degrees.

                <br/><br/>

                If you want to add a little bit more flair to your degree display, try using a double matting options. Double matboards have two layers of mats with different colors or patterns. This creates a bold look  that is sure to make your diploma stand out.

                <br/><br/>

                <div className={"visit-custom-mat-button"}>
                    <a href={"diploma-frame-matboards"} className={"carousel-read-more-button"}>
                        Start Designing
                    </a>
                </div>

                No matter what style you choose, displaying your degree in a custom matboard is a great way to show it off without spending a lot of money. By combining our Custom Diploma / Certificat Matboard with any Standard Sized Frames (you can get them from anywhere -- like Amazon, Etsy, Costco, Target, Walmart, IKEA, or Michael's) you can get a custom diploma frame for about $50-70 dollars.

                <br/><br/>



                <LazyLoad>
                    <img src={blogArticle1Regular32} className={"blog-carousel-photo"}/>
                </LazyLoad>

                <br/><br/>

                What are some other ways you like to display your degrees and certificates? Let's get it started from <a href={"/diploma-frame-matboards"}>here</a>.


                <br/><br/>

                If you enjoyed this post, be sure to check out our other blog post: "Budget Friendly Diploma Framing & More". In it, we share even more tips on how to choose the perfect frame.


                <br/><br/>

                All our orders can be shipped to anywhere in US and Canada for free. Free shipping across Canada & the USA

                <br/><br/>

                We do have a very generous return policy. 100% Happiness Guaranteed. If you’re not happy with your order, let us know and we’ll make it right. We take pride in our workmanship.

                <br/><br/>

                Please feel free to reach out to us at hello@custommat.ca if you have any questions. We are here to help.

                <br/><br/>

                The Custom Mat Team 🧡🖤💙

                <br/><br/>

                P.S. Follow us on Instagram (@custommat.ca) for daily dose of inspiration and matboard love. Tag us in your photos so we can see how you've used our products. We often share  customer photos on our feed and stories. We can't wait to see what you create! 📸

                <br/><br/>

                There are so many fun ideas. Gallery wall for your wedding, family, kids, pets, and the list goes on and on.

                Here are some photos from our actual customers. Check out our <b><a href={"/inspiration"}>Customer Photo Gallery</a></b> here.


                <br/><br/>

                Here are some instructions on how to create beautiful gallery walls at a fraction of traditional custom framing costs.

                <ol>
                    <li><a href={"https://www.custommat.ca/ikea-frame-matboard-sizing-guide"}>IKEA Ribba Picture Frame Gallery Wall</a></li>
                    <li><a href={"https://www.custommat.ca/creating-home-office"}>Home Office Gallery Wall</a></li>
                    <li><a href={"https://www.custommat.ca/save-huge-on-photo-frames-with-passe-partout-photo-mats"}>Bedside Table Gallery Wall</a></li>
                    <li><a href={"https://www.custommat.ca/oversized-mat-frames-with-ikea-ramsborg"}>Wedding Gallery Wall</a></li>
                    <li><a href={"https://www.custommat.ca/jersey-sports-shadow-box-memorabilia-matting"}>Sports Room Gallery Wall</a></li>
                    <li><a href={"https://www.custommat.ca/diy-oversized-mat-amp-square-family-photo-frames"}>Square Family Photo Frames for Gift</a></li>
                    <li><a href={"https://www.kendrafoundit.com/blog/diy-eclectic-vintage-gallery-wall "}>Recycling your old frames</a></li>
                </ol>



                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>



            </div>

            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>



        </div>
    }
}