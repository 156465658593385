import React from "react";
import localStorageFunctions from "../comp-localStorage/localStroage";
import {Helmet} from "react-helmet";
import analyticsDataToDB from "../comp-analyticsLog/dbLogging";
import checkLatestVersion from "./comp-landingPage/checkLatestVersion";
import InstagramLoader from "./instagramLoader";
import WhatIsMatboard from "../page-howItWorks/whatIsMatboard";
// import Explainer from "../page-howItWorks/explainer";
import ReviewComponent from "../app-review/reviewComponent";
import LandingPageVideo from "./comp-landingPage/landingPageVideo";
import LandingPageFAQ from "./landingPageFAQ";
import SatisfactionTransition from "./satisfactionTransition";
import LandingPageReviewStars from "./landingPageReviewStars";
import HowItWorksV2 from "../page-howItWorks/howItWorksV2";
import Explainer from "../page-howItWorks/explainer";
import benefitsPhotoLeft from "../../../resources/images/landingPage/benefitsPageLeft.jpg";
import benefitsPhotoCtr from "../../../resources/images/landingPage/benefitsPageCtr.jpg";
import benefitsPhotoRight from "../../../resources/images/landingPage/benefitsPageRight.jpg";
import save from '../../../resources/svgIcons/money-bill-1-solid.svg';
import crop from '../../../resources/svgIcons/crop-simple-solid.svg';
import delivery from '../../../resources/svgIcons/delivery.svg';
import review from '../../../resources/svgIcons/review.svg';
import rect1 from "../../../resources/images/landingPage/rect1.png";
import rect2 from "../../../resources/images/landingPage/rect2.png";
import rect3 from "../../../resources/images/landingPage/rect3.png";
import matTransitionPhotoLeft from "../../../resources/images/landingPage/mat-transition-left.png";
import matTransitionPhotoRight from "../../../resources/images/landingPage/mat-transition-right.png";
import howItWorksTransitionImg1 from "../../../resources/images/landingPage/howItWorksTransitionImg1.png";
import howItWorksTransitionImg2 from "../../../resources/images/landingPage/howItworksTransitionImg2.png";
import howItWorksTransitionImg3 from "../../../resources/images/landingPage/howItWorksTransitionImg3.png";



export default class LandingPage extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      // testValue: 1,
      scrolled: false,
      currencySelected: "CAD",
      displayType: "type1",
      french: (window.location.pathname === "/fr" || this.props.language === "fr") ? true : false,
    };

    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.logToDbOnHovering = this.logToDbOnHovering.bind(this);
  };

  componentDidMount() {
    var storedCurrency = localStorageFunctions("qHysYpo5yeED836ap5UzjYIkIAKO147dX1CXetXw", null);

    if (storedCurrency !== null && storedCurrency !== undefined && storedCurrency !== "") {
      this.setState({currencySelected: storedCurrency})
    };

    checkLatestVersion();

    //below two are causing memory leak -- cannot and should not handle asynchronous on component did mount page
    //react version update needed

    if (this.state.scrolled === false) {
      document.addEventListener('scroll', (evt) => this.handleScroll());
    }

    setInterval(() => {this.updateDisplayType()}, 4000);
  };

  updateDisplayType() {
    if (this.state.displayType === "type1") {
      this.setState({displayType: "type2"});
    } else if (this.state.displayType === "type2") {
      this.setState({displayType: "type3"});
    } else if (this.state.displayType === "type3") {
      this.setState({displayType: "type1"});
    }
  };

  handleScroll() {
    this.setState({scrolled: true});
  };

  handleValueChange(val){
    this.setState({testValue: val});
  };

  handleCurrencyChange(currency) {
    this.setState({currencySelected: currency});
  };

  logToDbOnHovering (hoveredSectionName) {
    analyticsDataToDB("landing-page-sections", hoveredSectionName);
  };

  fetchBenefits() {
    return       <div className={"benefits-page-container"}>

      {/*<div className={"benefits-page-img-container"}>*/}
      {/*  <img className={"benefits-page-container-img-side"} src={benefitsPhotoLeft}/>*/}

      {/*  <img className={"benefits-page-container-img-ctr"} src={benefitsPhotoCtr}/>*/}

      {/*  <img className={"benefits-page-container-img-side"} src={benefitsPhotoRight}/>*/}
      {/*</div>*/}

      <div className={"benefits-summary-container"}>
        <div className={"benefits-summary-component"}>
          <img src={save} className={"benefits-summary-component-img"}/>
          <div className={"benefits-summary-component-lead"}>
            {this.state.french ? "Prix Imbattables" : "Great Prices"}
          </div>
          <div className={"benefits-summary-component-text"}>
            {this.state.french ? "Jusqu'à 90 % moins cher, avec des réductions quantitatives." : "Up to 90% less than custom framing, with quantity discounts."}

          </div>
        </div>
        <div className={"benefits-summary-component"}>
          <img className={"benefits-summary-component-img"} src={crop}/>
          <br/>
          <div className={"benefits-summary-component-lead"}>
            {this.state.french ? "Ajustement parfait assuré" : "Guaranteed Perfect Fit"}


          </div>
          <div className={"benefits-summary-component-text"}>
            {this.state.french ? "Personnalisé selon vos spécifications." : "Customized to your specifications."}
          </div>
        </div>

        <div className={"benefits-summary-component"}>
          <img className={"benefits-summary-component-img"} src={delivery}/>
          <div className={"benefits-summary-component-lead"}>
            {this.state.french ? "Traitement ultra-rapide" : "Super Fast Turnaround"}
          </div>
          <div className={"benefits-summary-component-text"}>


            <span>{this.state.french ? "Expédition sous" : "Ships within"}</span><br/>
            <span>{this.state.french ? "2 jours ouvrables." : "2 business days."}</span>
          </div>
        </div>
        <div className={"benefits-summary-component"}>
          <img className={"benefits-summary-component-img"} src={review}/>
          <div className={"benefits-summary-component-lead"}>
            {this.state.french ? "2 000+ avis 5 étoiles" : "4,000+ 5 star reviews"}


          </div>
          <div className={"benefits-summary-component-text"}>
            {this.state.french ? "Remboursement ou recoupe si insatisfaction." : "We will recut or refund you if you are not 100% satisfied."}
          </div>
        </div>
      </div>

    </div>
  };

  customizableSection() {
    if (this.state.french) {
      return <div className={"customizable-container"}>
        <div className={"customizable-subcontainer"}>
          <div className={"customizable-head"}>
            Complètement personnalisable
          </div>
          <div className={"customizable-subhead"}>
            Nous pouvons découper des passe-partout jusqu'à 32 x 40 pouces, et nous pouvons ajouter autant de <b>fenêtres intérieures</b> que vous le souhaitez <b>sans frais supplémentaires.</b>
          </div>
        </div>
        <div className={"customizable-image-container"}>
          <img className={"customizable-image customizable-image-left"} src={rect1} />
          <img className={"customizable-image customizable-image-ctr"} src={rect2} />
          <img className={"customizable-image customizable-image-right"} src={rect3} />
        </div>

      </div>
    } else {
      return <div className={"customizable-container"}>
        <div className={"customizable-subcontainer"}>
          <div className={"customizable-head"}>
            Completely Customizable
          </div>
          <div className={"customizable-subhead"}>
            We can trim matboards up to 32 x 40 inches,<br className={"mobile-no-display"}/> and we can add as many <b>interior windows</b> as you want at <b>no extra charge.</b>
          </div>
        </div>
        <div className={"customizable-image-container"}>
          <img className={"customizable-image customizable-image-left"} src={rect1} />
          <img className={"customizable-image customizable-image-ctr"} src={rect2} />
          <img className={"customizable-image customizable-image-right"} src={rect3} />
        </div>

      </div>
    }

  }

  matboardTransitionSection() {
    return <div className={"mat-transition-container"}>
      <img className={"mat-transition-img-left"} src={matTransitionPhotoLeft} />

      <img className={"mat-transition-img-right"} src={matTransitionPhotoRight} />
    </div>
  }

  fetchHowItWorksTransiion() {
    return <div className={"mat-transition-container"} id={"landing-page-bottom-transition"}>
      <img className={"howItWorks-transition-img-left mobile-no-display tablet-no-display"} src={howItWorksTransitionImg1} />

      <img className={"howItWorks-transition-img-ctr"} src={howItWorksTransitionImg2} />

      <img className={"howItWorks-transition-img-left mobile-and-tablet-display-only"} src={howItWorksTransitionImg1} />

      <img className={"howItWorks-transition-img-right"} src={howItWorksTransitionImg3} />

    </div>
  }

  render() {
    return <div className="landing-page-container">
      <Helmet>
        <title>
          {this.state.french ? "Passe-partouts sur mesure | Toutes tailles, formes et couleurs | Livraison gratuite et retours faciles" :
              "Custom Cut Matboards | Any Size, Shape, Colors | Free Shipping & Easy Returns"}
        </title>
        <meta name="description" content={
          this.state.french ? "Découvrez nos passe-partouts sur mesure livrés à votre porte avec une expédition gratuite à travers le Canada et les États-Unis. Créez votre propre passe-partout personnalisé pour s'adapter à vos œuvres d'art dans des cadres de taille standard d'Amazon, IKEA et d'autres magasins. Production rapide en 2 jours ouvrables. Retours faciles et satisfaction client à 100% garantie. Personnalisez votre passe-partout dans la couleur, la taille et la forme de votre choix. Expédition vers le Canada et les États-Unis. Cadres photo personnalisés abordables à partir de 8,95$CA - 39,95$CA. Commandez en ligne pour la livraison. Profitez de la livraison gratuite pour les commandes de plus de 49 $. Diverses tailles et formes disponibles. Expédition rapide et abordable au Canada et aux États-Unis." :
              "Discover custom cut matboards delivered to your door with free shipping across Canada and the US. Create your own custom matboard to fit your artwork in standard-sized frames from Amazon, IKEA, and other stores. Quick production in 2 business days. Easy returns and 100% customer happiness guaranteed. Customize your mat board in your preferred color, size, and shape. Ships to Canada & USA. Affordable custom picture frames from CA$ 8.95 - CA$ 39.95. Order online for delivery. Enjoy free shipping on all orders. Various sizes and shapes available. Fast, affordable shipping within Canada & the USA."
        } />
      </Helmet>

      <LandingPageVideo
          french={this.state.french}
      />



      <WhatIsMatboard
          french={this.state.french}
      />

      {this.customizableSection()}

      {this.matboardTransitionSection()}


      {this.fetchBenefits()}

      <Explainer
          titleNoDisplay={false}
      />

      {this.fetchHowItWorksTransiion()}


      <LandingPageReviewStars
          h1Tag={"Custom Cut Matboards: Free Shipping & Customization | Any Size, Shape, Color"}
          french={this.state.french}
      />




      <div className={"product-demo-page-v2"}>
        <InstagramLoader
            french={this.state.french}
        />
      </div>

      {/*<ReviewComponent*/}
      {/*    french={this.state.french}*/}
      {/*/>*/}

      <SatisfactionTransition
          h1Tag={"Custom Cut Matboards: Free Shipping & Customization | Any Size, Shape, Color"}
          french={this.state.french}
      />


      <LandingPageFAQ
          french={this.state.french}
          location = {this.props.location}
      />

    </div>
  }
}