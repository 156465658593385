import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import fetchBanner from "../comp-banner/fetchBanner";
import fetchImage from "../comp-image/fetchImage";
import closeOutIcon from "../../../resources/svgIcons/close-out.png";
import ImageGallery from "react-image-gallery";
import localStorageFunctions from "../comp-localStorage/localStroage";


export default class SelectFrameFramework extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      hoveredFrameColourCategory: '',
      selectedFrameColourCategory: '',
      selectedFrameType: "classicWhite",
      selectedFrameSize: '',
      showModal: false,
    };
    this.escFunction = this.escFunction.bind(this);
  };

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  escFunction(event){
    if(event.keyCode === 27) {
      this.setState({showModal: false, showBackgroundImageModal: false})
    }
  }

  productCategoryAction(mouseAction, color) {
    if (mouseAction === "enter") {
      this.setState({hoveredFrameColourCategory: color});
    } else if (mouseAction === "leave") {
      this.setState({hoveredFrameColourCategory: null});
    } else if (mouseAction === "click") {
      this.setState({selectedFrameColourCategory: color === this.state.selectedFrameColourCategory ? null : color});
    }
  };

  fetchFrameOptions() {
    var frameCategoryKeys = Object.keys(this.props.productDBFrameCategory);
    return frameCategoryKeys.map((item, key) => {
      return <div className={"frame-category-container"} key={`frame-category-container-${key}`}>
        {fetchImage(
            this.props.productDBFrameCategory[item].png,
            this.props.productDBFrameCategory[item].webp,
            `frame-category-image ${this.state.selectedFrameColourCategory === item ? "frame-category-selected" : ""}`,
            `Please click here to show products that are ${item}`,
            "",
            (evt) => {this.productCategoryAction("enter", item)},
            (evt) => {this.productCategoryAction("leave", item)},
            (evt) => {this.productCategoryAction("click", item)}
        )}
        <div className={`frame-category-desc ${item === this.state.hoveredFrameColourCategory ? "display" : "no-display"}`}>
          {this.props.productDBFrameCategory[item].desc}
        </div>
      </div>
    });
  };

  fetchProducts() {
    var productListToFetch;

    if (this.state.selectedFrameColourCategory === "white") {
      productListToFetch = ["simplyWhite", "classicWhite", "elegantWhite", "ornamentalWhite"];
    } else if (this.state.selectedFrameColourCategory === "black") {
      productListToFetch = ["simplyBlack", "classicBlack", "plushBlack"];
    } else if (this.state.selectedFrameColourCategory === "aspen") {
      productListToFetch = ["patternedAspen"];
    } else if (this.state.selectedFrameColourCategory === "walnut") {
      productListToFetch = ["patternedWalnut"];
    } else if (this.state.selectedFrameColourCategory === "gold") {
      productListToFetch = ["metallicGold", "vintageGold"];
    } else if (this.state.selectedFrameColourCategory === "silver") {
      productListToFetch = ["metallicSilver", "vintageSilver"];
    } else {
      productListToFetch = Object.keys(this.props.productDBFrame.frameLists);
    }

    return productListToFetch.map((item, key) => {
      return <div className={"frame-product-container"} key={`frame-product-container=${key}`}>
        {fetchImage(this.props.productDBFrame.frameLists[item].png, this.props.productDBFrame.frameLists[item].webp, `frame-product-preview-photo`, `This is a product photoshoot for ${item}`, "", "", "", "")}

        <div className={"frame-product-description"}>

          <div className="product-divider"></div>

          <div className={"product-title product-listing-header"}>
            {this.props.productDBFrame.frameLists[item].productTitle}
          </div>

          <div className={"product-description product-listing-desc"}>
            <div className={"navbar-column-title"}>Size Range</div>
            {this.props.productDBFrame.frameLists[item].productShapeRange}<br/>
            {this.props.productDBFrame.frameLists[item].productSizeLowerBound} to {this.props.productDBFrame.frameLists[item].productSizeUpperBound} (inches)
          </div>

          <div className={"product-price product-listing-price"}>
            ${this.props.productDBFrame.frameLists[item].productPriceLowerBound} - ${this.props.productDBFrame.frameLists[item].productPriceUpperBound}<br/>
          </div>

          <div className="product-listing-action-button" onClick={() => this.selectFrame(item)}>Customize</div>

        </div>

      </div>
    });

  };

  fetchProductFilteringSection() {
    return <div>
      <div className={"select-frame-title-container"}>
        Shop Frames <br className={"mobile-display-only"}/> by Colour
      </div>

      <div className={"select-frame-category-selector-container"}>
        {this.fetchFrameOptions()}
      </div>

      <div className={"show-all-frames-button"}
           onClick={() => this.productCategoryAction("click", '')}>
        <span className={this.state.selectedFrameColourCategory === '' ? "no-display" : ""}>Show All Frames</span>
      </div>
    </div>

  };

  fetchProductionOptions() {
    var sizeList = Object.keys(this.props.productDBFrame.frameLists[this.state.selectedFrameType].sizeInventory);

    return sizeList.map((size, key) => {
      return <MenuItem value={`${size}`} key={`select-frame-size-${key}`}>
        {this.props.productDBFrame.frameLists[this.state.selectedFrameType].sizeInventory[size].dimensions} -
        CA ${this.props.productDBFrame.frameLists[this.state.selectedFrameType].sizeInventory[size].price} each
      </MenuItem>
    });
  };

  fetchProductResultSection() {
    return <div>
      <div className={"select-frame-title-container"}>
        Our {this.props.productType === "polaroid" ? "Polaroid" : ""}  Frames
      </div>

      <div className={"product-listing-desc"}>
        <span className={"mobile-no-display"}>
          Prices below include both a frame and a custom matboard.<br/>
          Once you select your frame, customize your matboard colour and size.<br/>
          You’re one step closer to great looking art!
        </span>
        <span className={"mobile-display-only"}>
          All prices include frame<br/>  + custom cut matboard.
        </span>
      </div>

      <div className={"select-frame-product-preview"}>
        {this.fetchProducts()}
      </div>

    </div>
  };

  selectFrame(selectedFrame) {
    this.setState({selectedFrameType: selectedFrame, showModal: true});
    window.scrollTo(0, 0);
  };

  fetchProductPrice() {
    var price;

    if (this.state.selectedFrameSize !== '') {
      price = `CA $${this.props.productDBFrame.frameLists[this.state.selectedFrameType].sizeInventory[this.state.selectedFrameSize].price}`;
    } else {
      price = `CA $${this.props.productDBFrame.frameLists[this.state.selectedFrameType].productPriceLowerBound} - CA $${this.props.productDBFrame.frameLists[this.state.selectedFrameType].productPriceUpperBound}`;
    }

    return <div className={"frame-size-desc-price"}>
      {price}
    </div>
  };

  fetchProductDesc(prodDescArray) {
    var prodList = Object.keys(prodDescArray);
    return prodList.map((desc, key) => {
      return <li key={`prod-desc-${key}`}>{this.props.productDBFrame.frameLists[this.state.selectedFrameType].productInfo[desc]}</li>
    })
  };

  fetchProdSelectionForm(selectedFrameSize) {
    return <FormControl id={"frame-size-selector"}>
      <InputLabel shrink htmlFor="age-label-placeholder">
        Frame Size
      </InputLabel>
      <Select
          value={selectedFrameSize}
          onChange={(evt) => {this.setState({selectedFrameSize: evt.target.value})}}
          input={<Input name="Frame Size" id="frame-size" />}
      >
        {this.fetchProductionOptions()}
      </Select>
      <FormHelperText>Please select frame size for accurate pricing.</FormHelperText>
    </FormControl>
  };

  fetchProdSummaryAndSubmitButton() {
    return <div>
      <div className={"frame-size-desc-prod-summary mobile-no-display"}>
        <div className={"frame-size-desc-prod-summary-title"}>Product Summary</div>
        <ul className={"frame-size-desc-prod-summary-text"}>
          {this.fetchProductDesc(this.props.productDBFrame.frameLists[this.state.selectedFrameType].productInfo)}
        </ul>
      </div>

      <div className="navbar-top-button" id="frame-design-button" onClick={() => {this.submitItemToLocalStorage()}}>
          NEXT
      </div>

      <div className={"frame-size-desc-prod-summary mobile-display-only"} id={"frame-size-desc-prod-summary-mobile-display-only"}>
        <div className={"frame-size-desc-prod-summary-title"}>Product Summary</div>
        <ul className={"frame-size-desc-prod-summary-text"}>
          {this.fetchProductDesc(this.props.productDBFrame.frameLists[this.state.selectedFrameType].productInfo)}
        </ul>
      </div>
    </div>
  };

  selectFrameSizeModal() {
    // console.log(this.props.productDBFrame.frameLists[this.state.selectedFrameType].detailedPhotos[0])

    return <div className={this.state.showModal ? "frame-size-selection display" : "no-display"}>
      <div className={"frame-size-selector-container"}>
        <div className={`frame-size-image-container`}>
          <ImageGallery items={[
            {original: this.props.productDBFrame.frameLists[this.state.selectedFrameType].detailedPhotos[0], thumbnail: this.props.productDBFrame.frameLists[this.state.selectedFrameType].detailedPhotos[0]},
            {original: this.props.productDBFrame.frameLists[this.state.selectedFrameType].detailedPhotos[1], thumbnail: this.props.productDBFrame.frameLists[this.state.selectedFrameType].detailedPhotos[1]},
          ]}/>
        </div>
        <div className={`frame-size-desc-container`}>

          <img className={"modal-close-button"} onClick={() => {this.setState({showModal: false})}}
               src={closeOutIcon} alt={"Please click here to go back to previous page."}/>

          <div className={"frame-size-desc-title"}>{this.props.productDBFrame.frameLists[this.state.selectedFrameType].productTitle} Frame</div>
          <div className={"frame-size-desc-subtitle"}>with customized matboard</div>

          {this.fetchProductPrice()}

          {this.fetchProdSelectionForm(this.state.selectedFrameSize)}

          {this.fetchProdSummaryAndSubmitButton()}

        </div>
      </div>
    </div>
  };

  submitItemToLocalStorage() {
    //this section will later needs to be encyrpted

    if (this.state.selectedFrameSize === "") {
      alert("Please select the frame size.")
    } else {
      localStorageFunctions("KU872B0FD81727FD1A4452C2811Q1FB071154793D7", this.state.selectedFrameType)
      localStorageFunctions("JX91B0V6K10168431BCS189C79759EA3DJTB61D944", this.state.selectedFrameSize)

      if (this.props.productType === "frame") {
        window.location.href="/customize-your-matboards"
      } else if (this.props.productType === "polaroidFrame") {
        window.location.href="/customize-polaroid-frame-layout"
      }
    }
  };

  render() {
    return <div className={`select-frame-container`} >
      {fetchBanner("We ship to Canada & the USA.", "Free Shipping & Easy Returns")}
      {this.state.showModal ? "" : this.fetchProductFilteringSection()}
      {this.state.showModal ? "" : this.fetchProductResultSection()}
      {this.state.showModal ? this.selectFrameSizeModal() : ""}
    </div>
  }
}