import React from "react";

import landingPageReviewStars from "../../../resources/logo/landing-page-star.svg";
import etsyLogo from "../../../resources/images/testimonial/etsyLogo.png";
import googleLogo from "../../../resources/images/testimonial/googleLogo.png";
import fbLogo from "../../../resources/images/testimonial/fbLogo.png";
import logos from "../../../resources/images/testimonial/logos.png";
import LazyLoad from 'react-lazyload';
import arrowLeft from "../../../resources/images/testimonial/arrowLft.svg";
import arrowRight from "../../../resources/images/testimonial/arrowRight.svg";
import testimonialImg1 from "../../../resources/images/testimonial/testimonial1.png";
import testimonialImg2 from "../../../resources/images/reviewStock/review-2.jpg";
import testimonialImg3 from "../../../resources/images/reviewStock/review-3.png";
import testimonialImg4 from "../../../resources/images/reviewStock/review-4.png";
import testimonialImg5 from "../../../resources/images/reviewStock/review-5.png";
import testimonialImg6 from "../../../resources/images/reviewStock/review-6.png";
import testimonialImg7 from "../../../resources/images/reviewStock/review-7.png";
import testimonialImg8 from "../../../resources/images/reviewStock/review-8.png";
import testimonialImg9 from "../../../resources/images/reviewStock/review-9.png";
import testimonialImg10 from "../../../resources/images/reviewStock/review-10.png";
import testimonialImg11 from "../../../resources/images/reviewStock/review-11.png";
import testimonialImg12 from "../../../resources/images/reviewStock/review-12.png";
import testimonialImg13 from "../../../resources/images/reviewStock/review-13.jpg";
import testimonialImg14 from "../../../resources/images/reviewStock/review-14.jpg";
import testimonialImg15 from "../../../resources/images/reviewStock/review-15.jpg";
import testimonialImg16 from "../../../resources/images/reviewStock/review-16.jpg";


import fiveStars from "../../../resources/images/testimonial/5stars.svg";
import img1 from "../../../resources/images/customers/1.jpg";
import img2 from "../../../resources/images/customers/2.jpg";
import img3 from "../../../resources/images/customers/3.jpg";
import img4 from "../../../resources/images/customers/4.jpg";
import img5 from "../../../resources/images/customers/5.jpg";
import img6 from "../../../resources/images/customers/6.jpg";
import img7 from "../../../resources/images/customers/7.jpg";
import img8 from "../../../resources/images/customers/8.jpg";
import img9 from "../../../resources/images/customers/9.jpg";
import img10 from "../../../resources/images/customers/10.jpg";
import img11 from "../../../resources/images/customers/11.jpg";
import img12 from "../../../resources/images/customers/12.jpg";
import img13 from "../../../resources/images/customers/13.jpg";

export default class LandingPageReviewStars extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      selectedReviewKey: 0,
      featuredReviews: [
        {
          img: testimonialImg1,
          reviewer: "Maury L",
          review: "I ordered a mat board with three windows cut into it: one large and two smaller ones. The cuts were perfect, just like the board's unique dimensions. These folks did a fantastic job, and I highly recommend them! Everything arrived on time and was well-packed.",
          reviewFr: "J'ai commandé un passe-partout avec 3 ouvertures découpées dedans ; une grande et deux plus petites. Les découpes étaient parfaites, tout comme les dimensions inhabituelles du passe-partout lui-même. Ces gars ont fait un travail fantastique et je les recommande vivement!"
        },

        {
          img: testimonialImg14,
          reviewer: "Gail G",
          review: "I enjoy designing my own mats. This company allows me to do exactly that. The quality is good, and it ships fast.",
          reviewFr: "J'apprécie de concevoir mes propres passe-partout. Cette entreprise me permet de le faire exactement comme je le souhaite. La qualité est bonne, et la livraison est rapide."
        },

        {
          img: testimonialImg16,
          reviewer: "Christina J",
          review: "Exactly what I wanted!! What a beautiful mat board! This was made exactly to my specifications, and it’s very high-quality. They were so kind when I messed up the measurements and needed to change the order — fast and polite response. And the shipping was fast and the packaging was secure.",
          reviewFr: "Exactement ce que je voulais !! Quel magnifique passe-partout! Il a été réalisé exactement selon mes spécifications, et il est de très haute qualité. Ils ont été très aimables lorsque j'ai fait une erreur dans les mesures et que j'ai dû modifier ma commande, avec une réponse rapide et courtoise."
        },
        {
          img: testimonialImg2,
          reviewer: "Mel L",
          review: "Beautiful mat boards! Rich deep colours with perfectly cut edges. Arrived promptly and in great protective packaging. Will come again for any future framing needs. I highly recommend this shop.",
          reviewFr: "De magnifiques passe-partout ! Des couleurs profondes et riches avec des bords parfaitement coupés. Livrés rapidement et dans un excellent emballage protecteur. Je reviendrai pour tous mes besoins d'encadrement futurs. Je recommande vivement cette boutique."
        },
        {
          img: testimonialImg3,
          reviewer: "Leannae M",
          review: "Definitely met my expectations. Shipping was speedy and well packaged. I was afraid it might be damaged when I got it but it was in perfect condition. Personalized size option was perfect for my project I was working on. Thanks very much!",
          reviewFr: "Cela a certainement répondu à mes attentes. La livraison a été rapide et l'emballage était soigné. J'avais peur qu'il soit endommagé à son arrivée, mais il était en parfait état. L'option de taille personnalisée était parfaite pour mon projet. Merci beaucoup!"
        },
        {
          img: testimonialImg10,
          reviewer: "Ashley K",
          review: "This shop was able to get the mat across the border and to me in California by the end of the week. I let them know when I ordered that I had a short time frame (no pun intended, it was actually an odd large size) and they had it cut that day! I got it in time for what I needed it for.",
          reviewFr: "Cette boutique a réussi à faire passer le passe-partout de l'autre côté de la frontière et à me le livrer en Californie d'ici la fin de la semaine. Je leur ai indiqué lors de ma commande que je disposais d'un délai court, et ils l'ont découpé le jour même ! Je l'ai reçu à temps pour ce dont j'en avais besoin.\n"
        },
        {
          img: testimonialImg15,
          reviewer: "Emma A",
          review: "Exactly what I was looking for, at a reasonable price, and shipped at lightning speed! I love the different colour choices, that allow a match to the artwork. (The mats are off center in the picture but this is just me. They are actually perfect)",
          reviewFr: "Exactement ce que je cherchais, à un prix raisonnable, et expédié à la vitesse de l'éclair ! J'apprécie les différentes options de couleur qui permettent de les assortir à l'œuvre d'art. (Les passe-partout sont légèrement décentrés sur la photo, mais cela vient de moi. En réalité, ils sont parfaits.)"
        },

        {
          img: testimonialImg4,
          reviewer: "Eileen",
          review: "I am so happy I found Custom Matboards! I was looking for mats that not only would hold two different sized photos, but would also allow one photo to be horizontal and the other to be vertical. Paris was so helpful with my order! She replied VERY quickly to my questions that I had, and my order shipped VERY fast!",
          reviewFr: "Je suis tellement heureux(se) d'avoir découvert Custom Matboards! Je cherchais des passe-partout qui non seulement pouvaient accueillir deux photos de tailles différentes, mais qui permettraient également à l'une d'entre elles d'être en format horizontal et l'autre en format vertical. Paris a été d'une grande aide pour ma commande!"
        },


        {
          img: testimonialImg8,
          reviewer: "Emily H",
          review: "Beautiful work on a custom mat board for 7 pictures",
          reviewFr: "Un travail magnifique sur un passe-partout personnalisé pour 7 photos.",
        },
        {
          img: testimonialImg6,
          reviewer: "Meredith T",
          review: "We love our custom mat board! Customer service was great and prompt.",
          reviewFr: "Nous adorons notre passe-partout personnalisé ! Le service client était excellent et rapide."
        },

      ]
    };
  }

  fetchReviews() {
    return this.state.featuredReviews.map((review, key) => {
      if (key === this.state.selectedReviewKey) {
        return <div className={"review-transition-center"}>
          <div className={"testimonial-photo-container"}>
            <LazyLoad>
              <img src={this.state.featuredReviews[key].img} className={"testimonial-photo"}/>
            </LazyLoad>
          </div>
          <div className={"testimonial-desc"}>
            {this.props.french ? this.state.featuredReviews[key].reviewFr :  this.state.featuredReviews[key].review}
          </div>
          <div className={"testimonial-subscript"}>
            {this.state.featuredReviews[key].reviewer}<br/>
            <img src={fiveStars} className={"testimonial-subscript-stars"}/>
          </div>
        </div>
      }
    });
  }

  fetchDials() {
    return this.state.featuredReviews.map((review, key) => {
      return  <div className={key === this.state.selectedReviewKey ? "dial-selected dial" : "dial"}>

      </div>
    });
  }

  buttonClicked(direction) {
    var selectedKey = this.state.selectedReviewKey + direction;
    console.log(selectedKey)
    if (selectedKey === 10) {
      selectedKey = 0;
      this.setState({selectedReviewKey: selectedKey})
    } else if (selectedKey === -1) {
      selectedKey = 9;
      console.log(selectedKey)
      this.setState({selectedReviewKey: selectedKey})
    } else {
      this.setState({selectedReviewKey: selectedKey})
    }
  }


  render() {
    return <div className={"landing-page-review-stars-container"}>
      {/*<div className={"landing-page-review-stars-v3"}>*/}
      {/*  <img className={"landing-page-review-stars-v4"} src={visa}*/}
      {/*       alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}*/}
      {/*  />*/}
      {/*  <img className={"landing-page-review-stars-v4"} src={mastercard} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>*/}
      {/*  <img className={"landing-page-review-stars-v4"} src={amex} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>*/}
      {/*  <img className={"landing-page-review-stars-v4"} src={discover} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>*/}
      {/*  <img className={"landing-page-review-stars-v4"} src={stripe} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>*/}
      {/*</div>*/}

      <div className={"review-transition"}>
        {this.props.french ? "AVIS VÉRIFIÉS" : "Verified Reviews"}
      </div>

      <div className={"review-transition-logo-container"}>
        <LazyLoad>
          <img className={"review-transition-logo"} src={logos}/>
        </LazyLoad>
      </div>

      <div className={"review-transition-stars-container"}>
        <div className={"review-transition-stars-number"}>
          {this.props.french ? "4,9" : "4.9"}
        </div>
        <div className={"review-transition-stars"}>
          <img className={"review-transition-star"} src={landingPageReviewStars} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>
          <img className={"review-transition-star"} src={landingPageReviewStars} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>
          <img className={"review-transition-star"} src={landingPageReviewStars} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>
          <img className={"review-transition-star"} src={landingPageReviewStars} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>
          <img className={"review-transition-star"} src={landingPageReviewStars} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>
        </div>
      </div>



      <div className={"review-transition-stars-desc"}>
        {this.props.french ? "AVEC PLUS DE 2 000 AVIS À 5 ÉTOILES" : "With over 4,000 5 star reviews"}
      </div>

      <div className={"review-transition-widget"}>
        <img src={arrowLeft} className={"arrow-button mobile-no-display"} onClick={() => this.buttonClicked(-1)}/>

        {this.fetchReviews()}

        <img src={arrowRight} className={"arrow-button mobile-no-display"} onClick={() => this.buttonClicked(1)}/>
      </div>

      <div className={"progress-indicator"}>
        <img src={arrowLeft} className={"arrow-button mobile-display-only"} id={"arrow-button-left"} onClick={() => this.buttonClicked(-1)}/>

        <div className={"review-transition-widget-dials-container"}>
          <div className={"review-transition-widget-dials"}>
            {this.fetchDials()}
          </div>
        </div>

        <img src={arrowRight} className={"arrow-button mobile-display-only"} id={"arrow-button-right"} onClick={() => this.buttonClicked(1)}/>

      </div>


      <a href={"/customer-reviews"} className={"testimonial-more-review-button mobile-no-display"}>
          {this.props.french? "Parcourez plus de 4000 évaluations" : "Browse over 4,000 Reviews"}
      </a>

      <div className={"testimonial-more-review-button mobile-display-only"}>
        <a href={"/customer-reviews"} className={" mobile-display-only"}>
          <u>{this.props.french? "Parcourez plus de 4000 évaluations" : "Browse over 4,000 Reviews"}</u>
        </a>
      </div>



    </div>
  }
}