import React from 'react';

import galleryWallStockPng from "../../../../resources/images/gallery/10-articleTen/gallery-wall-stock-photo.png";

import kendraPhotoPng from "../../../../resources/images/gallery/10-articleTen/kendra-photo-1.PNG";
import kendraPhotoWebp from "../../../../resources/images/gallery/10-articleTen/kendra-photo-1.PNG";
import aleksandraPng from "../../../../resources/images/gallery/10-articleTen/Aleksandra-2.jpg";
import programPng from "../../../../resources/images/gallery/10-articleTen/program illustration.PNG";
import chiePhoto from "../../../../resources/images/gallery/10-articleTen/chie.jpg";
import pinterestPinPhoto from "../../../../resources/images/gallery/10-articleTen/pinterest-pin.jpg";
import LazyLoad from 'react-lazyload';
import {Helmet} from "react-helmet";

export default class BlogArticleNine extends React.Component {

  constructor() {
    super();
    this.state = {
      penDrawing: false,
      french: false,
    };
  };

  componentDidMount() {
    this.setState({
      french: window.location.pathname.slice(-2) === "fr" ? true : false
    })
  };

  render() {
    return <div className="blog-app-container">

      <Helmet>
        <title>
          {this.state.french ? "CustomMat.ca - Personnalisez votre passe-partout. Expédié dans les 48 heures." : "CustomMat.ca - 100% Custom matboards delivered to your door."}
        </title>
        <meta name="description" content={
          this.state.french ? "Personnalisez vos passe-partout en ligne. Nous vous les expédions sous 48h. Pas de quantité minimum de commande. Des dizaines d'options de couleurs. Livraison gratuite au Canada et aux États-Unis à partir de 49 $." :
              "Customize your matboards online. Any size or colour. Custom dimensions. Your design. We ship them to you within 48 hours. No minimum order quantity. Free shipping to Canada and the USA over $49."
        } />
      </Helmet>

      <div className="carousel-subject-head">
        {this.state.french ? `COMMENT CRÉER UN MUR DE GALERIE AVEC UN BUDGET LIMITÉ` : `How to create a gallery wall on a budget`}

      </div>


      <LazyLoad>
        <picture>
          <source type="image/webp" srcSet={galleryWallStockPng}/>
          <source type="image/png" srcSet={galleryWallStockPng}/>
          <img className="blog-carousel-photo" src={galleryWallStockPng}
               alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
        </picture>
      </LazyLoad>

      <div className={"blog-article-content"}>
        {this.state.french ? `Avec autant de temps à la maison maintenant, ce n’est pas surprenant que les gens se concentrent sur la décoration. Une des manières les plus faciles et les plus populaires d’ajouter de l’intérêt et de la beauté à n’importe quelle pièce est de créer un mur de galerie.` : `With so much time at home now, it’s not surprising people are focused on decorating. One of the easiest and most popular ways to add interest and beauty to any room is by creating a gallery wall.`}
        <br/><br/>
        {this.state.french ? `Malheureusement, l’encadrement de quelques images pourrait facilement coûter des centaines de dollars. En conséquence, l’encadrement d’une série entière d’images est tout simplement trop cher pour la plupart des gens.` : `Unfortunately, framing a few pictures could easily cost hundreds of dollars. As a result, framing an entire series of pictures is just too expensive for most people.`}
        <br/><br/>
        {this.state.french ? `Heureusement, il existe une solution. Vous pouvez créer un mur de galerie avec un budget limité.` : `Luckily, there's a solution. You can create a gallery wall on a budget.`}
        <br/><br/>
        <a data-pin-do="buttonBookmark" href="https://www.pinterest.com/pin/create/button/"></a>
        <LazyLoad>
          <picture>
            <source type="image/webp" srcSet={pinterestPinPhoto}/>
            <source type="image/png" srcSet={pinterestPinPhoto}/>
            <img className="blog-content-photo" src={pinterestPinPhoto}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
        </LazyLoad>
        <br/><br/>
        <b>{this.state.french ? `1. Commencez par l’œuvre d’art` : `1. Start With the Art`}</b>
        <br/><br/>
        {this.state.french ? `Votre œuvre d’art est la star du spectacle. Le cadre et le ` : `Your art is the star of the show. The frame and `}
        <a href={"/how-it-works"}>
          {this.state.french ? `passe-partout` : `matboard`}
        </a>
        {this.state.french ? ` ne servent que de scène et ne devraient jamais éclipser l’image. Au lieu de cela, ils devraient diriger l’œil vers l’œuvre d’art et lui fournir la fierté de l’endroit qu’elle mérite.` : ` only set the stage and should never outshine the image. Instead, they should lead the eye toward the artwork and provide it with the pride of place it deserves.`}
        <br/><br/>
        {this.state.french ? `Les designers recommandent de placer vos photos, dessins ou tirages préférés sur le sol. Jouez avec l’arrangement jusqu’à ce que vous ayez un design agréable. Vous pouvez également lire des blogues et parcourir des magazines pour découvrir un look qui crée l’ambiance que vous voulez dans la pièce.` : `Designers recommend laying out your favorite photographs, drawings, or prints on the floor. Play with the arrangement until you having a pleasing design. Alternatively, read blogs and skim through magazines to discover a look that creates the mood you want in the room.`}
        <br/><br/>
        {this.state.french ? `Par exemple, ` : `For instance, `}
        <a href={"https://somethingturquoise.com/2020/05/20/diy-oversized-mat-gallery-wall-frames/"}>Kendra</a>
        {this.state.french ? ` a repéré cette disposition dans un blogue de design d’intérieur. Bien qu’elle aimait l’idée, l’encadrement personnalisé aurait été bien au-delà de son budget.` : ` spotted this layout in an interior design blog. Even though she loved the idea, custom framing would have been far beyond her budget.`}
        <br/><br/>

        <LazyLoad>
          <picture>
            <source type="image/webp" srcSet={kendraPhotoWebp}/>
            <source type="image/png" srcSet={kendraPhotoPng}/>
            <img className="blog-content-photo" src={kendraPhotoPng}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
        </LazyLoad>
        <div className={"blog-article-photo-description"}>
          {this.state.french ? `De nos clients -- ` : `From Our Customers -- `}
          <a href={"https://somethingturquoise.com/2020/05/20/diy-oversized-mat-gallery-wall-frames/"}>
            {this.state.french ? `Mur de galerie de Kendra` : `Kendra's Gallery Wall`}
          </a>
        </div>
        {this.state.french ? `Au lieu de cela, elle a choisi la voie du bricolage. Elle a acheté des ` : `Instead, she chose the DIY route. She bought inexpensive `}

        <a href={"https://www.ikea.com/ca/en/p/ribba-frame-black-90301621/"}>
          {this.state.french ? `cadres IKEA` : `IKEA frames`}
        </a>

        {this.state.french ? ` bon marché et a ensuite commandé des ` : ` and then ordered budget friendly `}

        <a href={"/how-it-works"}>
          {this.state.french ? `passe-partout sur mesure` : `custom matboards`}

        </a>

        {this.state.french ? ` économiques chez ` : ` from `}

        <a href={"/"}>
          CustomMat.ca
        </a>.

        {this.state.french ? ` Le coût total par photo était d’environ 50 $ pour un look haut de gamme.` : ` The total cost per picture was about $50 for a high-end designer look.`}

        <br/><br/>

        <b>{this.state.french ? `2. Choix des cadres` : `2. Choosing Frames`}</b>
        <br/><br/>
        {this.state.french ? `En règle générale, il est toujours préférable de choisir un cadre qui permet au moins 3 pouces autour de tous les côtés de l’image, mais préférablement plus. Les passe-partout larges sont en vogue aujourd’hui, car ils offrent un espace blanc abondant, ne semblent pas encombrés et accordent la présence à chaque pièce.` : `As a rule, it is always better to choose a frame that allows at least 3 inches around all sides of the image, but preferably more. Wide mats are trending now, because they provide abundant white space, don’t look cluttered, and give each piece presence.`}
        <br/><br/>
        {this.state.french ? `Si vous ne pouvez pas imaginer le produit fini, utilisez notre ` : `If you can't envision the finished product, use our `}
        <a href={"https://www.custommat.ca/custom-mat-boards-online"}>
          {this.state.french ? `outil de conception` : `design tool`}
        </a>.
        {this.state.french ? `Saisissez les dimensions du cadre et la taille de l’œuvre, moins de 0,5 pouce verticalement et horizontalement si vous souhaitez couvrir les bords de l’œuvre.` : `Input the frame dimensions and the artwork size, less 0.5-inches vertically and horizontally if you want to cover the artwork edges.`}
        <br/><br/>

        <LazyLoad>
          <picture>
            <source type="image/webp" srcSet={chiePhoto}/>
            <source type="image/png" srcSet={chiePhoto}/>
            <img className="blog-content-photo" src={chiePhoto}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
        </LazyLoad>


        <div className={"blog-article-photo-description"}>
          {this.state.french ? `De nos clients -- Mur de galerie de Chie` : `From Our Customers -- Chie's Gallery Wall`}
        </div>
        {this.state.french ? `Si vous recherchez un moyen simple d’unifier votre mur de galerie, choisissez des cadres de la même couleur. Bien sûr, vous pouvez toujours créer un look plus éclectique en utilisant ce que vous avez ou acheter des cadres peu coûteux dans des friperies, des grand magasin, ou en ligne. Vous aurez besoin de jouer avec la disposition pour créer la cohésion par la couleur, la texture et l’équilibre, mais cela est possible avec un peu de patience.` : `If you’re looking for a simple way to unify your gallery wall, choose frames all in the same colour. Of course, you can always create a more eclectic look by using whatever you have or buy inexpensive frames at thrift, or department store, or online. You will need to play with the layout to create cohesion through color, texture, and balance, but it is possible with a little patience.`}
        <br/><br/>
        <a href="/customize-matboards">
          <div className="product-listing-action-button">
            {this.state.french ? `Commencez` : `Start Designing`}
          </div>
        </a>
        <br/><br/>
        <b>
          {this.state.french ? `3. Passe-partout` : `3. Matboards`}
        </b><br/><br/>
        {this.state.french ? `Le passe-partout est la couche de papier dense se trouvant entre le cadre et votre image. Il couvre généralement les bords de votre illustration pour donner un aspect fini.` : `The matboard is the dense paper layer that sits between the frame and your image. It usually covers the edges of your artwork to provide a finished look.`}
        <br/><br/>
        {this.state.french ? `Les passe-partout permettent d’utiliser un cadre standard produit en masse pour une pièce irrégulière. Par exemple, vous pouvez encadrer une carte postale dans un cadre photo de 16 po x 20 po. Vous pouvez également personnaliser le passe-partout si vous voulez faire quelque chose de spécial.` : `Matboards make it possible to use a standard, mass-produced frame for an irregular piece. For instance, you could frame a postcard in a 16” x 20” photo frame. You can also customize the matboard if you want to do something special.`}
        <br/><br/>

        <LazyLoad>
          <picture>
            <source type="image/webp" srcSet={aleksandraPng}/>
            <source type="image/png" srcSet={aleksandraPng}/>
            <img className="blog-content-photo" src={aleksandraPng}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
        </LazyLoad>

        <br/>

        {this.state.french ? `Notre cliente Alex a mis un peu d’accent sur le portrait de son chiot avec un passe-partout rose glacé en laissant plus de marge sur le dessus comme il était censé être vu d’en haut.` : `Our customer Alex gave a little accent to her puppy portrait with Ice Pink mat by leaving more margin at the top as it was meant to be viewed from above.`}

        {this.state.french ? `Nos passe-partout sans acide (neutralisés à l’acide) à 4 épaisseurs sont disponibles en 26 couleurs. Leur composition avec un pH neutre permet de préserver les œuvres d’art. Vous ne savez pas quelle couleur utiliser? Aucun problème : commandez nos échantillons gratuits (9,95 $ de frais d’expédition s’appliquent).` : `Our acid-free (acid-neutralized), 4-ply matboards are available in 26 colours. Their pH neutral composition helps preserve artwork. Not sure of which colour to use? No problem – order our free samples.`}
        <br/><br/>
        {this.state.french ? `Si vous avez une demande inhabituelle, téléchargez un croquis et recevez un devis. Il suffit de sélectionner «Passe-partout personnalisé», puis «Personnaliser» et l’écran suivant s’affichera avec les exemples de passe-partout demandés et les options d’outil avancés.` : `If you have an unusual request, upload a sketch and receive an estimate. Just select “custom matboard” and then “customize” and the following screen will appear with the request matboard samples and advanced tool options.`}
        <br/><br/>
        <a href="/customize-matboards">
          <div className="product-listing-action-button">
            {this.state.french ? `Commencez` : `Start Designing`}
          </div>
          <br/><br/>
        </a>

        <LazyLoad>
          <picture>
            <source type="image/webp" srcSet={programPng}/>
            <source type="image/png" srcSet={programPng}/>
            <img className="blog-content-photo" src={programPng}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
        </LazyLoad>

        <b>{this.state.french ? `4. Pourquoi vous devriez commander chez CustomMat.ca` : `4. Why You Should Order From CustomMat.ca`}</b><br/><br/>

        {this.state.french ? `Nous offrons des passe-partout neutralisés à l’acide de la meilleure qualité qui sont fabriqués au Canada et aux États-Unis. Nous offrons ` : `We offer the best quality acid neutralized mats that are made in Canada and the United States. We offer `}

        <a href={"https://www.custommat.ca/pricing-and-discounts"}>
          {this.state.french ? `jusqu’à 40 % de réduction` : `up to a 40% discount`}
        </a>

        {this.state.french ? ` si vous commandez plusieurs articles, mais ne vous inquiétez pas. Nous n’avons pas de quantité minimale de commande.` : ` if you order multiple items, but don't worry. We do not have a minimum order quantity.`}

        <br/><br/>
        {this.state.french ? `Nous produisons et expédions dans les 48 heures directement à votre porte partout au Canada et aux États-Unis pour un taux d’expédition fixe de 9,95 $. Les commandes de plus de 49 $ sont expédiées gratuitement.` : `We produce and ship within 48 hours directly to your doorstep anywhere in Canada and the Unites States. Shipping is free of charge.`}

        <br/><br/>
        {this.state.french ? `Notre travail est offert avec une garantie de satisfaction de 100 %. Si vous avez des questions, veuillez nous contacter au 1 226 828-1705 ou à hello@CustomMat.ca. Nous répondrons dans un délai d’une heure.` : `Our work is 100% satisfaction guaranteed. If you have any questions, please contact us at +1-226-828-1705 or hello@CustomMat.ca. We'll respond within an hour.`}

        <br/><br/>
        <a href="/customize-matboards">
          <div className="product-listing-action-button">
            {this.state.french ? `Commencez` : `Start Designing`}
          </div>
        </a>

      </div>



    </div>
  }
}