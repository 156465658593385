import React from "react";

export default class QuantityDiscount extends React.Component {

    constructor() {
        super();
        this.state = {
            penDrawing: false,
            french: false,
        };
    };

    componentDidMount() {
        this.setState({
            french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
        })
    }


    render() {
        return <div className={"pricing-table-container fair-pricing-discount-section-container"}>
            <div className="product-listing-header">
                {this.state.french ? "ACHETEZ PLUS, ÉCONOMISEZ PLUS" : "Buy More, Save More"}
            </div>
            <div className="product-listing-desc">
                {this.state.french ? "Profitez d’une réduction pouvant aller jusqu’à 40 % à l’achat de plus d’un article." : "Enjoy up to 40% off when you buy more than one item."}
                <br/>
                {this.state.french ? "Les remises s’appliquent même si vous achetez des tailles, des couleurs ou des produits différents." : "Discounts apply even if you buy DIFFERENT sizes, colours, or products."}
                <br/>
                {this.state.french ? "La réduction s'applique à la quantité globale." : "Discount applies to overall quantity."}
                <br/>
            </div>

            <table className="pricing-table">
                <tbody>
                <tr>
                    <th className="faq-table-row">
                        {this.state.french ? "Nombre d’articles" : "Item Count"}
                    </th>
                    <th className="faq-table-row">
                        {this.state.french ? "Taux de Réduction" : "Discount Rates"}
                    </th>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">2 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>25%  {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">4 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>40%  {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">10 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>50%  {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">20 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>60%  {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">50 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>64%  {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">100 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>66%  {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">250 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>68%  {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">500+ {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>70%  {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>
            </table>

        </div>


    }
}