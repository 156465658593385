import React from "react";
import dropdownArrow from "../../../resources/svgIcons/chevron-up.svg";

export default class Faq extends React.Component {

    constructor() {
        super();
        this.state = {
            french: false,
            contactQ1: false,
            contactQ2: false,
            changeQ1: false,
            changeQ2: false,
            inquiryQ1: false,
            inquiryQ2: false,
            inquiryQ3: false,
            inquiryQ4: false,
            inquiryQ5: false,
            inquiryQ6: false,
            inquiryQ7: false,
            inquiryQ8: false,
            shippingQ1: false,
            shippingQ2: false,
            shippingQ3: false,
            shippingQ4: false,
            shippingQ5: false,
            shippingQ6: false,
            shippingQ7: false,
            shippingQ8: false,
            shippingQ9: false,
            pricingQ1: false,
            pricingQ2: false,
            billingQ1: false,
            billingQ2: false,
            billingQ3: false,
            billingQ4: false,
            billingQ5: false,
            orderingQ1: false,
            orderingQ2: false,
            orderingQ3: false,
            orderingQ4: false,
            orderingQ5: false,
            orderingQ6: false,
            orderingQ7: false,
            orderingQ8: false,
            orderingQ9: false,
            orderingQ10: false,
            orderingQ11: false,
            orderingQ12: false,
            orderingQ13: false,
            orderingQ14: false,
            orderingQ15: false,
            orderingQ16: false,
            orderingQ17: false,
            orderingQ18: false,
            orderingQ19: false,
            orderingQ20: false,
            orderingQ21: false,
            orderingQ22: false,
            orderingQ23: false,
            orderingQ24: false,
            orderingQ25: false,
            orderingQ26: false,
            orderingQ27: false,
            orderingQ28: false,
            orderingQ29: false,
            howItWorksQ1: false,
            sizingQ1: false,
            sizingQ2: false,
            productQ1: false,
            productQ2: false,
            productQ3: false,
            dataQ1: false,
            dataQ2: false,
        };
    };

    componentDidMount() {
        this.setState({
            french: window.location.pathname.slice(-2) === "fr" ? true : false
        })
    };

    toggleModal(stateToToggle) {
        this.setState({[stateToToggle]: !this.state[stateToToggle]})
    }

    fetchResponse(fr, en) {
        return this.state.french ? `${fr}` : `${en}`
    }



    render() {
        return <div className={`general-static-page-container`}>

            <div className={"faq-page-heading"}>
                {this.fetchResponse("FAQ", " Frequently asked questions")}
            </div>


            <div className={"faq-question-bloc"}>
                <div className={"faq-static-page-container-heading"}>
                    Contact
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("contactQ1")}>
                    <img src={dropdownArrow}
                         className={this.state.contactQ1 ? "faq-question-close" : "faq-question-open"}
                        alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse("Comment puis-je communiquer avec votre équipe du service à la clientèle?", "How can I get in touch with your customer service team?")}
                    </div>
                </div>

                <div className={this.state.contactQ1 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Le moyen le plus rapide de joindre notre équipe est par courriel -- hello@custommat.ca. Nous répondons généralement à vos courriels entre 9 h et 17 h, du lundi au vendredi, à l’exception des jours fériés.",
                            "The fastest way to reach our team is via email -- hello@custommat.ca. We typically respond to your emails between 9AM - 5PM, Monday to Friday, excluding holidays.")
                        }
                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Si vous préférez discuter avec nos représentants par téléphone, veuillez nous envoyer un message texte au 1 604 345-8428. Nous sommes disponibles par téléphone entre 9 h et 17 h, du lundi au vendredi, à l’exception des jours fériés.",
                            "If you would prefer chatting with our representatives over the phone, please call us at +1 226-828-1705 or text us at +1 604-345-8428. We are available over the phone between 9AM - 5PM, Monday to Friday, excluding holidays.")
                        }
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("contactQ2")}>
                    <img src={dropdownArrow} className={this.state.contactQ2 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                    />

                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Puis-je passer une commande par téléphone?",
                            "Can I place an order over the phone?"
                        )}
                    </div>

                </div>

                <div className={this.state.contactQ2 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oui, vous pouvez passer une commande par téléphone auprès de l’un de nos représentants. Une fois la commande passée, nous vous enverrons la facture par courriel. Nous ne prenons pas les informations de votre carte de crédit par téléphone. Les paiements seront traités via la facture à l’aide d’un service financier tiers appelé Stripe.",
                            "Yes, you can place an order with one of our representatives over the phone. Once the order is placed we will send the invoice to your email. We will not take your credit card information over the phone. Payments will be processed through the email invoice."
                        )}
                    </div>
                </div>
            </div>

            <div className={"faq-question-bloc"}>
                <div className={"faq-static-page-container-heading"}>
                    {this.fetchResponse(
                        "MODIFICATIONS, ANNULATIONS, ÉCHANGES OU REMBOURSEMENTS DE COMMANDES",
                        "Order changes, cancellations, exchanges, or refunds"
                    )}

                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("changeQ1")}>
                    <img src={dropdownArrow} className={this.state.changeQ1 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}

                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Politique de satisfaction de la clientèle à 100 %",
                            "100% Customer Satisfaction Policy"
                        )}
                    </div>
                </div>

                <div className={this.state.changeQ1 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Parfois, un client peut saisir des dimensions incorrectes ou choisir la mauvaise couleur. Si cela vous arrive, nous serons plus qu’heureux d’échanger votre commande gratuitement. En tant que petite entreprise avec plusieurs artistes dans notre salle de travail, nous comprenons l’importance de ces détails. La satisfaction des clients est extrêmement importante pour nous, et nous serons heureux d’échanger ou de remplacer votre commande gratuitement.",
                            "Sometimes a customer might enter incorrect dimensions or choose the wrong colour. If this happens to you, we are more than happy to exchange your order free of charge. Being a company with many an artist in our work room, we understand how important these details can be. Customer satisfaction is extremely important to us and we will be happy to exchange or replace your order free of charge."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("changeQ2")}>
                    <img src={dropdownArrow} className={this.state.changeQ2 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}

                    />

                    <div className={"faq-question-right"}>

                        {this.fetchResponse(
                            "Retours, échanges et annulations",
                            "Returns, Exchanges & Cancellations"
                        )}
                    </div>

                </div>

                <div className={this.state.changeQ2 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {
                            this.fetchResponse(
                                "Veuillez nous envoyer un courriel à hello@custommat.ca avec votre numéro de commande et le raisonnement justifiant votre demande de retour, d’échange ou d’annulation. Nous faisons de notre mieux pour répondre à toutes les demandes de nos clients afin d’assurer la plus grande satisfaction de nos clients.",
                                "Please send us an email at hello@custommat.ca with your order number and reasoning behind your return, exchange or cancellation. We try our best to accommodate all our customer requests to ensure the utmost customer satisfaction."
                            )
                        }
                    </div>
                </div>
            </div>

            <div className={"faq-question-bloc"}>

                <div className={"faq-static-page-container-heading"}>

                    {this.fetchResponse(
                        "DEMANDES DE COMMANDE",
                        "Order Inquiries"
                    )}

                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("inquiryQ1")}>
                    <img src={dropdownArrow} className={this.state.inquiryQ1 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}

                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Avez-vous une quantité minimale de commande?",
                            "Do you have a minimum order quantity?"
                        )}
                    </div>
                </div>

                <div className={this.state.inquiryQ1 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Non. Nous n’avons pas de quantité minimale de commande. Commandez dès maintenant! Vous pouvez facilement commander un seul passe-partout sans aucune difficulté. Gardez à l’esprit que nous avons différentes offres en place en fonction du nombre de passe-partout que vous commandez. Si cela vous intéresse, vous pouvez consulter les remises ici - www.custommat.ca/pricing-and-discounts-fr",
                            "No. We do not have a minimum order quantity. Order away! You can easily order one singular mat board without any hassle. Keep in mind, we do have different deals in place depending on how many mat boards you order. If this is of interest to you you can check out the discounts here - www.custommat.ca/pricing-and-discounts"
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("inquiryQ2")}>
                    <img src={dropdownArrow} className={this.state.inquiryQ2 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}

                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Quand vais-je recevoir la confirmation de ma commande?",
                            "When will I receive my  order confirmation?"
                        )}
                    </div>

                </div>

                <div className={this.state.inquiryQ2 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Directement après avoir passé votre commande, vous recevrez une confirmation de commande de notre part via le courriel -- orderconfirmation@custommat.ca. Si vous n’avez pas reçu votre courriel de confirmation, veuillez vérifier votre dossier de pourriel ou de promotions. Il est probable que le courriel s’y retrouve.",
                            "Directly after your order is placed you will receive an order confirmation from us through the email -- orderconfirmation@custommat.ca . If you have not received your confirmation email, please check your spam or promotions folder. It is probable that the email has wound up there.")}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("inquiryQ3")}>
                    <img src={dropdownArrow} className={this.state.inquiryQ3 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}

                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Je n’ai pas reçu le courriel de confirmation de ma commande. Que dois-je faire?",
                            "I have not received my order confirmation email. What do I do?"
                        )}

                    </div>

                </div>

                <div className={this.state.inquiryQ3 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Les courriels de confirmation de commande sont envoyés immédiatement après l’achat d’un passe-partout. Veuillez garder à l’esprit que nos courriels peuvent être accidentellement signalés par des filtres comme étant des « pourriels ». Veuillez vérifier le dossier de pourriel de votre courriel et marquer le courriel de notre société comme «non indésirable» afin que vous puissiez recevoir des mises à jour de votre commande par courriel sans aucun problème.",
                            "Order confirmation emails are sent out immediately after you purchase a mat board.  Please keep in mind that our emails may be accidentally flagged by spam filters as 'Spam.' Please check the spam folder of your email and mark our company email as ‘not spam’ so you will be able to receive future email updates on your order without any hiccups."
                        )}
                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Si vous n’avez pas reçu de confirmation de commande de notre part, veuillez nous contacter à hello@custommat.ca avec votre numéro de commande et nous serons heureux de vous aider. Si vous ne connaissez pas votre numéro de commande, veuillez confirmer votre adresse courriel et le nom complet que vous avez fourni lors de votre commande, et nous vous répondrons dès que possible.",
                            "If you haven’t received an order confirmation from us, please contact us at hello@custommat.ca with your order number and we will be happy to assist you. If you don’t know your order number, please confirm your email address and the full name that was provided when placing your order and we will get back to you as soon as we can."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("inquiryQ4")}>
                    <img src={dropdownArrow} className={this.state.inquiryQ4 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}

                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Comment puis-je faire le suivi de ma commande?",
                            "How can I track my order?"
                        )}

                    </div>

                </div>

                <div className={this.state.inquiryQ4 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Une fois que votre commande est produite et expédiée depuis notre atelier, nous vous enverrons un courriel incluant les informations de suivi de vos commandes. Vous pourrez ainsi vérifier l’emplacement de votre colis à tout moment. Nos commandes sont livrées par Postes Canada, FedEx, UPS et USPS.",
                            "Once your order has been produced and dispatched from our workshop, we will send you an email with your orders tracking information. This will allow you to check in with your package's whereabouts whenever you like. Our orders are delivered with Canada Post, FedEx, UPS, and USPS."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("inquiryQ5")}>
                    <img src={dropdownArrow} className={this.state.inquiryQ5 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}

                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Que dois-je faire si je n’ai pas reçu de numéro de suivi?",
                            "What should I do if I did not receive a tracking number?",
                        )}

                    </div>

                </div>

                <div className={this.state.inquiryQ5 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Les numéros de suivi sont attribués juste avant l’expédition de la commande. Dès que votre commande est envoyée, vous recevrez un courriel avec le numéro de suivi de chaque commande passée, ainsi qu’un lien qui vous permettra de faire le suivi de votre commande. Si vous ne recevez pas ce courriel, la première chose que vous devriez faire est de vérifier votre dossier de pourriel. Si le courriel de suivi ne s’affiche toujours pas, veuillez nous envoyer un courriel à l’adresse -- hello@custommat.ca avec votre nom et votre numéro de commande et un de nos représentants du service clientèle communiquera avec vous dès que possible.",
                            "Tracking numbers are assigned right before the order is shipped. As soon as your order is sent out,  you will receive an email with the tracking number for each individual order placed,  along with a link that will allow you to track your order. If you do not receive this email, the first thing you should do is check your spam folder. If the tracking email still has not shown up, please send us an email at -- hello@custommat.ca with your name and order number and one of our customer service representatives will get back to you as soon as possible."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("inquiryQ6")}>
                    <img src={dropdownArrow} className={this.state.inquiryQ6 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Que puis-je faire si la commande que j’ai reçue était incorrecte, endommagée ou manquante?",
                            "What can I do if the order I received was wrong, damaged or missing?"
                        )}
                    </div>

                </div>

                <div className={this.state.inquiryQ6 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oh non! Nous sommes vraiment désolés. Nous faisons de notre mieux pour nous assurer que toutes nos commandes sont emballées correctement, mais parfois les gens font des erreurs. Veuillez nous contacter à hello@custommat.ca avec votre numéro de commande et nous faire part de tout problème que vous pourriez avoir. Nous sommes plus que disposés à vous envoyer un nouveau passe-partout ou à répondre à toute autre demande des clients.",
                            "Oh no! We’re so sorry about this. We do our best to make sure that all of our orders are packaged correctly,  but sometimes people make mistakes. Please contact us at hello@custommat.ca with your order number and let us know of any issues you might have. We are more than willing to send you a new mat board or accommodate any other related customer requests."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("inquiryQ7")}>
                    <img src={dropdownArrow} className={this.state.inquiryQ7 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}

                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Que dois-je faire si ma commande a été expédiée à la mauvaise adresse?",
                            "What should I do if my order has been shipped to the wrong address?"
                        )}
                    </div>

                </div>

                <div className={this.state.inquiryQ7 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Veuillez nous indiquer votre bonne adresse dès que possible par courriel à l’adresse hello@custommat.ca.",
                            "Please let us know of your correct address as soon as possible through our email at hello@custommat.ca."
                        )}

                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Malheureusement, nous ne pouvons pas modifier l’itinéraire d’un colis une fois qu’il a été expédié. Pour les colis déjà expédiés, nous ne sommes pas en mesure de vous aider davantage et ils seront livrés à l’adresse indiquée au départ.",
                            "Unfortunately, we are unable to modify the route of a parcel once it has been shipped. For parcels that are already shipped, we are unable to assist further and it will be delivered to the originally stated address."
                        )}
                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Cependant, si vous constatez que votre colis est retourné chez Custom Mat, contactez-nous et faites-le nous savoir! Nous pouvons expédier votre commande une deuxième fois sans frais supplémentaires.",
                            "However, if you see that your parcel is being returned back to Custom Mat, simply reach out to us and let us know! We can reship your order a second time at no additional cost."
                        )}
                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Nous expédions les commandes dans les 1 à 2 jours ouvrables suivant la réception de la commande. Si vous nous contactez dans ce délai, nous devrions être en mesure de corriger facilement tout problème avec l’adresse.",
                            "We ship orders within 1-2 business days after receiving the order. If you get a hold of us within that time frame, we should be able to easily correct any issues with the address."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("inquiryQ8")}>
                    <img src={dropdownArrow} className={this.state.inquiryQ8 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}

                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Mes commandes sont en retard. Pouvez-vous m’aider?",
                            "My orders are being delayed. Can you help me?"
                        )}

                    </div>

                </div>

                <div className={this.state.inquiryQ8 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Nos messagers peuvent rencontrer des retards. Les délais allant de 1 à 3 jours ouvrables sont dans la plage normale de retards. Toutefois, si vous rencontrez des retards de plus de 4 jours ouvrables, veuillez nous en informer à l’adresse -- hello@custommat.ca",
                            "Our couriers may experience delays. Delays of up to 1 to 3 business days are all within the normal range. However, if you are experiencing delays that are longer than 4 business days, please let us know at -- hello@custommat.ca"
                        )}
                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Comme nous sommes responsables de votre commande jusqu’à ce que votre commande soit reçue, nous enquêterons sur tout retard de notre côté, et nous continuerons de vous aider tout au long du processus de livraison.",
                            "As we are responsible for your order until your order is received, we will investigate any delays on our end and will continue to assist you throughout the delivery journey."
                        )}
                    </div>
                </div>
            </div>

            <div className={"faq-question-bloc"}>
                <div className={"faq-static-page-container-heading"}>
                    {this.fetchResponse(
                        "EXPÉDITION ET DEMANDES DE LIVRAISON",
                        "Shipping + Delivery inquiries"
                    )}

                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("shippingQ1")}>
                    <img src={dropdownArrow} className={this.state.shippingQ1 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}

                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "À quels endroits Custom Mat peut-il livrer?",
                            "Where does Custom Mat ship to?"
                        )}

                    </div>

                </div>

                <div className={this.state.shippingQ1 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Custom Mat livre partout au Canada et aux États-Unis.",
                            "Custom Mat ships to anywhere in Canada and the United States"
                        )}

                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("shippingQ2")}>
                    <img src={dropdownArrow} className={this.state.shippingQ2 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}

                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Je commande à partir des États-Unis, dois-je me soucier des taxes?",
                            "I’m ordering from the US, do I have to worry about taxes?"
                        )}

                    </div>

                </div>

                <div className={this.state.shippingQ2 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Non! Nos produits sont hors taxes. Nos passe-partout sont exonérés des taxes fédérales et municipales des États-Unis.",
                            "No! Our products are tax-stress free. Our mat boards are exempt from both the US federal and municipal level taxes."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("shippingQ3")}>
                    <img src={dropdownArrow} className={this.state.shippingQ3 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}

                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Quel est le coût de l’expédition? Comment puis-je bénéficier de la livraison gratuite?",
                            "How much is shipping? How do I qualify for free shipping?"
                        )}

                    </div>

                </div>

                <div className={this.state.shippingQ3 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Livraison gratuite partout au Canada et aux États-Unis",
                            "Free shipping across Canada & the USA."
                        )}

                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("shippingQ4")}>
                    <img src={dropdownArrow} className={this.state.shippingQ4 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}

                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Expédiez-vous vers des boîtes postales et des adresses militaires?",
                            "Do you ship to PO Boxes and Military Addresses?"
                        )}

                    </div>

                </div>

                <div className={this.state.shippingQ4 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oui! Tant que vous vous trouvez au Canada ou dans la zone continentale des États-Unis, nous pouvons expédier vers vos boîtes postales et adresses militaires. Aucun problème.",
                            "Yes! As long as you are in Canada or Continental US, we can ship to your PO boxes and military address. No problem."
                        )}

                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("shippingQ5")}>
                    <img src={dropdownArrow} className={this.state.shippingQ5 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}

                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Puis-je expédier à une adresse de réexpédition?",
                            "Can I ship to a mail/freight forwarding address?"
                        )}

                    </div>

                </div>

                <div className={this.state.shippingQ5 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oui, nous le pouvons. Veuillez vous assurer que votre prénom et votre nom sont inclus dans les champs appropriés.",
                            "Yes, we can. Please make sure your first and last name are included in the appropriate fields."
                        )}

                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Veuillez noter que si vous choisissez d’utiliser un service de réexpédition de courrier, nous ne sommes plus responsables de votre commande une fois qu’elle a été livrée à cette adresse. Les commandes expédiées à une adresse de réexpédition ne sont pas admissibles à des remplacements ou à des remboursements si nous pensons qu’elles ont été raisonnablement livrées à cette adresse.",
                            "Please note, if you choose to use a mail forwarding service, we are no longer responsible for your order once it has been delivered to that address. Orders shipped to a mail forwarding address are not eligible for replacements or refunds if we believe it has been reasonably delivered to that address."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("shippingQ6")}>
                    <img src={dropdownArrow} className={this.state.shippingQ6 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}

                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Combien de temps faut-il pour qu’une commande soit expédiée?",
                            "How long does it take for an order to be shipped?",
                        )}

                    </div>

                </div>

                <div className={this.state.shippingQ6 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "La plupart de nos commandes sont produites et expédiées dans un délai de 2 jours ouvrables. Pour certains modèles de passe-partout personnalisés et les commandes en grande quantité, cela peut prendre de 1 à 2 jours supplémentaires.",
                            "Most of our orders are produced and shipped within 2 business days. For certain custom mat board designs and large quantity orders, it may take 1 to 2 additional days."
                        )}

                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("shippingQ7")}>
                    <img src={dropdownArrow} className={this.state.shippingQ7 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Combien de temps l’expédition prend-elle?",
                            "How long does it take for delivery?"
                        )}

                    </div>

                </div>

                <div className={this.state.shippingQ7 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Nos commandes sont produites et expédiées dans un délai de 2 jours ouvrables. ",
                            "Our orders are produced and shipped within 2 business days."
                        )}

                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Le calendrier de livraison estimé région par région peut être vu ici - www.custommat.ca/shipping-fr",
                            "Estimated delivery timeline region to region can be seen here - www.custommat.ca/shipping"
                        )}

                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("shippingQ8")}>
                    <img src={dropdownArrow} className={this.state.shippingQ8 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Quel fournisseur de services d’expédition utilisez-vous?",
                            "What shipping service provider do you use?"
                        )}

                    </div>

                </div>

                <div className={this.state.shippingQ8 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Nous utilisons Postes Canada, Canpar, FedEx, UPS et USPS.",
                            "We use Canada Post, Canpar, FedEx, UPS, and USPS."
                        )}

                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("shippingQ9")}>
                    <img src={dropdownArrow} className={this.state.shippingQ9 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Puis-je récupérer ma commande à votre emplacement?",
                            "Can I pick up my order from your location?"
                        )}

                    </div>

                </div>

                <div className={this.state.shippingQ9 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Non. Malheureusement, nous n’offrons pas de services de ramassage pour le moment.",
                            "No. Unfortunately, we do not offer pickup services at this time."
                        )}

                    </div>
                </div>
            </div>

            <div className={"faq-question-bloc"}>
                <div className={"faq-static-page-container-heading"}>
                    {this.fetchResponse(
                        "TARIFS ET REMISES",
                        "Pricing & Discounts"
                    )}

                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("pricingQ1")}>
                    <img src={dropdownArrow} className={this.state.pricingQ1 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Offrez-vous des remises sur la quantité?",
                            "Do you offer quantity discounts?"
                        )}
                    </div>

                </div>

                <div className={this.state.pricingQ1 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oui. Nous en offrons. Pour connaître les remises sur les quantités et l’estimation des prix, veuillez vous reporter à la section des remises sur les quantités -- (à ajouter). Vous pouvez bénéficier d’une remise allant jusqu’à 40 % si vous commandez plus de 10 articles. Ils peuvent être une combinaison de différentes tailles et couleurs.",
                            "Yes. We do. For quantity discounts and price estimation, please refer to the quantity discount section -- (to be added). You are eligible for up to 40% quantity discount if you order more than 10 items. They can be a combination of various sizes and colours."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("pricingQ2")}>
                    <img src={dropdownArrow} className={this.state.pricingQ2 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Avez-vous un compte de vente en gros?",
                            "Do you have a wholesale account?"
                        )}

                    </div>

                </div>

                <div className={this.state.pricingQ2 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oui. Nous avons travaillé sur de nombreux projets à grande échelle avec des musées, des galeries, des instituts éducatifs, des entreprises, ainsi que des organisations gouvernementales et internationales, et nous nous sentons donc très à l’aise de traiter de grandes commandes en gros. Veuillez communiquer avec nous à l’adresse hello@custommat.ca en indiquant les détails de votre commande, y compris la quantité de passe-partout que vous souhaitez, et nous vous répondrons dès que possible.",
                            "Yes we do. We have worked on numerous large scale projects with museums, galleries, educational institutes, corporations, as well as governmental and international organizations, and therefore feel very comfortable handling large wholesale orders. Please reach out to us at hello@custommat.ca with your order details including the quantity of mat boards that you are looking for and we will get back to you as soon as we can."
                        )}
                    </div>
                </div>

            </div>

            <div className={"faq-question-bloc"}>
                <div className={"faq-static-page-container-heading"}>
                    {this.fetchResponse(
                        "FACTURATION ET PAIEMENTS",
                        "Billing + Payments"
                    )}

                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("billingQ1")}>
                    <img src={dropdownArrow} className={this.state.billingQ1 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Quelles méthodes de paiement acceptez-vous actuellement?",
                            "What methods of payments do you currently accept?"
                        )}

                    </div>

                </div>

                <div className={this.state.billingQ1 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Nous acceptons toutes les principales cartes de crédit et de débit. Lors de la commande, veuillez vous assurer que votre numéro de carte de crédit, la date d’expiration, le numéro CVV (au dos de votre carte de crédit) et l’adresse de facturation de votre carte de crédit correspondent à la carte de crédit que vous décidez d’utiliser.",
                            "We accept all major credit and debit cards. When ordering please ensure that your credit card number, expiration date, CVV number (number in the back of your credit card) and credit card billing address match with the credit card you decide to use."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("billingQ2")}>
                    <img src={dropdownArrow} className={this.state.billingQ2 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Quand mon paiement sera-t-il traité?",
                            "When will my payment be processed?"
                        )}

                    </div>

                </div>

                <div className={this.state.billingQ2 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Votre carte sera débitée pendant le processus de paiement.",
                            "Your card will be charged during the checkout process."
                        )}

                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("billingQ3")}>
                    <img src={dropdownArrow} className={this.state.billingQ3 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                         />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Dans quelle devise facturez-vous?",
                            "What currency do you charge in?"
                        )}

                    </div>

                </div>

                <div className={this.state.billingQ3 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Nous facturons soit en dollars canadiens ($ CA), soit en dollars américains ($ US) selon votre choix de devise.",
                            "We charge either in Canadian Dollars (CA$) or US Dollars (US$) based on your currency selection."
                        )}

                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("billingQ4")}>
                    <img src={dropdownArrow} className={this.state.billingQ4 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Quand vais-je recevoir mon remboursement?",
                            "When will I receive my refund?"
                        )}

                    </div>

                </div>

                <div className={this.state.billingQ4 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Une fois votre remboursement approuvé, vous recevrez un courriel avec un reçu de remboursement. Le remboursement apparaîtra sur votre relevé de carte de crédit ou de débit dans un délai de 1 à 2 jours ouvrables. Votre reçu de remboursement sera envoyé au courriel qui a été utilisé pour traiter votre commande, et le remboursement sera appliqué à votre carte.",
                            "Once your refund has been approved, you will receive a refund receipt email. The refund will appear on your credit or debit card statement within 1 to 2 business days. Your refund receipt will be sent to the email that was used to process your order and the refund will be issued back to your card."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("billingQ5")}>
                    <img src={dropdownArrow} className={this.state.billingQ5 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Ma carte de crédit a-t-elle été débitée lorsque ma transaction a été refusée?",
                            "Was my credit card still charged when my transaction was declined?"
                        )}

                    </div>

                </div>

                <div className={this.state.billingQ5 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Lorsque votre transaction est refusée, le paiement peut toujours apparaître sur les « frais en attente » de votre compte de carte de crédit/débit. Ceci est normal. Les frais en attente disparaîtront dans un délai de 1 à 2 jours ouvrables. Veuillez noter que si votre transaction a été refusée, aucun fonds n’a été transféré à Custom Mat. Si, après quelques jours ouvrables, votre compte a été débité, veuillez nous contacter à hello@custommat.ca.",
                            "When your transaction is declined the payment may still appear on “pending charges” on your credit/ debit card account. This is normal. The pending charges will disappear within 1 - 2 business days. Please note that if your transaction was declined, no funds have been transferred to Custom Mat. If after a few business days, your account was still charged, please contact us at hello@custommat.ca ."
                        )}
                    </div>
                </div>
            </div>

            <div className={"faq-question-bloc"}>


                <div className={"faq-static-page-container-heading"}>

                    {this.fetchResponse(
                        "COMMANDE DE PASSE-PARTOUT",
                        "Ordering Matboards"
                    )}
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ1")}>
                    <img src={dropdownArrow} className={this.state.orderingQ1 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                         />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "J’ai déjà un cadre. Comment puis-je savoir quelle taille de passe-partout j’ai besoin?",
                            "I already have a frame. How do I know what size mat board I need?"
                        )}

                    </div>

                </div>

                <div className={this.state.orderingQ1 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Si vous souhaitez regarder une courte vidéo sur ce sujet, cliquez ici. Les cadres sont dimensionnés en fonction des formats d’impression qu’ils tiennent. Un cadre de 11 x 14 pouces tiendra une impression de 11 x 14 pouces. La taille globale du passe-partout doit correspondre à la taille du cadre. Si vous ne savez pas quelle est la taille de votre cadre, vous pouvez mesurer le panneau en verre/acrylique ou le dos protecteur et cela vous donnera la taille du passe-partout dont vous avez besoin. Vous pouvez également mesurer le cadre lui-même et l’arrondir au format d’impression standard le plus proche. Pour les cadres sur mesure ou antiques, vous devez mesurer les dimensions intérieures de l’arrière du cadre. Réfléchissez à l’endroit où le passe-partout serait placé.",
                            "If you would like to watch a quick video on this please click here. Frames are sized based on the print sizes that they hold. An 11 x 14” frame, will hold an 11 x 14” print. The matboard overall size should be the frame size. If you do not know what size your frame is, you can measure the glass/ acrylic pane or the backing and that will give you the size of the mat board you need. Alternatively you can also measure the frame itself and round down to the nearest standard print size. For custom frames or for antique frames you will need to measure the inside dimensions of the back of the frame. Think about it in terms of where the mat board would sit."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ2")}>
                    <img src={dropdownArrow} className={this.state.orderingQ2 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Comment puis-je décider de la taille de la fenêtre de mon passe-partout?",
                            "How do I decide on the window size of my mat board?"
                        )}
                    </div>

                </div>

                <div className={this.state.orderingQ2 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Il existe 3 types généraux de passe-partout : fin, moyen et large. Plus votre cadre est grand. Avec des impressions plus petites, vous avez beaucoup de possibilités en termes de tailles de passe-partout et d’emplacements différents. Notre outil de conception vous permet de jouer avec le placement, la couleur, le passe-partout double et vous permet de convertir votre passe-partout à une fenêtre ronde.",
                            "There are 3 general types of matting: thin, medium and wide.  The larger your frame is. With smaller prints you have a lot to work with in terms of different matting sizes and placements. Our design tool allows you to play around with placement, colour, double matting and allows you to switch your matting to a round window."
                        )}
                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Pour voir des options de passe-partout plus larges, vous devrez inscrire de nouvelles dimensions sur la taille générale du passe-partout (qui est directement liée à la taille de votre cadre). Pour les œuvres plus grandes, votre passe-partout sera probablement plus mince, car vous êtes limité à la taille du cadre que vous pouvez obtenir pour l’impression, ce qui vous permet d’avoir des options plus larges pour le passe-partout.",
                            "To see wider matting options, you will have to put in new dimensions on the general size of the matting (which is directly related to your frame size). For larger artworks your matting will most likely be on the thinner side, as you are limited to how big a frame you can get for the work which allows you to have wider options for matting."
                        )}
                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "La fenêtre de votre passe-partout devrait cadrer votre image par au moins 0,25 pouce (ou 1/4 pouce) de chaque côté. Pour les impressions dépassant 16 x 20 pouces, la marge de cadrage doit être d’au moins 1 pouce de large. Si vous ne souhaitez pas cadrer votre image, vous devez vous assurer d’imprimer de votre image avec une marge égale ou supérieure à la taille de la fenêtre du passe-partout. Une autre option est d’acheter un dos protecteur, que vous pouvez coller à votre impression.",
                            "The window of your mat board should be cropping your image at the very least by a 0.25  inch (or ¼ inch) on each side. For prints that exceed 16 x 20”  that cropping margin should be at least 1 inch wide.  If you do not want your image cropped in any way,  you will have to make sure you print your image with a margin equal or greater than the size of the mat board window. Another option is to purchase a mat board backing, that you can adhere your print to."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ3")}>
                    <img src={dropdownArrow} className={this.state.orderingQ3 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                         />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Comment puis-je rendre mon ouverture de fenêtre décentrée?",
                            "How do I make my window opening off-centered?"
                        )}

                    </div>

                </div>

                <div className={this.state.orderingQ3 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "En utilisant notre outil de conception de passe-partout sur notre site Web, vous pouvez décentrer la fenêtre de votre passe-partout en cliquant sur les flèches juste en dehors de la fenêtre des passe-partout. Ces flèches apparaissent sur le passe-partout encadré se trouvant sur le côté gauche de la page. Lorsque vous cliquez sur les flèches, vous remarquerez que les dimensions des marges changent en fonction du déplacement de la fenêtre.",
                            "Using our mat board design tool on our website you can make your mat board’s window off-centred by clicking on the arrows just outside of the mat boards window. These arrows appear on the framed mat board that is  on the left hand side of the page. As you click on the arrows you will notice the dimensions of the margins will change in response to the shifting of the window."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ4")}>
                    <img src={dropdownArrow} className={this.state.orderingQ4 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Comment soumettre une conception personnalisée?",
                            "How do I submit a custom design?"
                        )}
                    </div>

                </div>

                <div className={this.state.orderingQ4 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Vous pouvez nous soumettre une conception personnalisée de deux façons : (1) vous pouvez faire votre propre dessin avec notre outil de dessin (voir ci-dessous pour des instructions supplémentaires) ou (2) vous pouvez nous envoyer votre design par courriel à hello@custommat.ca. Qu’il s’agisse d’une photo d’un dessin au stylo sur papier ou d’une maquette numérique, nous travaillerons avec vous à partir de là.",
                            "You can submit a custom design to us in two ways: (1) You can make your own drawing with our drawing tool (see below for further instructions) or (2) you can email us your design at hello@custommat.ca . Whether it’s a photo of a pen and paper drawing or a digital mockup, we will work with you from there."
                        )}
                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "1. Créez votre propre dessin avec notre outil de dessin :",
                            "1. Making your own drawing with our drawing tool:"
                        )}
                        <br/>
                        {this.fetchResponse(
                            "Étape 1 : Cliquez sur le bouton « Basculer vers l’outil de dessin »",
                            "Step 1: Click “Switch to Drawing Tool” button"
                        )}
                        <br/>
                        {this.fetchResponse(
                            "Étape 2 : Commencez à dessiner",
                            "Step 2: Start drawing"
                        )}
                        <br/>
                        {this.fetchResponse(
                            "Étape 3 : Choisissez votre taille, votre couleur et vos modèles",
                            "Step 3: Choose your size, colour, and designs"
                        )}
                        <br/>
                        {this.fetchResponse(
                            "Étape 4 : Soumettez la commande et payez",
                            "Step 4: Submit order & checkout"
                        )}
                        <br/>
                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "2. Envoyez-nous votre dessin :",
                            "2. Send your drawing to us:"
                        )}
                        <br/>
                        {this.fetchResponse(
                            "Étape 1 : Cliquez sur le bouton « Télécharger le dessin »",
                            "Step 1: Click “Upload drawing” button"
                        )}
                        <br/>
                        {this.fetchResponse(
                            "Étape 2 : Choisissez votre taille, votre couleur et vos modèles comme d’habitude",
                            "Step 2: Choose your size, colour, and designs as normal"
                        )}
                        <br/>
                        {this.fetchResponse(
                            "Étape 3 : Soumettez la commande et passez à la caisse",
                            "Step 3: Submit order & continue to checkout"
                        )}
                        <br/>
                        {this.fetchResponse(
                            "Étape 4 : Envoyez-nous votre dessin par courriel, séparément",
                            "Step 4: Email us your drawing, separately"
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ5")}>
                    <img src={dropdownArrow} className={this.state.orderingQ5 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                         />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Quelle est la largeur recommandée pour le passe-partout?",
                            "What is the recommended width for matting?"
                        )}

                    </div>

                </div>

                <div className={this.state.orderingQ5 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Nous vous recommandons de vous donner au moins un pouce de chaque côté. Si votre cadre dépasse 20 x 16 pouces, nous vous recommandons plus de 2 pouces de chaque côté.",
                            "We recommend you give yourself at least one inch on each side . If your frames exceed 20 x 16 inches, then we would recommend more than 2 inches on each side."
                        )}

                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ6")}>
                    <img src={dropdownArrow} className={this.state.orderingQ6 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                         />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Quelle est la plus petite largeur de passe-partout possible?",
                            "What is the smallest matting width you can have?"
                        )}
                    </div>

                </div>

                <div className={this.state.orderingQ6 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Nos machines peuvent couper un passe-partout jusqu’à un demi-pouce de chaque côté. Cependant, pour des raisons esthétiques, nous vous recommandons d’utiliser un passe-partout ayant au moins un pouce de chaque côté.",
                            "Our machines can cut upto half an inch matting on each side. However, for aesthetic purposes, we still recommend you use at least one inch matting on each side."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ7")}>
                    <img src={dropdownArrow} className={this.state.orderingQ7 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                         />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Qu’est-ce qu’un passe-partout double?",
                            "What is double matting?"
                        )}
                    </div>

                </div>

                <div className={this.state.orderingQ7 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Le passe-partout double est une couche supplémentaire de passe-partout qui se trouve sous votre premier passe-partout. Il est situé entre votre passe-partout principal et votre œuvre d’art. Le passe-partout double crée une autre couche de profondeur et une bordure supplémentaire à votre œuvre encadrée. Cela permet d’attirer davantage l’attention sur ce qui est encadré.",
                            "Double matting is an extra layer of matting that sits under your first mat board. It is situated between your primary mat and your artwork. Double matting creates another layer of depth and an additional border to your framed work. It helps to create more focus on what is framed."
                        )}
                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Le passe-partout double peut être de la même couleur que votre passe-partout principal ou d’une couleur différente pour faire ressortir votre œuvre. Souvent, une couleur dominante de votre œuvre d’art est choisie pour le passe-partout double afin de mettre en valeur la couleur d’accent.",
                            "Double matting can be in the same colour as your primary mat, or it can be in a different colour to make your artwork stand out. Often, a dominant colour of your artwork is chosen for the double matting so as to emphasize the accent colour."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ8")}>
                    <img src={dropdownArrow} className={this.state.orderingQ8 ? "faq-question-close" : "faq-question-open"}
                         alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}
                    />
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Qu’est-ce que la partie apparente du passe-partout double?",
                            "What is a double matting reveal?"
                        )}

                    </div>

                </div>

                <div className={this.state.orderingQ8 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "La partie apparente du passe-partout double fait référence à la largeur de la zone où le passe-partout double est visible. Par exemple, si vous avez sélectionné un passe-partout double «noir de jais » de 0,25 pouce, il y aura 0,25 pouce de passe-partout «noir de jais» apparent (visible) entre votre illustration et le passe-partout principal.",
                            "A double matting reveal refers to the width of the area where the double matting is shown. For instance, If you selected “smooth black” double matting of 0.25 inches, there will be 0.25 inches of “smooth black” mat board revealed (visible) between your artwork and the primary matboard."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ9")}>
                    <img src={dropdownArrow} className={this.state.orderingQ9 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Le passe-partout double présente-t-il également des coupes en biseau?",
                            "Does double matting have bevel cuts as well?"
                        )}

                    </div>

                </div>

                <div className={this.state.orderingQ9 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oui, par défaut, tous nos passe-partout sont coupés avec une coupe en biseau à centre blanc de 45˚. Y compris le passe-partout double. Si vous souhaitez éliminer les coupes inclinées, veuillez sélectionner une coupe à 90° comme préférence.",
                            "Yes, by default, all our mat boards are cut with a 45˚ white core bevel cut.  Including the double matting. If you want to eliminate angled cuts then please select a 90º cut as your preference."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ10")}>
                    <img src={dropdownArrow} className={this.state.orderingQ10 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Ai-je toujours besoin d’un passe-partout double?",
                            "Do I always need double matting?"
                        )}
                    </div>

                </div>

                <div className={this.state.orderingQ10 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Le passe-partout double est selon votre choix. Le passe-partout double crée une autre couche de profondeur. Il ajoute un autre élément d’attention à votre travail.",
                            "Double matting is completely up to you. Double matting creates another layer of depth. It adds another element of focus to your work."
                        )}

                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Le passe-partout double peut être de la même couleur que votre passe-partout principal ou d’une couleur différente pour faire ressortir votre œuvre! Souvent, une couleur dominante de votre œuvre d’art est choisie pour le passe-partout double. Ce sera la couleur d’accent.",
                            "Double matting can be in the same colour as your primary mat, or it can be in a different colour to make your artwork pop! Often, a dominant colour from your artwork is chosen to be the double matting. This will be the accent colour."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ11")}>
                    <img src={dropdownArrow} className={this.state.orderingQ11 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Pouvez-vous couper un passe-partout personnalisé ayant un design complexe?",
                            "Can you cut a custom matboard composed of a complex design?"
                        )}
                    </div>

                </div>

                <div className={this.state.orderingQ11 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oui. Nous sommes capables de réaliser une multitude de designs complexes. Si vous avez des doutes ou des questions concernant la complexité de votre design, veuillez nous envoyer un courriel avec votre dessin. Notre équipe en prendra connaissance et vous fera savoir si c’est possible ou quels changements, le cas échéant, doivent être apportés.",
                            "Yes. We are able to do a multitude of complex designs. If you have any doubts or questions relating to the complexity of your design, please send us an email with your drawing. Our team will take a look at it and let you know if it’s possible or what changes - if any - need to be made."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ12")}>
                    <img src={dropdownArrow} className={this.state.orderingQ12 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Pouvez-vous écrire ou imprimer sur votre passe-partout?",
                            "Can you write or print on your matboard?"
                        )}
                    </div>

                </div>

                <div className={this.state.orderingQ12 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oui. Nous avons des bras robotisés pouvant écrire et imprimer sur le passe-partout. Vous pouvez également écrire ou signer sur le passe-partout.",
                            "Yes. We have robotic arms that we can write and print on the matboard. You can also write or sign on the matboard."
                        )}

                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ13")}>
                    <img src={dropdownArrow} className={this.state.orderingQ13 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Pouvez-vous faire une découpe de vinyle?",
                            "Can you do a vinyl cutting?"
                        )}
                    </div>

                </div>

                <div className={this.state.orderingQ13 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oui. Nous avons des bras robotisés avec lesquels nous pouvons couper le vinyle et le coller sur le passe-partout. Des frais supplémentaires peuvent s’appliquer.",
                            "Yes. We have robotic arms that we can cut vinyl and paste on the matboard. Extra charges may apply.",
                        )}

                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ14")}>
                    <img src={dropdownArrow} className={this.state.orderingQ14 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Qu’est-ce que le dos protecteur du passe-partout?",
                            "What is the matboard backing?"
                        )}

                    </div>

                </div>

                <div className={this.state.orderingQ14 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Un dos protecteur de passe-partout est un passe-partout sans ouverture. Le dos protecteur aura les mêmes dimensions extérieures que votre passe-partout principal et est fabriqué du même matériau. Les dos protecteurs de passe-partout peuvent être utilisés pour sécuriser votre passe-partout et votre œuvre dans le cadre. Ils peuvent également être utilisés pour un montage flottant ou pour coller une impression si vous utilisez un matériel d’impression fragile ou fin ou si vous ne souhaitez pas cadrer votre image.",
                            "A mat board backing is a matboard without an opening. The backing board will have the same exterior dimensions as your primary matboard and is the exact same board material. Matboard backings can be used to  sandwich your matboard and art snugly in the frame. They can also be used for float mounting or to adhere a print to if you are using fragile or thin printing material or do not want to crop your image."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ15")}>
                    <img src={dropdownArrow} className={this.state.orderingQ15 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Ai-je besoin d’un dos protecteur de passe-partout?",
                            "Do I need a mat board backing?"
                        )}
                    </div>

                </div>
                <div className={this.state.orderingQ15 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Dans la plupart des cas, vous n’avez pas besoin d’un dos protecteur de passe-partout. Ils ne sont pas toujours nécessaires. Les dos protecteurs de passe-partout sont généralement utilisés lorsque votre illustration ou impression est sur un matériau fin et doit être étirée ou fixée sur une autre couche pour du support.",
                            "In most cases, you do not need a mat board backing. They are not always necessary. Mat board backings are typically used when  your artwork or print are on the thin side and need to be stretched or affixed on top of another layer for support."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ16")}>
                    <img src={dropdownArrow} className={this.state.orderingQ16 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Puis-je choisir une couleur différente pour mon dos protecteur de passe-partout?",
                            "Can I choose a different colour for my backing matboard?"
                        )}
                    </div>

                </div>

                <div className={this.state.orderingQ16 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Tous nos dos protecteurs de passe-partout sont offerts en «blanc doux» par défaut. Si vous souhaitez que le dos protecteur soit d’une couleur différente, veuillez nous le faire savoir dans la section «Notes supplémentaires» de la page de conception.",
                            "All our backing mat boards come in “Smooth White” by default. If you would like the backing to be in a different colour, please let us know in the  “Additional Notes” section on the design page."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ17")}>
                    <img src={dropdownArrow} className={this.state.orderingQ17 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Avez-vous des cadres pour images à vendre?",
                            " Do you have picture frames for sale?"
                        )}

                    </div>

                </div>

                <div className={this.state.orderingQ17 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Non, malheureusement, nous n’en avons pas. Actuellement, nous sommes uniquement un vendeur de passe-partout et ne vendons pas de cadres pour images.",
                            "No, unfortunately, we do not. Currently, we are solely a matboard seller and do not sell picture frames.",
                        )}

                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Les cadres de taille standard sont facilement accessibles en ligne ou chez les détaillants locaux. En achetant un cadre standard et en créant un passe-partout personnalisé avec CustomMat.ca vous pouvez obtenir l’aspect de «cadre personnalisé» pour la moitié du coût.",
                            "Standard sized frames are easily accessible online or at local retailers. By purchasing a standard frame and creating a custom matting with CustomMat.ca you can achieve the look of “custom framing” for half the cost."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ18")}>
                    <img src={dropdownArrow} className={this.state.orderingQ18 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Vendez-vous des verres pour cadres?",
                            "Do you sell framing glasses?"
                        )}
                    </div>

                </div>

                <div className={this.state.orderingQ18 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Non, malheureusement, nous n’en avons pas. Nous vendons uniquement des passe-partout personnalisés.",
                            "No, unfortunately, we do not. We only sell custom mat boards."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ19")}>
                    <img src={dropdownArrow} className={this.state.orderingQ19 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Vendez-vous des passe-partout en mousse?",
                            "Do you sell foam boards?"
                        )}
                    </div>

                </div>

                <div className={this.state.orderingQ19 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Non, malheureusement, nous n’en avons pas. Nous vendons uniquement des passe-partout personnalisés. Cependant, nos dos protecteurs de passe-partout peuvent atteindre un but similaire.",
                            "No, unfortunately, we do not. We only sell custom mat boards. However, our backing matboards can achieve a similar purpose."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ20")}>
                    <img src={dropdownArrow} className={this.state.orderingQ20 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Vendez-vous des œuvres d’art ou des images photo?",
                            "Do you sell artwork or photo prints?"
                        )}

                    </div>

                </div>

                <div className={this.state.orderingQ20 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Non, malheureusement, nous n’en avons pas.",
                            "No, unfortunately, we do not."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ21")}>
                    <img src={dropdownArrow} className={this.state.orderingQ21 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Puis-je rendre l’intérieur du passe-partout ovale (circulaire/rond)?",
                            "Can I make the inside of the mat board oval (circular / round)?"
                        )}

                    </div>

                </div>

                <div className={this.state.orderingQ21 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oui! N’hésitez pas à cliquer sur l’option «Fenêtres arrondies» lorsque vous concevez votre passe-partout. Elle apparaît sous la section «Taille de fenêtre» de notre page de conception.",
                            "Yes! Please feel free to click  the “Rounded Windows”option when you are designing your matboard. It appears below the “Window Size” section of our design page."
                        )}

                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ22")}>
                    <img src={dropdownArrow} className={this.state.orderingQ22 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Puis-je rendre mon passe-partout entièrement ovale (circulaire/rond)?",
                            "Can I make my entire mat board oval (circular / round)?"
                        )}
                    </div>

                </div>

                <div className={this.state.orderingQ22 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oui! Veuillez indiquer que vous souhaitez que votre passe-partout soit de forme ovale dans la section « Notes supplémentaires » de la page de conception, et nous nous en occuperons à partir de là.",
                            "Yes! Please indicate that you want your matboard itself to be oval in shape  in the “Additional Notes” section of the Design page and we will take care of it from there."
                        )}

                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ23")}>
                    <img src={dropdownArrow} className={this.state.orderingQ23 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Puis-je avoir mon passe-partout sans l’ouverture?",
                            "Can I have my mat board without the opening?"
                        )}
                    </div>

                </div>

                <div className={this.state.orderingQ23 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oui! Veuillez saisir 0 x 0 comme fenêtre d’ouverture.",
                            "Yes! Please enter 0 x 0 as your window opening."
                        )}

                    </div>
                </div>


                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ24")}>
                    <img src={dropdownArrow} className={this.state.orderingQ24 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Qu’est-ce qu’une coupe en biseau?",
                            "What is a bevel cut?"
                        )}

                    </div>

                </div>

                <div className={this.state.orderingQ24 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Une coupe en biseau est une coupe en angle. La coupe par défaut que nous utilisons chez CustomMat.ca est biseautée à 45 degrés. Vous pouvez également demander que les coupes soient effectuées à un angle de 90 degrés. Cela permet à la machine de couper tout droit lors de la coupe de la fenêtre, au lieu de faire les coupes en angle. Une coupe biseautée permet de montrer l’intérieur blanc du passe-partout, ce qui ajoute de la profondeur à l’œuvre.",
                            "A bevel cut is an angled cut. The default cut that we use at CustomMat.ca is beveled at 45 degrees. You can also request the cuts to be made at a  90 degree angle. This will make it so the machine will cut straight down when cutting the window, instead of making the cuts on an angle. Having a beveled cut allows the white interior of the matboard to show through, which adds depth to the work."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ25")}>
                    <img src={dropdownArrow} className={this.state.orderingQ25 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Puis-je avoir mon passe-partout avec une coupe à 90 degrés, au lieu du biseau de 45 degrés?",
                            "Can I have my mat board with a 90 degree cut, instead of the 45 degree bevel?"
                        )}

                    </div>

                </div>

                <div className={this.state.orderingQ25 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oui. Pour obtenir l’option de coupe à 90 degrés, veuillez sélectionner l’option de coupe à 90 degrés dans le menu. Cela permet de rendre l’intérieur blanc du passe-partout moins ou pas visible.",
                            "Yes. To have the  90 degree cut option, please select the 90 degree cut option on the menu. This will make it so that the interior white of the matboard is less/ not visible."
                        )}
                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Pour que le blanc soit visible avec la coupe biseautée à 45 degrés, veuillez sélectionner l’option biseau à 45 degrés dans le menu. Cette option est déjà sélectionnée, car il s’agit de notre option par défaut.",
                            "To have the white visible with the 45 degree bevel cut, please select the 45 degree bevel option on the menu. This option is preselected as it is our default."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ26")}>
                    <img src={dropdownArrow} className={this.state.orderingQ26 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Les passe-partout de couleur sont-ils de la même couleur à l’intérieur que sur la surface?",
                            "Are your coloured mat boards the same colour inside as on the surface?"
                        )}
                    </div>


                </div>

                <div className={this.state.orderingQ26 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Non. Nos passe-partout couleur sont seulement colorés sur l’avant du passe-partout. L’arrière et l’intérieur de nos passe-partout sont blancs. Les fenêtres de nos passe-partout sont coupées en biseau à 45 degrés, ce qui permet d’afficher le centre blanc du passe-partout. Si vous préférez que votre fenêtre soit coupée à un angle de 90 degrés, veuillez l’indiquer dans la section «Notes supplémentaires» de la page de conception.",
                            "No. Our coloured mat boards are only coloured on the front of the board. The back and the inside of our mat boards are white. Our mat boards’ windows are cut on a 45 degree bevel allowing the white core of the mat board to be displayed. If you would prefer your window to be cut on a 90 degree angle, please specify in the “Additional Notes” section of the Design page."
                        )}
                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "*Veuillez noter que tous nos passe-partout sont des passe-partout avec un centre blanc standard coupé par défaut à un biseau de 45 degrés. Nous ne proposons pas de passe-partout avec centres de production ou de centres solides. Nous proposons uniquement une coupe à angle de 45 degrés ou, si spécifié, une coupe à angle de 90 degrés.",
                            "*Please note that all of our mat boards are standard white core mats cut by default at a 45 degree bevel. We do not offer production core or solid core mats. We only offer 45 degree angle cutting or if specified, 90 degree angle cutting."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ27")}>
                    <img src={dropdownArrow} className={this.state.orderingQ27 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Quelle est l’épaisseur de votre passe-partout?",
                            "How thick is your matboard?"
                        )}
                    </div>
                </div>

                <div className={this.state.orderingQ27 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Tous nos passe-partout sont des passe-partout standard à 4 épaisseurs. Ils ont environ 1,5 mm d’épaisseur.",
                            "All of our mat boards are standard 4 ply matboards. They are about 1.5 mm thick."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ28")}>
                    <img src={dropdownArrow} className={this.state.orderingQ28 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Faites-vous des passe-partout à 8 épaisseurs?",
                            "Do you do 8 ply mats?"
                        )}
                    </div>


                </div>

                <div className={this.state.orderingQ28 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Non. Nous ne proposons que des passe-partout standard à 4 épaisseurs.",
                            "No. We only do standard 4 ply mats."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("orderingQ29")}>
                    <img src={dropdownArrow} className={this.state.orderingQ29 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "De quels matériaux vos passe-partout sont-ils fabriqués?",
                            "What are your mat boards made of?"
                        )}

                    </div>


                </div>

                <div className={this.state.orderingQ29 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Tous nos passe-partout sont fabriqués à partir de papier cellulose alpha sans acide (neutralisé par l’acide).",
                            "All of our mat boards are made from acid free (acid neutralized) Alpha cellulose paper."
                        )}

                    </div>
                </div>
            </div>

            <div className={"faq-question-bloc"}>

                <div className={"faq-static-page-container-heading"}>
                    {this.fetchResponse(
                        "COMMENT CELA FONCTIONNE",
                        "How it works"
                    )}

                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("howItWorksQ1")}>
                    <img src={dropdownArrow} className={this.state.howItWorksQ1 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>

                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Comment passez-vous une commande?",
                            "How do you place an order?"
                        )}

                    </div>

                </div>

                <div className={this.state.howItWorksQ1 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>


                    </div>

                    <li>
                        {this.fetchResponse(
                            "Veuillez vous reporter à cette page.",
                            "Please refer to this page."
                        )}
                        <ol>
                            {this.fetchResponse(
                                "Choisissez l’œuvre",
                                "Choose the art"
                            )}
                        </ol>
                        <ol>
                            {this.fetchResponse(
                                "Choisissez votre cadre",
                                "Choose your frame"
                            )}

                        </ol>
                        <ol>
                            {this.fetchResponse(
                                "Rendez-vous sur custommat.ca et cliquez sur le lien «Concevoir maintenant»",
                                "Go to custommat.ca and click on the “Design Now” link"
                            )}

                        </ol>
                        <ol>
                            {this.fetchResponse(
                                "Saisissez la taille du passe-partout (il s’agit également de la taille de votre cadre).",
                                "Input the mat board size  (this is also your frame size)"
                            )}

                        </ol>
                        <ol>
                            {this.fetchResponse(
                                "Choisissez la couleur de votre passe-partout (vous pouvez ajouter une photo de l’œuvre dans la fenêtre centrale pour vous aider à choisir votre couleur)",
                                "Choose your mat board colour (you can add a photo of the art into the centre window to help you choose your colour)"
                            )}

                        </ol>
                        <ol>
                            {this.fetchResponse(
                                "Choisissez la taille de la fenêtre (la fenêtre doit avoir une largeur de bordure d’au moins 1/2 pouces ~ pour les impressions dépassant 12 po x 16 po, nous recommandons une largeur de bordure de 1 pouce).",
                                "Choose the size of the window (the window must have  at least a  1/2 inch border width ~ for prints that exceed 12 x 16 we recommend a border width of 1 inch)"
                            )}

                        </ol>
                        <ol>
                            {this.fetchResponse(
                                "Décidez si vous souhaitez un passe-partout double.",
                                "Decide if you would like double matting."
                            )}

                        </ol>
                        <ol>
                            {this.fetchResponse(
                                "Une fois que vous êtes satisfait, passez à la caisse et remplissez les informations de livraison et de facturation nécessaires.",
                                "Once you are happy, proceed to the checkout, and fill in the necessary shipping and billing information."
                            )}

                        </ol>
                        <ol>
                            {this.fetchResponse(
                                "Attendez votre courriel de confirmation et, une fois expédiée, votre courriel de suivi. Une fois la commande passée, nous l’expédions généralement dans les 1 à 2 jours ouvrables et votre commande sera ensuite livrée dans les 2 à 8 jours ouvrables à compter de cette date.",
                                "Wait for your confirmation email, and once shipped, your tracking email. After an order is placed, we typically ship within 1-2 business days and your order will then be delivered within 2-8 business days from then."
                            )}
                        </ol>
                    </li>


                </div>
            </div>

            <div className={"faq-question-bloc"}>
                <div className={"faq-static-page-container-heading"}>
                    {this.fetchResponse(
                        "TAILLE",
                        "Sizing"
                    )}
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("sizingQ1")}>
                    <img src={dropdownArrow} className={this.state.sizingQ1 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "De quelle taille ai-je besoin?",
                            "What size of mat board do I need?"
                        )}
                    </div>

                </div>

                <div className={this.state.sizingQ1 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Le passe-partout dont vous avez besoin sera déterminé par la taille du cadre (cela déterminera la taille globale du passe-partout), la taille de l’impression (cela déterminera l’espace dont vous disposez pour votre passe-partout) et la bordure du passe-partout souhaitée (ceci déterminera la fenêtre qui présentera votre impression).",
                            "The mat board you need will be determined by the size of the frame (this will determine the overall size of the board), the size of the print (this will determine how much room you have to work with for your matting) and the desired mat board border (this will determine the window that will showcase your print)."
                        )}
                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Lors de l’utilisation de passe-partout, il faut généralement commencer par choisir un cadre ayant 1 taille plus grande que votre impression. Si vous voulez un passe-partout très large pour votre impression, choisissez un cadre d’au moins 2 tailles plus grandes que votre impression.",
                            "When using mat boards it is standard to firstly choose a frame that is 1 size larger than your art print. If you want a very wide matting for your print, choose a frame at least 2 sizes larger than your print."
                        )}
                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Rappel: Le passe-partout encadrera votre impression. La marge la plus mince à laquelle nous coupons nos fenêtres de passe-partout à est ½ pouce de chaque côté. Si vous ne souhaitez pas que votre image soit encadrée, vous devez l’imprimer avec une bordure d’au moins ½ pouce. À partir de 12 po x 16 po, vous devez utiliser une bordure de 1 pouce.",
                            "Reminder: Matting will crop your art print. The thinnest margin we cut our matboard windows at is a ½ inch on each side. If you do not want your image to be cropped then your image needs to be printed with a border of at least ½ inches. Starting at 12 x 16 you should use a 1 inch border"
                        )}

                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("sizingQ2")}>
                    <img src={dropdownArrow} className={this.state.sizingQ2 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Quelle est la plus grande taille de passe-partout que vous pouvez découper?",
                            "What is the largest mat board size that you can cut?"
                        )}

                    </div>

                </div>

                <div className={this.state.sizingQ2 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Le plus grand passe-partout que nous pouvons découper est de 32 x 40 pouces. Généralement ce passe-partout est acheté de nous avec l’intention de l’utiliser comme passe-partout de sport, car il convient parfaitement à un chandail de sport.",
                            "The biggest mat board we can cut is 32 x 40 inches. Commonly this matting is purchased from us with the intention of being used for sports matting, as it perfectly fits a sports jersey."
                        )}
                    </div>
                </div>
            </div>

            <div className={"faq-question-bloc"}>
                <div className={"faq-static-page-container-heading"}>
                    {this.fetchResponse(
                        "DEMANDES DE RENSEIGNEMENTS SUR LES PRODUITS",
                        "Product inquiries",
                    )}


                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("productQ1")}>
                    <img src={dropdownArrow} className={this.state.productQ1 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    {this.fetchResponse(
                        "Où et comment traitez-vous vos passe-partout?",
                        "Where & how do you process your matboard?"
                    )}

                </div>

                <div className={this.state.productQ1 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "99 % de nos passe-partout proviennent du Canada et y sont fabriqués. Certains passes-partouts de spécialités que nous avons proviennent du Royaume-Uni et des États-Unis.",
                            "99% of our matboards are sourced and produced in Canada. Some speciality matboards that we have are sourced from the United Kingdom and the United States."
                        )}
                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Nos bureaux et nos installations de production sont situés à Toronto et à Vancouver. Nos passe-partout sont coupés avec un équipement standard conçu pour couper précisément à 45 degrés (coupe en biseau).",
                            "Our office and production facilities are located in Toronto and Vancouver. Our matboards are cut with industry-standard equipment designed to cut precisely at 45 degrees (bevel cutting)."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("productQ2")}>
                    <img src={dropdownArrow} className={this.state.productQ2 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Puis-je commander un échantillon de produit?",
                            "Can I order a product sample?"
                        )}
                    </div>

                </div>

                <div className={this.state.productQ2 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oui. Vous pouvez commander notre catalogue d’échantillons à partir du lien ci-dessous -- (le lien sera ajouté ici)",
                            "Yes. You can order our sample catalogue from the link below -- (Link will be added here)"
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("productQ3")}>
                    <img src={dropdownArrow} className={this.state.productQ3 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Pouvez-vous m’en dire plus sur la qualité de vos passe-partout? Sont-ils sans d’acide?",
                            "Can you tell me more about your matboard quality? Are they acid-free?"
                        )}
                    </div>

                </div>

                <div className={this.state.productQ3 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Oui. Nos passe-partout sont sans acide avec des surfaces, centres et dos protecteurs neutralisés à l’acide pour la préservation de vos œuvres d’art. Nos passe-partout à 4 épaisseurs offrent une coloration cohérente. Ils sont tamponnés et alcalins à un pH de 7,5 à 9,5 pour les besoins de préservation. Ils sont résistants aux fuites et à la décoloration. Ils sont coupés avec un biseau de 45 degrés, sauf indication contraire.",
                            "Yes. Our matboards are acid-free with acid-neutralized surfaces, core, and backing for your artwork preservation. Our 4 ply mat boards provide coherent colouring. They are alkaline-buffered to a pH of 7.5 - 9.5 for preservation needs. They are bleed and fade resistant. They are cut with 45 degree bevel, unless you specify otherwise."
                        )}

                    </div>
                </div>
            </div>

            <div className={"faq-question-bloc"}>
                <div className={"faq-static-page-container-heading"}>
                    {this.fetchResponse(
                        "DONNÉES ET CONFIDENTIALITÉ",
                        "Data & Privacy"
                    )}


                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("dataQ1")}>
                    <img src={dropdownArrow} className={this.state.dataQ1 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Mon compte est-il sécurisé? Stockez-vous les informations de ma carte de crédit?",
                            "Is my account secure? Do you store my credit card information?"
                        )}

                    </div>

                </div>

                <div className={this.state.dataQ1 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Chez CustomMat.ca, nous stockons toutes vos informations de clients sur les bases de données sécurisées et chiffrées d’Amazon et de Google. Bien sûr, pour une sécurité maximale, toutes vos données seront CRYPTÉES à l’aide d’algorithmes de chiffrement très complexes. Nous faisons de notre mieux pour stocker vos informations de commande en toute sécurité.",
                            "At CustomMat.ca, we store all your client information on Amazon and Google’s secure, encrypted database. Of course, for maximum security, all your data points will be ENCRYPTED with highly complex ciphering algorithms. We do our best to store your order information safely."
                        )}
                    </div>
                </div>

                <div className={"faq-question"} onClick={() => this.toggleModal("dataQ2")}>
                    <img src={dropdownArrow} className={this.state.dataQ2 ? "faq-question-close" : "faq-question-open"} alt={this.state.french ? "Cliquez pour voir les réponses aux questions suivantes." : "Click to view the answers to the following questions."}/>
                    <div className={"faq-question-right"}>
                        {this.fetchResponse(
                            "Stockez-vous le numéro de ma carte de crédit?",
                            "Do you store my credit card number?"
                        )}
                    </div>

                </div>

                <div className={this.state.dataQ2 ? "faq-answer" : "no-display"}>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Nous N’ENREGISTRONS PAS les informations de votre carte de crédit. De plus, nous ne pouvons jamais voir les informations de votre carte de crédit. Tous nos paiements sont liés à Stripe, notre fournisseur de services financiers tiers.",
                            "We DO NOT save your credit card information. Additionally, we never end up even seeing any of your credit card information. All of our payments are linked to Stripe, our third-party financial services provider."
                        )}

                    </div>
                    <div className={"faq-answer-paragraph"}>
                        {this.fetchResponse(
                            "Les informations et le traitement de votre carte de crédit seront effectués par le biais de Stripe. Stripe est l’un des processeurs de paiement les plus prisés parmi les entreprises technologiques. Sa sécurité a été prouvée par des années d’expérience et des milliers d’entreprises qui utilisent les services de Stripe tous les jours.",
                            "Your credit card information and handling will be done through Stripe. Stripe is one of the most favored payment processors among tech companies. It’s security has been proved by years of experience and thousands of companies using Stripe’s services everyday."
                        )}
                    </div>
                </div>
            </div>

            <div className={"faq-question-bloc"}>
                <div className={"faq-static-page-container-heading"}>
                    Other Related Articles.
                </div>

                <div className={"faq-answer"}>
                    <div className={"faq-answer-paragraph"}>
                        <ol>
                            <li>
                                <span>Detailed FAQ</span>
                                <span> -- </span>
                                <a href={"/faq-in-detail"}>
                                    <u>Learn More</u>
                                </a>
                                <span> -- </span>
                                <a href={"/faq-in-detail-fr"}>
                                    <u>Learn More (in French)</u>
                                </a>
                            </li>

                            <li>
                                <span>Minimum Order Quantity</span>
                                <span> -- </span>
                                <a href={"/matboard-no-minimum-order-quantity"}>
                                    <u>Learn More</u>
                                </a>
                                <span> -- </span>
                                <a href={"/matboard-no-minimum-order-quantity-fr"}>
                                    <u>Learn More (in French)</u>
                                </a>
                            </li>

                            <li>
                                <span>Guide to Finding a Right (Standard Sized) Picture Frame</span>
                                <span> -- </span>
                                <a href={"/guide-to-find-a-right-picture-standard-sized-picture-frame"}>
                                    <u>Learn More</u>
                                </a>
                                <span> -- </span>
                                <a href={"/guide-to-find-a-right-picture-standard-sized-picture-frame-fr"}>
                                    <u>Learn More (in French)</u>
                                </a>
                            </li>


                        </ol>



                    </div>
                </div>
            </div>

        </div>
    }
}