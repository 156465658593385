import React from "react";
import landingPageVideo from "../../../../resources/video/backgroundVideoCompresse.mp4";
import staticImageMobileV2 from "../../../../resources/logo/custommat-v2-mobile-stock-photo.png";
import LazyLoad from 'react-lazyload';
import staticImage from "../../../../resources/images/landingPage/MatInstructionsPhoto.jpg";
import staticImageV2 from "../../../../resources/images/landingPage/landingPage2023.png";
import landingPagePhotoV3 from "../../../../resources/images/landingPage/landing-page-photo-v3.jpg";
import landingPagePhotoV3Mobile from "../../../../resources/images/landingPage/landing-page-photo-v3-mobile.jpg";


export default class LandingPageVideo extends React.Component {

  fetchTitle() {
    return <h1 className={"landing-page-subtext-first-v2 mobile-no-display tablet-no-display"}>
      <div>
        <div className={"landing-page-subtext-first-v2-lead mobile-no-display"}>
          {this.props.french ? "PASSE-PARTOUTS": "Custom"} <br/>
          {this.props.french ? "PERSONNALISÉS": "Matboards"}
        </div>

        <div className={"landing-page-subtext-first-v2-sublead"}>
          {this.props.french ? "Ajustez parfaitement votre": "Perfectly fit your"} <br/>
          {this.props.french ? "œuvre dans n'importe quel cadre.": "artwork into any frame."}
        </div>


      </div>
      <div className={"landing-page-product-list"}>
        <a href={this.props.french ? "/customize-matboards-fr" : "/customize-matboards"} className={"landing-page-start-button-v2"}>
          {this.props.french ? "Je crée" : "CREATE YOUR CUSTOM MAT"}
        </a>
      </div>
    </h1>
  }

  fetchMobileTitle() {
    return <div className={"mobile-landing-page-title mobile-and-tablet-display-only"}>
      <h1 className={"mobile-landing-page-title-head"}>Custom Matboards</h1>
      <h3 className={"mobile-landing-page-title-subhead"}>Perfectly fit your artwork <br/> into any frame.</h3>
      <div className={"landing-page-product-list tablet-display-only"}>
        <a href={this.props.french ? "/customize-matboards-fr" : "/customize-matboards"} className={"landing-page-start-button-v2"} style={{borderRadius: "64px"}}>
          {this.props.french ? "Je crée" : "CREATE YOUR CUSTOM MAT"}
        </a>
      </div>
    </div>
  }


  render() {
    return <div className={"landing-page-header-v2"}>
      {/*<div className={"landing-page-image-overlay-black-v2 mobile-display-only"}>*/}
      {/*</div>*/}


      {this.fetchMobileTitle()}

      <div className={"landing-page-product-list mobile-display-only"}>
        <a href={this.props.french ? "/customize-matboards-fr" : "/customize-matboards"} className={"landing-page-start-button-v2"}>
          {this.props.french ? "Je crée" : "CREATE YOUR CUSTOM MAT"}
        </a>
      </div>

      <LazyLoad>
        <div className={"landing-page-photo-overlay mobile-no-display tablet-no-display"}></div>
        <img className={"landing-page-image-overlay-image-v2 landing-page-subtext-first-v2-lead mobile-no-display tablet-no-display"} src={landingPagePhotoV3}></img>
        <img className={"landing-page-image-overlay-image-v2 mobile-and-tablet-display-only"} src={landingPagePhotoV3Mobile} alt={this.props.french ? "Il s'agit d'une image d'arrière-plan pour illustrer nos passe-partout personnalisés." : "This is a background image to illustrate our featured custom matboards."}/>
      </LazyLoad>

      {this.fetchTitle()}


      <script src="https://static.elfsight.com/platform/platform.js" async></script>

      <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4" data-elfsight-app-lazy></div>

    </div>
  }
}