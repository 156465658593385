import React from "react";
import landingPageReviewStars from "../../../resources/logo/landing-page-star.svg";
import customizationImg from "../../../resources/svgIcons/cutomization.svg";
import thumbup from "../../../resources/svgIcons/thumbup.svg";
import delivery from "../../../resources/svgIcons/shippingLogo.svg";

export default class LandingPageFAQ extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      // testValue: 1,
      scrolled: false,
      currencySelected: "CAD",
      displayType: "type1",

    };

  };

  render() {
    return <div className={"landing-page-bottom-cover"}>

      <div className={"landing-page-bottom"} id={"special-memories-container"}>
        {this.props.french ? "SOUVENIRS SPÉCIAUX," : "Special memories,"} <br/>
        {this.props.french ? "PRÉSENTÉS DE MANIÈRE UNIQUE" : "uniquely Displayed"}
        <br/>

      </div>

      <div className={"landing-page-bottom-stars"}>
        <img className={"review-transition-star"} src={landingPageReviewStars} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>
        <img className={"review-transition-star"} src={landingPageReviewStars} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>
        <img className={"review-transition-star"} src={landingPageReviewStars} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>
        <img className={"review-transition-star"} src={landingPageReviewStars} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>
        <img className={"review-transition-star"} src={landingPageReviewStars} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>
      </div>

      <div className={"landing-page-bottom-text"}>
        <span className={"landing-page-bottom-text-highlight"}>
          4.9 / 5.0<br/>
        </span>
        {this.props.french ? "Soutenu par plus de" : "Backed with over 4,000"}<br/>
        {this.props.french ? "4 000 avis 5 étoiles" : "5 star reviews"}
      </div>

      <div className={"landing-page-bottom-text"}>
        <img src={customizationImg} className={"landing-page-bottom-text-img mobile-display-only"}/>
        <span className={"landing-page-bottom-text-highlight"}>
          {this.props.french ? "Entièrement personnalisable" : "Fully Customizable"}<br/>
        </span>
        {this.props.french ? "De toute taille, forme ou couleur" : `Any Size, Shape, or ${this.props.location === "us" ? "Color" : "Colour"}`}
      </div>

      <div className={"landing-page-bottom-text"}>
        <img src={thumbup} className={"landing-page-bottom-text-img mobile-display-only"}/>
        <span className={"landing-page-bottom-text-highlight"}>
          {this.props.french ? "100 % de bonheur garanti" : "100% Happiness Guaranteed"}<br/>
        </span>
        {this.props.french ? "Si vous n'êtes pas satisfait de votre commande," : "If you are not happy with your order,"} <br className={"mobile-no-display"}/>
        {this.props.french ? "faites-le nous savoir et nous rectifierons la situation." : "let us know and we will make it right."}
      </div>

      <div className={"landing-page-bottom-text"}>
        <img src={delivery} className={"landing-page-bottom-text-img mobile-display-only"}/>
        <span className={"landing-page-bottom-text-highlight"}>
          {this.props.french ? "Livraison gratuite partout au Canada et aux États-Unis" : `Free Shipping across Canada & the USA`}<br/>
        </span>
        {this.props.french ? "Des milliers de cartons à biseaux entièrement personnalisés," : "Thousands of fully customized matboards,"}<br className={"mobile-no-display"}/>
        {this.props.french ? "expédiés chaque mois." : "Shipped every month."}

      </div>

      <div className={"landing-page-bottom-start-button"}>
        <a href={this.props.french ? "/customize-matboards-fr" : "/customize-matboards"}>
          <div className={"landing-page-button-standard-v2 how-it-works-page-button"}
               id={"explainer-start-designing-button"}>
            {this.props.french ? "Je crée " : "START DESIGNING"}
          </div>
        </a>
      </div>
    </div>
  }
}