import React from "react";
import landingPageArrowLeft from "../../../resources/logo/landing-page-arrow-left.svg";
import landingPageReviewStars from "../../../resources/logo/landing-page-star.svg";
import landingPageArrowRight from "../../../resources/logo/landing-page-arrow-right.svg";
import img1 from "../../../resources/images/customers/1.jpg";
import img2 from "../../../resources/images/customers/2.jpg";
import img3 from "../../../resources/images/customers/3.jpg";
import img4 from "../../../resources/images/customers/4.jpg";
import img5 from "../../../resources/images/customers/5.jpg";
import img6 from "../../../resources/images/customers/6.jpg";
import img7 from "../../../resources/images/customers/7.jpg";
import img8 from "../../../resources/images/customers/8.jpg";
import img9 from "../../../resources/images/customers/9.jpg";
import img10 from "../../../resources/images/customers/10.jpg";
import img11 from "../../../resources/images/customers/11.jpg";
import img12 from "../../../resources/images/customers/12.jpg";
import img13 from "../../../resources/images/customers/13.jpg";
import LazyLoad from 'react-lazyload';

export default class ReviewComponent extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            starClicked: false,
            carouselNumber: 0,
            ourComment: {
                improve: "Sorry for our mistake. Please contact us at hello@custommat.ca. We will come up with solution. We will issue you a refund or redo the work.",
                good: "Thank you very much for your feedback!",
                excellent: "Awesome! Happy to see our satisfied customers! :)",
            },
            featuredReviews: [
                {
                    img: img1,
                    reviewer: "Kelsey Simone",
                    review: "100% Satisfied. I could not be happier with my order from Custom Mat - the order process was very easy, shipping was extremely quick, and the mats came exactly as ordered. My framed pictures look better than I hoped for! ",
                    reviewFr: "100 % satisfait. Je ne pouvais pas être plus heureux avec ma commande de Custom Mat - le processus de commande a été très facile, l’expédition a été extrêmement rapide, et les passe-partout sont arrivés exactement comme commandés. Mes photos encadrées ont l’air mieux que ce que j’espérais!"
                },
                {
                    img: img2,
                    reviewer: "Stacey Harvie",
                    review: "Excellent customer service! Wasn't even a full week from the time I placed my order to the time I received it (that includes cross country shipping). Custom matting was cut perfectly and works great. Thanks so much!",
                    reviewFr: "Excellent service à la clientèle! Même pas une semaine complète ne s’est écoulée entre le moment où j’ai passé ma commande et le moment où je l’ai reçue (incluant l’expédition de ON à AB). Le passe-partout personnalisé a été parfaitement coupé et fonctionne parfaitement. Merci beaucoup!"
                },
                {
                    img: img3,
                    reviewer: "Daniel Weedmark",
                    review: "I have about 10 mats ordered from you guys and they are all perfect! Price is amazing, quality is on point and your turnaround time is unmatched with other companies I’ve tried. Thanks for all the hard work you do!",
                    reviewFr: "J’ai environ 10 passe-partout commandés de vous et ils sont tous parfaits! Le prix est incroyable, la qualité est parfaite et votre temps de réponse est sans égal comparativement à d’autres entreprises que j’ai essayées. Merci pour tout le travail que vous faites!"
                },
                {
                    img: img4,
                    reviewer: "Sarah Donovan",
                    review: "Excellent customer service. I goofed and they fixed it with no extra charge. Everything was delivered across the country in perfect condition and quickly. A great Canadian company.",
                    reviewFr: "Excellent service à la clientèle. J’ai fait une erreur et ils l’ont réparée sans frais supplémentaires. Tout a été livré dans tout le pays en parfait état et rapidement. Une excellente entreprise canadienne. "
                },
                {
                    img: img5,
                    reviewer: "Lucinda. Nigren",
                    review: "Great company! I felt the mats they sent were a great finish and quality. When I made a mistake in size they were able to help me out right away. The mat really improved the look of the art work too. If I need more mats I will be back.",
                    reviewFr: "Excellente entreprise! J’ai trouvé que les passe-partout qu’ils ont envoyés étaient d’une excellente finition et de qualité. Quand j’ai fait une erreur de taille, ils ont pu m’aider immédiatement. Le passe-partout a également amélioré l’aspect de l’œuvre d’art. Si j’ai besoin de plus de passe-partout, je reviendrai"
                },
                {
                    img: img6,
                    reviewer: "Alicia Silva",
                    review: "I've ordered ~10 mats now and each one has showed up very quickly, and they have all been exactly as ordered. Exceptional service, especially during the pandemic. Thank you!",
                    reviewFr: "J’ai commandé environ 10 passe-partout maintenant et chacun d’eux a été livré très rapidement, et ils ont tous été exactement comme commandés. Un service exceptionnel, en particulier pendant la pandémie. Merci!"
                },
                {
                    img: img7,
                    reviewer: "Dotti Lister",
                    review: "Very easy to order on their site! Shipped within two days, perfect fit & good quality.",
                    reviewFr: "Très facile de commander sur leur site! Livré en deux jours, ajustement parfait et bonne qualité."
                },

                {
                    img: img8,
                    reviewer: "Makai Thompson",
                    review: "My matboards were perfect! Just what I was expecting. Equally as great as the mats themselves was the customer service :)",
                    reviewFr: "Mes passe-partout étaient parfaits! Tout ce à quoi je m’attendais. Le service à la clientèle était tout aussi bien que les passe-partout eux-mêmes :)",
                },
                {
                    img: img9,
                    reviewer: "Jennifer Kim",
                    review: "Excellent customer service! I was afraid to order online. I called an my fears were put to rest. I ordered mats, one with bottom weighted matting. It came exactly I needed it. Thank you so much for the wonderful service.",
                    reviewFr: "Excellent service à la clientèle! J’avais peur de commander en ligne. J’ai appelé et mes craintes ont été mises de côté. J’ai commandé des passe-partout, dont un avec passe-partout lesté sur le bas. C’était exactement ce dont j’avais besoin. Merci beaucoup pour le merveilleux service."
                },

                {
                    img: img10,
                    reviewer: "Jo Anne MacMilan",
                    review: "Just want to say thank you Custom Mat for helping me out! The folks at Custom Mat helped me out and I received my mat yesterday. It was absolutely perfect! The price was affordable and the quality was exceptional.",
                    reviewFr: "Je veux simplement vous remercier Custom Mat pour m’avoir aidé! Les gens chez Custom Mat m’ont aidé et j’ai reçu mon passe-partout hier. C’était absolument parfait! Le prix était abordable et la qualité exceptionnelle."
                },
                {
                    img: img11,
                    reviewer: "Colleen Wishart",
                    review: "This company is fantastic. Not only do they offer beautiful custom mats which arrived quickly and were really good quality, but my mats didn’t fit and they replaced them for free and without any hassle. I will be ordering all of my mats from them from now on.",
                    reviewFr: "Cette entreprise est fantastique. Non seulement ils offrent de beaux passe-partout personnalisés qui sont arrivés rapidement et étaient vraiment de bonne qualité, mais mes passe-partout n’étaient pas bien ajustés et ils les ont remplacés gratuitement et sans tracas. Je vais maintenant commander tous mes passe-partout d’eux."
                },
                {
                    img: img12,
                    reviewer: "Al Thatcher",
                    review: "Quality mats and great service! I needed matting to frame two pieces of needlework. CustomMat cut the mats perfectly and delivered within a few days. The colour selection on one was not pleasing (my blunder). CustomMat replaced it right away ... no fuss at all. This is great service.",
                    reviewFr: "Des passe-partout de qualité et un excellent service! J’avais besoin de passe-partout pour encadrer deux pièces de broderies. CustomMat a parfaitement coupé les passe-partout et les a livrés en quelques jours. La sélection de couleurs sur l’une d’elles n’était pas agréable (mon erreur). CustomMat l’a remplacé tout de suite... pas de souci du tout. C’est un excellent service.",
                },
                {
                    img: img13,
                    reviewer: "Jamie Campbell",
                    review: "The customer service I have received from Custom Mat has been mindblowing! The communication between us while designing a custom matte for my signed jersey shadow box was second to none. They have truly gone out of their way to ensure 100% customer satisfaction.",
                    reviewFr: "Le service client que j’ai reçu de Custom Mat a été époustouflant! La communication entre nous pendant la conception de mon passe-partout personnalisé pour l’encadrement de mon gilet signé était sans égal. Ils ont vraiment fait tout le nécessaire pour garantir une satisfaction client de 100 %.",
                },

            ]
        };
    }

    reviewCarouselClicked(leftOrRight) {
        if (leftOrRight === "left") {
            if (this.state.carouselNumber === 0) {
                this.setState({carouselNumber: 12})
            } else {
                this.setState({carouselNumber: this.state.carouselNumber - 1})
            }
        } else {
            if (this.state.carouselNumber === 12) {
                this.setState({carouselNumber: 0})
            } else {
                this.setState({carouselNumber: this.state.carouselNumber + 1})
            }
        }
    }

    render() {
        return <div className={"landing-page-review-container-v2"} id={"landing-page-review-container-v2-moblet"}>

            <div className={"landing-page-left-arrow-v2"} onClick={()=> this.reviewCarouselClicked("left")}>
                <img className={"landing-page-arrow"} src={landingPageArrowLeft} alt={this.props.french ? "Cliquez pour voir plus d'avis." : "Click to view more reviews."} />
            </div>

            <div className={"landing-page-review-v2"}>
                <LazyLoad>
                    <img className={"customer-review-image"} src={this.state.featuredReviews[this.state.carouselNumber].img}/>
                </LazyLoad>
                <div className={"landing-page-reviewer-name-v2"}>
                    {this.state.featuredReviews[this.state.carouselNumber].reviewer}
                </div>
                <div className={"landing-page-review-content-v2"}>
                    {this.props.french ? `«${this.state.featuredReviews[this.state.carouselNumber].reviewFr}»` : `"${this.state.featuredReviews[this.state.carouselNumber].review}"` }
                </div>
                <div className={"landing-page-review-stars-v2"}>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars}
                        alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}
                    />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}/>
                </div>

                <a href={"/customer-reviews"} className={"landing-page-total-review-count-v2"}>
                    {this.props.french? "Parcourez plus de 300 évaluations" : "Browse over 4,000 5-Star Reviews"}
                </a>
            </div>

            <div className={"landing-page-right-arrow-v2"} onClick={()=> this.reviewCarouselClicked("right")}>
                <img className={"landing-page-arrow"} src={landingPageArrowRight} alt={this.props.french ? "Cliquez pour voir plus d'avis." : "Click to view more reviews."} />
            </div>


            <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>


        </div>
    }
}