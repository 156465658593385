import React from 'react';
import {marketList, schoolName} from "./components/articleDB/articleCityNames";
import {keywordList} from "./components/articleDB/articleKeywords";
import {preCutMatSizes} from "./components/articleDB/precutMatSizes";
import {preCutMatOpeningSizes} from "./components/articleDB/precutMatOpeningSizes";
import {precutBackingSizes} from "./components/articleDB/precutBackingSizes";
import {ikeaFrameSizes} from "./components/articleDB/ikeaFrameSizes"
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import '../stylesheets/App.css';
import NavBarTop from "./components/comp-navBar/navBarTop";
import LandingPage from "./components/page-landing/landingPage";
import NavBarBottom from "./components/comp-navBar/navBarBottom";
import PaymentApp from "./components/app-payments/paymentApp";
import ConfirmationApp from "./components/app-confirmation/confirmation";
import OrderChange from "./components/comp-orderChanges/orderChanges";
import UserPolicies from "./components/page-disclosure/policies";
import RefundPolicy from "./components/page-disclosure/refunds";
import WorkFlow from "./components/app-workflow/workflow";
import ErrPage from "./components/page-err/errPage";
import TheGallery from "./components/app-gallery/galleryApp";
import BlogArticleOne from "./components/app-gallery/articleView/articleOne";
import BlogArticleTwo from "./components/app-gallery/articleView/articleTwo";
import FaqPage from "./components/pages-faq/faqPage";
import PricingAndShippingPage from "./components/page-pricing/pricingAndShipping";
import BlogArticleThree from "./components/app-gallery/articleView/articleThree";
import BlogArticleFour from "./components/app-gallery/articleView/articleFour";
import WhatWeDoSection from "./components/page-landing/comp-landingPage/whatWeDo";
// import HowItWorksSection from "./components/page-landing/comp-landingPage/howItWorks";
import ShowVancouverPage from "./components/app-region/regionView/vancouver";
import ShowAtlantaPage from "./components/app-region/regionView/atlanta";
import ShowBostonPage from "./components/app-region/regionView/boston";
import ShowCalgaryPage from "./components/app-region/regionView/calgary";
import ShowChicagoPage from "./components/app-region/regionView/chicago";
import ShowDallasPage from "./components/app-region/regionView/dallas";
import ShowDcPage from "./components/app-region/regionView/dc";
import ShowEdmonton from "./components/app-region/regionView/edmonton";
import ShowHoustonPage from "./components/app-region/regionView/houston";
import ShowLaPage from "./components/app-region/regionView/la";
import ShowMiamiPage from "./components/app-region/regionView/miami";
import ShowMontrealPage from "./components/app-region/regionView/montreal";
import ShowNewYorkCityPage from "./components/app-region/regionView/nyc";
import ShowOttawaPage from "./components/app-region/regionView/ottawa";
import ShowPhillyPage from "./components/app-region/regionView/philly";
import ShowPortlandPage from "./components/app-region/regionView/portland";
import ShowSeattle from "./components/app-region/regionView/seattle";
import ShowSfPage from "./components/app-region/regionView/sf";
import ShowTorontoPage from "./components/app-region/regionView/toronto";
import BlogArticleFive from "./components/app-gallery/articleView/articleFive";
import StartDesigningPage from "./components/startDesigningPage/startDesigningPage";
import BlogArticleSix from "./components/app-gallery/articleView/articleSix";
import BlogArticleSeven from "./components/app-gallery/articleView/articleSeven";
import BlogArticleEight from "./components/app-gallery/articleView/articleEight";
import BlogArticleNine from "./components/app-gallery/articleView/articleNine";
import BlogArticleTen from "./components/app-gallery/articleView/articleTen";
import BlogArticleEleven from "./components/app-gallery/articleView/articleEleven";
import BlogArticleTwelve from "./components/app-gallery/articleView/articleTwelve";
import MeasuringYourMat from "./components/page-howItWorks/measruingYourMat";
import HowItWorksV2 from "./components/page-howItWorks/howItWorksV2";
import MatboardNoMinimumOrderQuantity from "./components/pages-faq/faqArticles/matboard-no-minimum-order-quantity";

import SelectMat from "./components/app-matsize-rendering/selectMat";
// import LandingPageFrench from "./components/page-landing/landingPageFrench";
import SelectProduct from "./components/app-select-product/selectProduct";
import SelectRegularFrame from "./components/app-select-frame/regularFrame";
import SelectPolaroidFrame from "./components/app-select-frame/selectPolaroidFrame";
import PolaroidTypeSelector from "./components/comp-polaroid-type-selector/polaroidTypeSelector";
import localStorageFunctions from "./components/comp-localStorage/localStroage";
import FetchSeoArticle from "./components/articleDB/seoArticle";
import ShippingInfoPage from "./components/page-disclosure/shippingInfoPage";
import priceTesting from "./components/comp-pricing/priceTesting";
import SeoKeywordArticle from "./components/articleDB/seoKeywordArticle";
import PricingAndDiscountPage from "./components/page-pricing/pricingAndDiscounts";
import SiteMapPage from "./components/articleDB/siteMap";
import AppReview from "./components/app-review/appReview";
import {cadUsdRate} from "../javascript/components/comp-pricing/forExRate";
// import HowItWorksPage from "./components/page-howItWorks/howItWorks";
import ReactPixel from 'react-facebook-pixel';
import QuantityDiscount from "./components/page-howItWorks/quantityDiscount";
import FetchPrices from "./components/page-howItWorks/fetchPrices";
import Inspiration from "./components/page-inspiration/inspiration";
import ShippingPage from "./components/pages-faq/shipping";
import Faq from "./components/pages-faq/faq";
// import ReviewComponent from "./components/app-review/reviewComponent";
import CustomerReviewSection from "./components/page-landing/comp-landingPage/customerReview";
// import ColourGuide from "./components/page-howItWorks/colourGuide";
import DesignGuide from "./components/page-howItWorks/designGuide";
import PreCutMats from "./components/page-preCutMats/preCutMats";

import ShopByFrameSize from "./components/page-preCutMats/shopByFrameSize";

import DesignBlogMain from "./components/blog2.0/blog2.0";
import BlogTemplate from "./components/blog2.0/blog-article-template";
import BlogHomeOffice from "./components/blog2.0/home-office";
import PressedFlowerFraming from "./components/blog2.0/pressed-flower-framing";
import OffsetOversizedRamsborgFrames from "./components/blog2.0/offset-oversized-ramsborg-frames";
import AchievingCheapCustomFrames from "./components/blog2.0/achieving-cheap-custom-frames";
import ShadowBoxMemorabilia from "./components/blog2.0/shadowBoxMemorabilia";
import MatboardsForIkeaFrames from "./components/blog2.0/matboards-for-ikea-frames";
import IkeaFrameMat from "./components/blog2.0/ikeaFrameMat";
import IkeaFrameSizingGuide from "./components/blog2.0/ikeaFrameSizingGuide";
import Matboard from "./components/blog2.0/matboard";
import FrameMat from "./components/blog2.0/frameMat";
import WholesaleMatboardSupplier from "./components/blog2.0/wholesaleSupplier";
import MatboardCutter from "./components/blog2.0/matboardCutter";
import WhereToOrderMatboards from "./components/blog2.0/whereToOrderMatboards";
import HowToFindARightFrameForYourArtwork from "./components/blog2.0/how-to-find-a-right-frame-for-your-artwork";
import DiplomaMatboardAndMore from "./components/blog2.0/diploma-matboard-and-more";
import DiplomaMats from "./components/page-preCutMats/diplomaMats/diplomaMats";
import SingleDiplomaMat from "./components/page-preCutMats/diplomaMats/singleDiplomaMat";
import StylishAffordableWaysToFrameYourDegree from "./components/blog2.0/stylish-affordable-ways-to-frame-your-degree";
import HowToFrameArtOnABudget from "./components/blog2.0/how-to-frame-art-on-a-budget";
import GalleryWallWithVintageFrames from "./components/blog2.0/galleryWallWithVintageFrames";
import BlackAndWhitePhotos from "./components/blog2.0/blackAndWhitePhotos";
import UWaterloo from "./components/blog2.0/universityCollection/uWaterloo";
import Highschool from "./components/blog2.0/universityCollection/highschool";
import UToronto from "./components/blog2.0/universityCollection/uToronto";
import UniversityGeneral from "./components/blog2.0/universityCollection/universityGeneral";
import FrameMatLanding from "./components/blog2.0/frameMatLanding";
import axios from "axios";
import HomeArtGalleryWall from "./components/blog2.0/HomeArtGalleryWall";
import UncutMatboards from "./components/blog2.0/uncutMatboards";
import BackingMats from "./components/page-preCutMats/backingMats";
import HowToMeasureAMatboard from "./components/pages-faq/faqArticles/how-to-measure-a-matboard";
import GuideToFindARightSizedFrame from "./components/pages-faq/faqArticles/guide-to-find-a-right-size-picture-frame";
import FloatMounting from "./components/pages-faq/faqArticles/floatMounting";
import FaqInDetail from "./components/pages-faq/faqInDetail";
import HowAreMatboardsMade from "./components/blog2.0/howAreMatboardsMade";
import HowToChooseRightMatboard from "./components/blog2.0/howToChooseRightMatboard";
import FrameCircularArtwork from "./components/blog2.0/frameCircularArtwork";
import EconomicCustomFraming from "./components/blog2.0/economicCustomFraming";
import TvWallFraming from "./components/blog2.0/tvWallFraming";
import BackingMat from "./components/blog2.0/backingMat";
import Matboards from "./components/blog2.0/matboards"
import ShopByLooks from "./components/page-landing/shopByLooks";
import {productDB} from "./components/comp-pricing/productDB";
import ShopByFrameSizeLineItem from "./components/page-preCutMats/shopByFrameSizeLineItem";
import DiyWeddingPhotoFrames from "./components/blog2.0/diyWeddingPhotoFrames";
import GalleryWall from "./components/blog2.0/galleryWall";
import StripeApp from "./components/app-payments/stripeApp";


export default class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      openShoppingCartModal: false,
      language: "en",
      location: "ca",
      currency: "CA$",

      onGoingPromotion: false,
      promotionRate: "",
      promoDecimals: 0.00,
      promoCode: "",

      // onGoingPromotion: true,
      // promotionRate: "20%",
      // promoDecimals: 0.2,
      // promoCode: "2024LD20",

    };

    this.showShoppingCartModal = this.showShoppingCartModal.bind(this);
    this.parentSetStateCallback = this.parentSetStateCallback.bind(this);
    this.updateItemList = this.updateItemList.bind(this);
    this.calculateItemSubtotal = this.calculateItemSubtotal.bind(this);
    this.fetchDiscountPlans = this.fetchDiscountPlans.bind(this);
    this.fetchPrices = this.fetchPrices.bind(this);
    this.getDiscountMultiplier = this.getDiscountMultiplier.bind(this);
    this.getTotalItemCount = this.getTotalItemCount.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.fxConversion = this.fxConversion.bind(this);
    this.changeCurrency = this.changeCurrency.bind(this);
    this.blogDirectLinkToMatLayout = this.blogDirectLinkToMatLayout.bind(this);
    this.fetchDiscountTier = this.fetchDiscountTier.bind(this);
  };

  componentDidMount() {
    this.loadShoppingCartPriceInfo();
    priceTesting();

    // const ReactPixel =  require('react-facebook-pixel');

    const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init('1175327839586894', advancedMatching, options);

    ReactPixel.pageView();
    ReactPixel.track('ViewContent', {content_name: "generalWebsite"});

    this.setState({currency: localStorage.getItem("currency") ? localStorage.getItem("currency") : "CA$"})

    this.getGeoInfo();

    this.setLanguage("en");
    this.setState({language: localStorage.getItem("language") ? localStorage.getItem("language") : "en"});
    if ( window.location.pathname.slice(-3) === "-fr") {
      this.setLanguage("fr");
    }
  };

  fxConversion(cadAmt) {
    var fxRate = this.state.currency === "CA$" ? 1 : cadUsdRate;
    var usdAmt = cadAmt * fxRate;
    return usdAmt
  }

  blogDirectLinkToMatLayout(desc, linkDesc, url, ) {
    return <div style={{textAlign: "left"}}>
      <span>{desc} -- </span>
      <a href={url}>{linkDesc}</a>
    </div>
  }

  changeCurrency(CadOrUsd) {
    localStorage.setItem("currency", CadOrUsd);
    this.setState({currency: CadOrUsd});
  }

  getGeoInfo() {
    axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;

          if (data.country === "US") {
            this.setState({location: "us", currency: "US$"});
          }


          // setState({
          //   ...state,
          //   ip: data.ip,
          //   countryName: data.country_name,
          //   countryCode: data.country_calling_code,
          //   city: data.city,
          //   timezone: data.timezone
          // });
        })
        .catch((error) => {
          console.log(error);
        });
  }

  setLanguage(lang) {
    localStorage.setItem("language", lang);
    this.setState({language: lang});
  }

  fetchDiscountPlans() {
    return <QuantityDiscount/>
  };

  fetchPrices() {
    return <FetchPrices/>
  };


  loadShoppingCartPriceInfo() {
      //when this function renders, you load shopping cart info from the local storage and updates the price information on the modal
    var localStorageItems = JSON.parse(localStorageFunctions("CJ92A38CCJXAE8C379C6F79758BQJXB88D14FEJKB", null));

    this.setState({shoppingCartItems: localStorageItems});
    return localStorageItems;
  };

  showShoppingCartModal(openOrClose) {
    // if (openOrClose === "open") {
    //   //every time this button is clicked, the local storage has to be reloaded and parsed.
    //   var shoppingCartItems = this.loadShoppingCartPriceInfo();
    //   this.setState({
    //     openShoppingCartModal: true,
    //     shoppingCartItems: shoppingCartItems,
    //   });
    // } else {
    //   this.setState({openShoppingCartModal: false});
    // };

    window.scrollTo(0, 0)

    this.setState({openShoppingCartModal: !this.state.openShoppingCartModal}, () => {
      var shoppingCartItems = this.loadShoppingCartPriceInfo();
      this.setState({
        shoppingCartItems: shoppingCartItems,
      });
    })
  };

  parentSetStateCallback(object, value) {
    this.setState({[object]: value})
  };

  updateItemList(itemKey, addOrReduceOrRemove) {
    var itemToUpdate = this.state.shoppingCartItems;

    if (addOrReduceOrRemove === "add") {
      itemToUpdate[itemKey].orderQuantity = parseFloat(itemToUpdate[itemKey].orderQuantity) + 1;
    } else if (addOrReduceOrRemove === "reduce") {
      if (itemToUpdate[itemKey].orderQuantity === 1) {
        alert("We are removing the item from your cart.");
        itemToUpdate.splice(itemKey, 1)
      } else {
        itemToUpdate[itemKey].orderQuantity = parseFloat(itemToUpdate[itemKey].orderQuantity) - 1;
      }
    } else if (addOrReduceOrRemove === "remove") {
      alert("We are removing the item from your cart.");
      itemToUpdate.splice(itemKey, 1);
    } else if (addOrReduceOrRemove === "0" && addOrReduceOrRemove !== "" ) {
      alert("We are removing the item from your cart.");
      itemToUpdate.splice(itemKey, 1);
    } else {
      //if customer just enters the number to the texbox
      //addOrReduceOrRemove is just a number
      if (Number.isInteger(parseInt(addOrReduceOrRemove)) === false || parseInt(addOrReduceOrRemove) <= 0) {
        alert("Please enter a valid quantity.")
      } else if (parseInt(addOrReduceOrRemove) > 0) {
        // this.setState({orderQuantity: parseInt(addOrReduceOrRemove)})
        itemToUpdate[itemKey].orderQuantity = parseInt(addOrReduceOrRemove);
      }
    }

    this.parentSetStateCallback("shoppingCartItems", itemToUpdate);
    localStorageFunctions("CJ92A38CCJXAE8C379C6F79758BQJXB88D14FEJKB", JSON.stringify(itemToUpdate))
  };

  getTotalItemCount() {
      var totalItemCount = 0;

      if (this.state.shoppingCartItems) {
          this.state.shoppingCartItems.map((item, key) => {
              if (item.prodType !== "sampleRequest") {
                  totalItemCount = totalItemCount + parseFloat(item.orderQuantity);
              }
          });
      }

    return totalItemCount;


  }



  getDiscountMultiplier(aboutToOrder) {
    //if a user buys 2 items, you get 20% off. 4 items 30% off, 10 items, then 40% off.

    var aboutToOrder = aboutToOrder ? parseFloat(aboutToOrder) : 0;

    var validItemCount = 0 + parseFloat(aboutToOrder) ;

    if (this.state.shoppingCartItems) {
      //reflecting second matting price to the total price calculation

      this.state.shoppingCartItems.map((item, key) => {

        if (item.prodType !== "sampleRequest") {
          validItemCount = validItemCount + parseFloat(item.orderQuantity);
        }

      });
    }

    if (validItemCount <= 1) {
      return 1
    } else if (validItemCount >= 2 && validItemCount < 4) {
      return 0.75
    } else if (validItemCount >= 4 && validItemCount < 10) {
      return 0.6
    } else if (validItemCount >= 10 && validItemCount < 20 ) {
      return 0.5
    } else if (validItemCount >= 20 && validItemCount < 50) {
      return 0.4
    } else if (validItemCount >= 50 && validItemCount < 100) {
      return 0.36
    } else if (validItemCount >= 100 && validItemCount < 250) {
      return 0.34
    } else if (validItemCount >= 250 && validItemCount < 500) {
      return 0.32
    } else if (validItemCount >= 500) {
      return 0.3
    }
  };

  calculateItemSubtotal() {
    var itemSubtotal = 0;
    var discountMultiplier = 1;


    if (this.state.shoppingCartItems) {
      //reflecting second matting price to the total price calculation

      this.state.shoppingCartItems.map((item, key) => {



        var primaryMatCost = item.price;
        var secondMatCost = (item.secondMatColour !== "not selected" && item.secondMatColour !== "none" && item.secondMatWidth !== 0) ? item.secondMatPrice : 0;
        var backingMatCost = (item.backingMatboard) ? item.secondMatPrice : 0;
        var addTextCost = (item.textAddition) ? item.textAdditionPrice : 0;
        var addLogoCost = (item.logoAdd) ? item.logoAdditionPrice : 0;

        itemSubtotal = itemSubtotal + (primaryMatCost + secondMatCost + backingMatCost + addTextCost + addLogoCost) * item.orderQuantity;

      });
    }

    discountMultiplier = this.getDiscountMultiplier();
    return itemSubtotal * discountMultiplier;

  };

  fetchDiscountTier(orderQuantity) {
    if (this.getDiscountMultiplier(orderQuantity) === 1) {
      return <div>
        {this.state.language === "fr" ? "Ajoutez 1 élément supplémentaire. Obtenez 20% de réduction." : "Add 1 more. Get 25% off."}
      </div>
    } else if (this.getDiscountMultiplier(orderQuantity) === 0.75) {
      return <div>
        {this.state.language === "fr" ? "Réduction de 25%." : "25% off. Get 4+ & 40% off"}
      </div>
    } else if (this.getDiscountMultiplier(orderQuantity) === 0.6) {
      return <div>
        {this.state.language === "fr" ? "40% de réduction" : "40% off. Get 10+ & 50% off"}
      </div>
    } else if (this.getDiscountMultiplier(orderQuantity) === 0.5) {
      return <div>
        {this.state.language === "fr" ? "50% de réduction" : "50% Discount Applied"}
      </div>
    } else {
      return <div>
        {this.state.language === "fr" ? `${parseInt((1-this.getDiscountMultiplier(orderQuantity))*100)}% de réduction` : `${parseInt((1-this.getDiscountMultiplier(orderQuantity))*100)}% Discount Applied`}
      </div>
    }
  }

  render() {


      const workflowPage = () => {
          return <WorkFlow
              currency={this.state.currency}
              fxConversion={this.fxConversion}
              changeCurrency={this.changeCurrency}
              fetchDiscountTier={this.fetchDiscountTier}
              getDiscountMultiplier={this.getDiscountMultiplier}
              location={this.state.location}

          />
      };

    const preCutMats = () => {
      return <PreCutMats
          showShoppingCartModal={this.showShoppingCartModal}
          language={this.state.language}
          selectedMatSize={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          fetchDiscountTier={this.fetchDiscountTier}
          getDiscountMultiplier={this.getDiscountMultiplier}
          location={this.state.location}
          onGoingPromotion={this.state.onGoingPromotion}
          promotionRate={this.state.promotionRate}
      />
    }

    const stripePayment = () => {
        return <StripeApp/>
    }

    const shopByFrameSize = () => {
        return <ShopByFrameSize
            showShoppingCartModal={this.showShoppingCartModal}
            language={this.state.language}
            selectedMatSize={false}
            fxConversion={this.fxConversion}
            currency={this.state.currency}
            changeCurrency={this.changeCurrency}
            fetchDiscountTier={this.fetchDiscountTier}
            location={this.state.location}
        />
    }

    const shopByFrameSizeFr = () => {
      return <ShopByFrameSize
          showShoppingCartModal={this.showShoppingCartModal}
          selectedMatSize={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          fetchDiscountTier={this.fetchDiscountTier}
          location={this.state.location}
          french={true}
      />
    }

    const backingMats = () => {
      return <BackingMats
          showShoppingCartModal={this.showShoppingCartModal}
          language={this.state.language}
          selectedMatSize={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          getDiscountMultiplier={this.getDiscountMultiplier}
          location={this.state.location}
          onGoingPromotion={this.state.onGoingPromotion}
          promotionRate={this.state.promotionRate}
      />
    }

    const backingMatsFr = () => {
      return <BackingMats
          showShoppingCartModal={this.showShoppingCartModal}
          selectedMatSize={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          getDiscountMultiplier={this.getDiscountMultiplier}
          french={true}
          location={this.state.location}
          onGoingPromotion={this.state.onGoingPromotion}
          promotionRate={this.state.promotionRate}
      />
    }


    const preCutMatsFr = () => {
      return <PreCutMats
          showShoppingCartModal={this.showShoppingCartModal}
          french={true}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          fetchDiscountTier={this.fetchDiscountTier}
          getDiscountMultiplier={this.getDiscountMultiplier}
          location={this.state.location}
          onGoingPromotion={this.state.onGoingPromotion}
          promotionRate={this.state.promotionRate}
      />
    }

    const howItWorksPageV2 = () => {
      return <HowItWorksV2
          language={this.state.language}
          location={this.state.location}
      />
    }

    const selectMatPageForFrame = () => {
      return <SelectMat
          designFor={"frame"}
          openShoppingCartModal={this.state.openShoppingCartModal}
          showShoppingCartModal={this.showShoppingCartModal}
          getDiscountMultiplier={this.getDiscountMultiplier}
          shoppingCartItems={this.state.shoppingCartItems}
          setLanguage={this.setLanguage}
          language={this.state.language}
          currency={this.state.currency}
          fxConversion={this.fxConversion}
          changeCurrency={this.changeCurrency}
          fetchDiscountTier={this.fetchDiscountTier}
          location={this.state.location}
          onGoingPromotion={this.state.onGoingPromotion}
          promotionRate={this.state.promotionRate}
      />
    };

    const selectMatPageForMatboardOnly= () => {
      return <SelectMat
          designFor={"matboardOnly"}
          openShoppingCartModal={this.state.openShoppingCartModal}
          showShoppingCartModal={this.showShoppingCartModal}
          getDiscountMultiplier={this.getDiscountMultiplier}
          language={this.state.language}
          currency={this.state.currency}
          fxConversion={this.fxConversion}
          changeCurrency={this.changeCurrency}
          fetchDiscountTier={this.fetchDiscountTier}
          location={this.state.location}
          onGoingPromotion={this.state.onGoingPromotion}
          promotionRate={this.state.promotionRate}
      />
    };

    // const showHowItWorksPage = () => {
    //   return <HowItWorksPage/>
    // }

    const selectMatPageForPolaroid = () => {
      return <SelectMat
          designFor={"polaroidFrame"}
          openShoppingCartModal={this.state.openShoppingCartModal}
          showShoppingCartModal={this.showShoppingCartModal}
          getDiscountMultiplier={this.getDiscountMultiplier}
          setLanguage={this.setLanguage}
          currency={this.state.currency}
          fxConversion={this.fxConversion}
          changeCurrency={this.changeCurrency}
          location={this.state.location}
          onGoingPromotion={this.state.onGoingPromotion}
          promotionRate={this.state.promotionRate}
      />
    };

    const confirmationPage = () => {
      return <ConfirmationApp
          calculateItemSubtotal={this.calculateItemSubtotal}
          getDiscountMultiplier={this.getDiscountMultiplier}
          getTotalItemCount={this.getTotalItemCount}
          shoppingCartItems={this.state.shoppingCartItems}
          updateItemList={this.updateItemList}
          parentSetStateCallback={this.parentSetStateCallback}
          language={this.state.language}
          currency={this.state.currency}
          fxConversion={this.fxConversion}
          location={this.state.location}
      />
    };

    const landingPage = () => {
      return <LandingPage
          fetchDiscountPlans={this.fetchDiscountPlans}
          fetchPrices={this.fetchPrices}
          language={this.state.language}
          location={this.state.location}
      />
    };

    const reviewPage = () => {
      return <AppReview/>
    };

    const customMatBoardLandingPage = () => {
      return <LandingPage
        product={"matboardOnly"}
        fetchPrices={this.fetchPrices}
        language={this.state.language}
        location={this.state.location}
      />
    };

    const frameWithMatLandingPage = () => {
        return <LandingPage
            product={"matboardOnly"}
            fetchPrices={this.fetchPrices}
            language={this.state.language}
            location={this.state.location}
        />
    };

    const polaroidFrameLandingPage = () => {
        return <LandingPage
            product={"matboardOnly"}
            fetchPrices={this.fetchPrices}
            language={this.state.language}
            location={this.state.location}
        />
    };

    const pricingAndDiscountPage = () => {
      return <PricingAndDiscountPage
          fetchDiscountPlans={this.fetchDiscountPlans}
          fetchPrices={this.fetchPrices}
          location={this.state.location}
      />
    };

    const siteMapPage = () => {
      return <SiteMapPage/>
    }


    const shippingInfoPage = () => {
      return <ShippingInfoPage/>
    };



    const paymentPage = () => {
      return <PaymentApp
          shoppingCartItems={this.state.shoppingCartItems}
          updateItemList={this.updateItemList}
          parentSetStateCallback={this.parentSetStateCallback}
          calculateItemSubtotal={this.calculateItemSubtotal}
          getDiscountMultiplier={this.getDiscountMultiplier}
          getTotalItemCount={this.getTotalItemCount}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          location={this.state.location}
          onGoingPromotion={this.state.onGoingPromotion}
          promotionRate={this.state.promotionRate}
          promoCode={this.state.promoCode}
          promoDecimals={this.state.promoDecimals}
      />
    };

    // const postReviewPage = () => {
    //   return <PaymentApp
    //       shoppingCartItems={this.state.shoppingCartItems}
    //       updateItemList={this.updateItemList}
    //       parentSetStateCallback={this.parentSetStateCallback}
    //       calculateItemSubtotal={this.calculateItemSubtotal}
    //       getDiscountMultiplier={this.getDiscountMultiplier}
    //   />
    // }

    const designGuidePage = () => {
      return <DesignGuide
          tab={"matboardSize"}
          location={this.state.location}
      />
    }

    const windowSizePage = () => {
      return <DesignGuide
        tab={"windowSize"}
        location={this.state.location}
      />
    }

    const matboardColourPage = () => {
      return <DesignGuide
          tab={"matboardColour"}
          location={this.state.location}
      />
    }

    const customDesignPage = () => {
      return <DesignGuide
        tab={"customDesign"}
        location={this.state.location}
      />
    }

    const designBlogMain = () => {
      return <DesignBlogMain
          location={this.state.location}
      />
    }

    const blogTemplate = () => {
      return <BlogTemplate/>
    }

    const shopByLooks = () => {
        return <ShopByLooks
            french={false}
            blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
        />
    }

    const shopByLooksFr = () => {
      return <ShopByLooks
          french={true}
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const blogHomeOffice = () => {
      return <BlogHomeOffice
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const pressedFlowerFraming = () => {
      return <PressedFlowerFraming
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}

      />
    }

    const matboardNoMinimumOrderQuantity = () => {
        return <MatboardNoMinimumOrderQuantity
            blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
        />
    }

    const howToMeasureAMatboard = () => {
      return <HowToMeasureAMatboard
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}

      />
    }

      const guideToRightSizePictureFrame = () => {
          return <GuideToFindARightSizedFrame
              blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
          />
      }

      const floatMountingMatboard = () => {
        return <FloatMounting
            blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}

        />
      }

    const howAreMatboardsMade = () => {
      return <HowAreMatboardsMade
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}

      />
    }

    const howToChooseRightMatboard = () => {
      return <HowToChooseRightMatboard
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}

      />
    }

    const howToFrameCircularArtwork = () => {
      return <FrameCircularArtwork
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}

      />
    }

    const tvWallFraming = () => {
      return <TvWallFraming
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

      const backingMat = () => {
          return <BackingMat
              blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
              onGoingPromotion={this.state.onGoingPromotion}
              promotionRate={this.state.promotionRate}
          />
      }

      const matboards = () => {
        return <Matboards
            blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
        />
      }

      const galleryWall = () => {
        return <GalleryWall
            french={false}
            blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
        />
      }



    const economicCustomFraming = () => {
      return <EconomicCustomFraming
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const offsetOversizedRamsborgFrames = () => {
      return <OffsetOversizedRamsborgFrames
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const achievingCheapCustomFrames = () => {
      return <AchievingCheapCustomFrames
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const shadowBoxMemorabilia = () => {
      return <ShadowBoxMemorabilia
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const matboardsForIkeaFrames = () => {
      return <MatboardsForIkeaFrames
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }


    const ikeaFrameSizingGuide = () => {
      return <IkeaFrameSizingGuide
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const matbaordPage = () => {
      return <Matboard
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const frameMat = () => {
      return <FrameMat
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const homeArtGallery = () => {
      return <HomeArtGalleryWall
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const frameMatLanding = () => {
      return <FrameMatLanding
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const wholesaleMatboardSupplier = () => {
      return <WholesaleMatboardSupplier
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }


    const uncutMatboards = () => {
      return <UncutMatboards
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const matboardCutter = () => {
      return <MatboardCutter
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const whereAndHowToOrderMatboards = () => {
      return <WhereToOrderMatboards
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const howToFindFrame = () => {
      return <HowToFindARightFrameForYourArtwork
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const diplomaMatboardAndMore = () => {
      return <DiplomaMatboardAndMore
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const stylishAndAffordableDegreeFrame = () => {
      return <StylishAffordableWaysToFrameYourDegree
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const howToFrameYourArtowrkOnABudget = () => {
      return <HowToFrameArtOnABudget
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const galleryWallWithVintageFrames = () => {
      return <GalleryWallWithVintageFrames
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const blackAndWhitePhoto = () => {
      return <BlackAndWhitePhotos
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const diyWeddingPhotoFrames = () => {
      return <DiyWeddingPhotoFrames
          blogDirectLinkToMatLayout={this.blogDirectLinkToMatLayout}
      />
    }

    const diplomaMats = () => {
        return <DiplomaMats
            fxConversion={this.fxConversion}
            currency={this.state.currency}
            location={this.state.location}
        />
    }
    const diplomaMatsFr = () => {
      return <DiplomaMats
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          location={this.state.location}
          french={true}
      />
    }

    const uWaterloo = () => {
      return <UWaterloo/>
    }

    const highschool = () => {
      return <Highschool/>
    }



    const uToronto = () => {
      return <UToronto
          schoolName="University of Toronto"
          schoolColor="navy blue"
          diplomaSize="12.75 x 9.75"
          openingSize="12.5 x 9.5"
          frameRec1="20x16"
          frameRec2="16x12"
          frameRec3="18x14"
      />
    }

    const yorkU = () => {
      return <UToronto
          schoolName="York University"
          schoolColor="Fire Red"
          diplomaSize="12.75 x 9.75"
          openingSize="12.5 x 9.5"
          frameRec1="20x16"
          frameRec2="16x12"
          frameRec3="18x14"
      />
    }

    const ubc = () => {
      return <UToronto
          schoolName="University of British Columbia"
          schoolColor="Navy Blue"
          diplomaSize="10.75x8.25"
          openingSize="10.5x8"
          frameRec1="16x12"
          frameRec2="20x16"
          frameRec3="18x14"
      />
    }

    const uOttawa = () => {
      return <UToronto
          schoolName="University of Ottawa"
          schoolColor="Dark Red and Grey (Flagstone)"
          diplomaSize="15.25x10.25"
          openingSize="15x10"
          frameRec1="20x16"
          frameRec2="18x14"
          frameRec3="24x18"
      />
    }

    const western = () => {
      return <UToronto
          schoolName="Western University"
          schoolColor="Purple"
          diplomaSize="10.75x13.75"
          openingSize="10.5x13.5"
          frameRec1="16x20"
          frameRec2="14x18"
          frameRec3="18x24"
      />
    }

    const uAlberta = () => {
      return <UToronto
          schoolName="University of Alberta"
          schoolColor="Green and Yellow (Straw)"
          diplomaSize="10.75x8.25"
          openingSize="10.5x8"
          frameRec1="16x12"
          frameRec2="20x16"
          frameRec3="18x14"
      />
    }

    const ryerson = () => {
      return <UToronto
          schoolName="Ryerson University"
          schoolColor="Royal Blue"
          diplomaSize="10.75x8.25"
          openingSize="10.5x8"
          frameRec1="16x12"
          frameRec2="20x16"
          frameRec3="18x14"
      />
    }

    const mcmaster = () => {
      return <UToronto
          schoolName="McMaster University"
          schoolColor="Wine"
          diplomaSize="10.75x14.75"
          openingSize="10.5x14.5"
          frameRec1="16x20"
          frameRec2="14x28"
          frameRec3="18x24"
      />
    }

    const calgary = () => {
      return <UToronto
          schoolName="University of Calgary"
          schoolColor="Red"
          diplomaSize="10.75x8.25"
          openingSize="10.5x8"
          frameRec1="16x12"
          frameRec2="20x16"
          frameRec3="18x14"
      />
    }

    const montreal = () => {
      return <UToronto
          schoolName="University of Montreal"
          schoolColor="Fire Red"
          diplomaSize="10.75x8.25"
          openingSize="10.5x8"
          frameRec1="16x12"
          frameRec2="20x16"
          frameRec3="18x14"
      />
    }

    const mcgill = () => {
      return <UToronto
          schoolName="McGill University"
          schoolColor="Fire Red"
          diplomaSize="16.75x10.75"
          openingSize="16.5x10.5"
          frameRec1="20x16"
          frameRec2="28x20"
          frameRec3="24x18"
      />
    }

    const guelph = () => {
      return <UToronto
          schoolName="University of Guelph"
          schoolColor="Dark Red"
          diplomaSize="8.25x10.75"
          openingSize="8x10.5"
          frameRec1="12x16"
          frameRec2="16x20"
          frameRec3="14x18"
      />
    }

    const queens = () => {
      return <UToronto
          schoolName="Queen's University"
          schoolColor="Dark Red and Navy Blue"
          diplomaSize="10.75x13.75"
          openingSize="10.5x13.5"
          frameRec1="16x20"
          frameRec2="14x18"
          frameRec3="18x22"
      />
    }

    const manitoba = () => {
      return <UToronto
          schoolName="University of Manitoba"
          schoolColor="Chocolate"
          diplomaSize="10.75x8.25"
          openingSize="10.5x8"
          frameRec1="20x16"
          frameRec2="16x12"
          frameRec3="18x14"
      />
    }

    const singleDiplomaMat = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={false}
          text={false}
          logo={false}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}

      />
    }

    const singleDiplomaMatWithText = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={false}
          text={true}
          logo={false}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          location={this.state.location}
      />
    }

    const singleDiplomaMatWithLogo = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={false}
          text={false}
          logo={true}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          location={this.state.location}
      />
    }

    const singleDiplomaMatWithLogoText = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={false}
          text={true}
          logo={true}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          location={this.state.location}
      />
    }

    const doubleDiplomaMat = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={true}
          text={false}
          logo={false}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          location={this.state.location}
      />
    }

    const doubleDiplomaMatWithText = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={true}
          text={true}
          logo={false}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          location={this.state.location}
      />
    }

    const doubleDiplomaMatWithLogo = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={true}
          text={false}
          logo={true}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          location={this.state.location}
      />
    }

    const doubleDiplomaMatWithLogoText = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={true}
          text={true}
          logo={true}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          location={this.state.location}
      />
    }

    const singleDiplomaMatFr = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={false}
          text={false}
          logo={false}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          french={true}
      />
    }

    const singleDiplomaMatWithTextFr = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={false}
          text={true}
          logo={false}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          location={this.state.location}
          french={true}
      />
    }

    const singleDiplomaMatWithLogoFr = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={false}
          text={false}
          logo={true}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          location={this.state.location}
          french={true}
      />
    }

    const singleDiplomaMatWithLogoTextFr = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={false}
          text={true}
          logo={true}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          location={this.state.location}
          french={true}
      />
    }

    const doubleDiplomaMatFr = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={true}
          text={false}
          logo={false}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          location={this.state.location}
          french={true}
      />
    }

    const doubleDiplomaMatWithTextFr = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={true}
          text={true}
          logo={false}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          location={this.state.location}
          french={true}
      />
    }

    const doubleDiplomaMatWithLogoFr = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={true}
          text={false}
          logo={true}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          location={this.state.location}
          french={true}
      />
    }

    const doubleDiplomaMatWithLogoTextFr = () => {
      return <SingleDiplomaMat
          getDiscountMultiplier={this.getDiscountMultiplier}
          showShoppingCartModal={this.showShoppingCartModal}
          doubleMatting={true}
          text={true}
          logo={true}
          multipleWindow={false}
          fxConversion={this.fxConversion}
          currency={this.state.currency}
          changeCurrency={this.changeCurrency}
          location={this.state.location}
          french={true}
      />
    }

      const faqPage = () => {
          return <FaqPage
              location={this.state.location}
          />
      };

      const shippingPageFr = () => {
          return <ShippingPage
              french={true}
          />
      }

      const faqInDetail = () => {
          return <FaqInDetail
              location={this.state.location}
          />
      }

      const faqPageV2 = () => {
          return <Faq
              location={this.state.location}
          />
      }

      const errPageFr = () => {
          return <ErrPage
              french={true}
          />
      };

      const refundPageFr = () => {
          return <RefundPolicy
              french={true}
          />
      };


    return <BrowserRouter>
      <div className="application-container">
        <NavBarTop
            openShoppingCartModal={this.state.openShoppingCartModal}
            shoppingCartItems={this.state.shoppingCartItems}
            showShoppingCartModal={this.showShoppingCartModal}
            parentSetStateCallback={this.parentSetStateCallback}
            updateItemList={this.updateItemList}
            calculateItemSubtotal={this.calculateItemSubtotal}
            getDiscountMultiplier={this.getDiscountMultiplier}
            getTotalItemCount={this.getTotalItemCount}
            setLanguage={this.setLanguage}
            language={this.state.language}
            location={this.state.location}
            currency={this.state.currency}
            fxConversion={this.fxConversion}
            changeCurrency={this.changeCurrency}
            onGoingPromotion={this.state.onGoingPromotion}
            promotionRate={this.state.promotionRate}
        />

        <Switch>

          {/*##Comment -- User Flow*/}
          {/*## 1-1. Landing Page - EN*/}
          <Route exact path="/" component={landingPage}/>
          {/*## 1-1. Landing Page - FR*/}
          <Route exact path="/-fr" component={landingPage}/>

          {/*##Comment -- User Flow*/}
          {/*## 2-1. Landing Page - Select Product*/}
          <Route exact path="/select-product" component={SelectProduct}/>

          {/*## 3-1. Product 1 -- Custom Matboard*/}
          <Route exact path="/custom-mat-boards-online" component={customMatBoardLandingPage}/>
          <Route exact path="/custom-mat-boards-online-fr" component={customMatBoardLandingPage}/>
          <Route exact path="/customize-matboards" component={selectMatPageForMatboardOnly}/>
          <Route exact path="/customize-matboards-fr" component={selectMatPageForMatboardOnly}/>

          {/*## 3-2. Product 2 -- Picture Frame + Custom Matboard*/}
          <Route exact path="/frames-with-custom-matboards" component={frameWithMatLandingPage}/>
          <Route exact path="/select-frames" component={selectRegularFramePage}/>
          <Route exact path="/customize-your-matboards" component={selectMatPageForFrame}/>

          {/*## 3-3. Product 3 -- Polaroid Frame*/}
          <Route exact path="/custom-polaroid-instax-frames" component={polaroidFrameLandingPage}/>
          <Route exact path="/select-polaroid-frames" component={selectPolaroidFrame}/>
          <Route exact path="/select-polaroid-type" component={selectPolaroidType}/>
          <Route exact path="/customize-polaroid-frame-layout" component={selectMatPageForPolaroid}/>

          {/*## 4-1. User Address and Payment Information*/}
          <Route exact path="/payments" component={paymentPage}/>
          <Route exact path="/payments-fr" component={paymentPage}/>
          <Route exact path="/payment-page" component={stripePayment}/>

          {/*## 5-1. User Checkout confirmation*/}
          <Route exact path="/confirmation" component={confirmationPage}/>
          <Route exact path="/confirmation-fr" component={confirmationPage}/>

          {/*## OTHER Pages*/}
          {/*<Route exact path="/tracking" component={trackingPage}/>*/}
          <Route exact path={"/policies"} component={policyPage}/>
          <Route exact path={"/policies-fr"} component={policyPage}/>
          <Route exact path={"/returns"} component={refundPage}/>
          <Route exact path={"/returns-fr"} component={refundPageFr}/>
          <Route exact path={"/contact"} component={orderChangePage}/>
          <Route exact path={"/contact-fr"} component={orderChangePage}/>
          <Route exact path={"/error"} component={errPage}/>
          <Route exact path={"/error-fr"} component={errPageFr}/>

          <Route exact path={"/pricing"} component={pricingPage}/>
          <Route exact path={"/pricing-fr"} component={pricingPage}/>
          <Route exact path={"/shipping-info"} component={shippingInfoPage}/>
          <Route exact path={"/shipping-info-fr"} component={shippingInfoPage}/>
          <Route exact path={"/review"} component={reviewPage}/>
          <Route exact path={"/review-fr"} component={reviewPage}/>
          {/*<Route exact path={"/how-it-works"} component={showHowItWorksPage}/>*/}
          <Route exact path="/how-it-works" component={howItWorksPageV2}/>
          <Route exact path="/how-it-works-fr" component={howItWorksPageV2}/>

            <Route exact path={"/faqs"} component={faqPage}/>
            <Route exact path={"/faqs-fr"} component={faqPage}/>

          {/*## Blog Pages*/}
          <Route exact path={"/gallery"} component={galleryPage}/>
          <Route exact path={"/gallery-fr"} component={galleryPage}/>
          <Route exact path={"/custom-picture-mats-make-difference"} component={blogArticleOne}/>
          <Route exact path={"/how-to-measure-a-mat-board"} component={blogArticleTwo}/>
          <Route exact path={"/custom-mat-board-for-ikea-ribba-and-silverhojden-36in-26in-91cm-61cm"} component={blogArticleThree}/>
          <Route exact path="/how-to-measure-mat-board-without-a-ruler" component={blogArticleFour}/>
          <Route exact path="/what-we-do" component={whatWeDoPage}/>
          <Route exact path="/inspiration" component={inspirationPage}/>
          <Route exact path="/inspiration-fr" component={inspirationPage}/>

          <Route exact path={"/measuring-your-matboards"} component={measuringYourMat}/>
          {/*<Route exact path="/how-it-works" component={howItWorksPage}/>*/}
          <Route exact path={"/ordering-custom-passepartout-matboard-in-canada-usa"} component={blogArticleFive}/>
          <Route exact path="/startDesigning" component={startDesigningPage}/>
          <Route exact path="/acid-free-matboard-definition" component={blogArticleSix}/>
          <Route exact path="/thrift-shop-frames-fitting-border-for-art-frames" component={blogArticleSeven}/>
          <Route exact path="/saving-tips-for-custom-framing" component={blogArticleEight}/>
          <Route exact path="/diy-gallery-wall" component={blogArticleNine}/>
          <Route exact path="/diy-gallery-wall-fr" component={blogArticleNine}/>
          <Route exact path="/oversized-matboard-gallery-wall-grid" component={blogArticleTen}/>
          <Route exact path="/diy-oversized-mat-amp-square-family-photo-frames" component={blogArticleEleven}/>
          <Route exact path="/gallery-wall-template-oversized-mat-frames-with-ikea-ramsborg" component={blogArticleTwelve}/>

          <Route exact path={"/workflow"} component={workflowPage}/>

          {/*## Service Locations*/}
          <Route exact path="/custom-mat-board-atlanta" component={showAtlantaPage}/>
          <Route exact path="/custom-mat-board-boston" component={showBostonPage}/>
          <Route exact path="/custom-mat-board-calgary" component={showCalgaryPage}/>
          <Route exact path="/custom-mat-board-chicago" component={showChicagoPage}/>
          <Route exact path="/custom-mat-board-dallas" component={showDallasPage}/>
          <Route exact path="/custom-mat-board-washington-dc" component={showDcPage}/>
          <Route exact path="/custom-mat-board-edmonton" component={showEdmontonPage}/>
          <Route exact path="/custom-mat-board-houston" component={showHoustonPage}/>
          <Route exact path="/custom-mat-board-la" component={showLaPage}/>
          <Route exact path="/custom-mat-board-miami" component={showMiamiPage}/>
          <Route exact path="/custom-mat-board-montreal" component={showMontrealPage}/>
          <Route exact path="/custom-mat-board-new-york-city" component={showNycPage}/>
          <Route exact path="/custom-mat-board-ottawa" component={showOttawaPage}/>
          <Route exact path="/custom-mat-board-philadelphia" component={showPhillyPage}/>
          <Route exact path="/custom-mat-board-portland" component={showPortlandPage}/>
          <Route exact path="/custom-mat-board-seattle" component={showSeattlePage}/>
          <Route exact path="/custom-mat-board-san-francisco" component={showSfPage}/>
          <Route exact path="/custom-mat-board-toronto" component={showTorontoPage}/>
          <Route exact path="/custom-mat-board-vancouver" component={showVancouverPage}/>
          <Route exact path="/pricing-and-discounts" component={pricingAndDiscountPage}/>
          <Route exact path="/pricing-and-discounts-fr" component={pricingAndDiscountPage}/>
          <Route exact path="/sitemap" component={siteMapPage}/>
          <Route exact path="/sitemap-fr" component={siteMapPage}/>
          <Route exact path="/shipping" component={shippingPage}/>
          <Route exact path="/shipping-fr" component={shippingPageFr}/>
          <Route exact path="/faq" component={faqPageV2}/>
          <Route exact path="/faq-fr" component={faqPageV2}/>
          <Route exact path="/faq-in-detail" component={faqInDetail}/>
          <Route exact path="/faq-in-detail-fr" component={faqInDetail}/>
          <Route exact path="/customer-reviews" component={customerReviewPage}/>
          <Route exact path="/customer-reviews-fr" component={customerReviewPage}/>
          <Route exact path="/design-guide" component={designGuidePage}/>
          <Route exact path="/design-guide-fr" component={designGuidePage}/>
          <Route exact path="/window-size" component={windowSizePage}/>
          <Route exact path="/window-size-fr" component={windowSizePage}/>
          <Route exact path="/colour-guide" component={matboardColourPage}/>
          <Route exact path="/colour-guide-fr" component={matboardColourPage}/>
          <Route exact path="/custom-design-guide" component={customDesignPage}/>
          <Route exact path="/custom-design-guide-fr" component={customDesignPage}/>
          <Route exact path="/precut-mats" component={preCutMats}/>
          <Route exact path="/precut-mats-fr" component={preCutMatsFr}/>

          <Route exact path="/shop-by-frame-size" component={shopByFrameSize}/>
          <Route exact path="/shop-by-frame-size-fr" component={shopByFrameSizeFr}/>


          <Route exact path="/precut-mat-boards" component={preCutMats}/>
          <Route exact path="/precut-mat-boards-fr" component={preCutMatsFr}/>
          <Route exact path="/backing-mat-boards" component={backingMats}/>
          <Route exact path="/backing-mat-boards-fr" component={backingMatsFr}/>
          <Route exact path="/diploma-frame-matboards" component={diplomaMats}/>
          <Route exact path="/diploma-frame-matboards-fr" component={diplomaMatsFr}/>





          <Route exact path="/single-diploma-certificate-frame-matboard" component={singleDiplomaMat}/>
          <Route exact path="/single-diploma-certificate-frame-matboard-with-custom-text-print" component={singleDiplomaMatWithText}/>
          <Route exact path="/single-diploma-certificate-frame-matboard-with-custom-logo-print" component={singleDiplomaMatWithLogo}/>
          <Route exact path="/single-diploma-certificate-frame-matboard-with-custom-text-and-logo-print" component={singleDiplomaMatWithLogoText}/>
          <Route exact path="/double-diploma-certificate-frame-matboard" component={doubleDiplomaMat}/>
          <Route exact path="/double-diploma-certificate-frame-matboard-with-custom-text-print" component={doubleDiplomaMatWithText}/>
          <Route exact path="/double-diploma-certificate-frame-matboard-with-custom-logo-print" component={doubleDiplomaMatWithLogo}/>
          <Route exact path="/double-diploma-certificate-frame-matboard-with-custom-text-and-logo-print" component={doubleDiplomaMatWithLogoText}/>


          <Route exact path="/single-diploma-certificate-frame-matboard-fr" component={singleDiplomaMatFr}/>
          <Route exact path="/single-diploma-certificate-frame-matboard-with-custom-text-print-fr" component={singleDiplomaMatWithTextFr}/>
          <Route exact path="/single-diploma-certificate-frame-matboard-with-custom-logo-print-fr" component={singleDiplomaMatWithLogoFr}/>
          <Route exact path="/single-diploma-certificate-frame-matboard-with-custom-text-and-logo-print-fr" component={singleDiplomaMatWithLogoTextFr}/>
          <Route exact path="/double-diploma-certificate-frame-matboard-fr" component={doubleDiplomaMatFr}/>
          <Route exact path="/double-diploma-certificate-frame-matboard-with-custom-text-print-fr" component={doubleDiplomaMatWithTextFr}/>
          <Route exact path="/double-diploma-certificate-frame-matboard-with-custom-logo-print-fr" component={doubleDiplomaMatWithLogoFr}/>
          <Route exact path="/double-diploma-certificate-frame-matboard-with-custom-text-and-logo-print-fr" component={doubleDiplomaMatWithLogoTextFr}/>





          <Route exact path={"/university-of-waterloo-custom-diploma-frame"} component={uWaterloo}/>
          <Route exact path={"/highschool-custom-diploma-frame"} component={highschool}/>
          <Route exact path={"/university-of-toronto-custom-diploma-frame"} component={uToronto}/>
          <Route exact path={"/york-university-custom-diploma-frame"} component={yorkU}/>
          <Route exact path={"/university-of-british-columbia-custom-diploma-frame"} component={ubc}/>
          <Route exact path={"/university-of-ottawa-custom-diploma-frame"} component={uOttawa}/>
          <Route exact path={"/western-university-custom-diploma-frame"} component={western}/>
          <Route exact path={"/university-of-alberta-custom-diploma-frame"} component={uAlberta}/>
          <Route exact path={"/ryerson-university-custom-diploma-frame"} component={ryerson}/>
          <Route exact path={"/mcmaster-university-custom-diploma-frame"} component={mcmaster}/>
          <Route exact path={"/calgary-university-custom-diploma-frame"} component={calgary}/>
          <Route exact path={"/montreal-university-custom-diploma-frame"} component={montreal}/>
          <Route exact path={"/mcgill-university-custom-diploma-frame"} component={mcgill}/>
          <Route exact path={"/university-of-guelph-custom-diploma-frame"} component={guelph}/>
          <Route exact path={"/queens-university-custom-diploma-frame"} component={queens}/>
          <Route exact path={"/university-of-manitoba-custom-diploma-frame"} component={manitoba}/>


          {
            schoolName.map((school) => {
              var schoolUrl = school.replace(/ /g,"-")
              return <Route
                  path={`/${schoolUrl}-custom-diploma-frame`}
                  render={props => <UniversityGeneral {...props} schoolName={school}/>}
              />
            })
          }

          {/*## FAQ2.0 Articles*/}
          <Route exact path={"/matboard-no-minimum-order-quantity"} component={matboardNoMinimumOrderQuantity}/>
          <Route exact path={"/guide-to-find-a-right-picture-standard-sized-picture-frame"} component={guideToRightSizePictureFrame}/>

          <Route exact path={"/how-to-measure-a-matboard"} component={howToMeasureAMatboard}/>
          <Route exact path={"/float-mounting-matboard"} component={floatMountingMatboard}/>


            {/*## Blog2.0 Articles*/}
          <Route exact path="/design-blog-main" component={designBlogMain}/>
          <Route exact path="/save-huge-on-photo-frames-with-passe-partout-photo-mats" component={blogTemplate}/>
          <Route exact path="/creating-home-office" component={blogHomeOffice}/>
          <Route exact path="/pressed-flower-diy-framing" component={pressedFlowerFraming}/>
          <Route exact path="/oversized-mat-frames-with-ikea-ramsborg" component={offsetOversizedRamsborgFrames}/>
          <Route exact path="/achieving-cheap-custom-frames" component={achievingCheapCustomFrames}/>
          <Route exact path="/jersey-sports-shadow-box-memorabilia-matting" component={shadowBoxMemorabilia}/>
          <Route exact path="/matboards-for-ikea-frames" component={matboardsForIkeaFrames}/>
          <Route exact path="/ikea-frame-matboard-sizing-guide" component={ikeaFrameSizingGuide}/>
          <Route exact path="/matboard" component={matbaordPage}/>
          <Route exact path="/How-To-Get-My-Artwork-Framed-Perfectly-In-Budget" component={frameMat}/>
          <Route exact path="/home-art-gallery-wall-design-inspirations" component={homeArtGallery}/>
          <Route exact path="/frame-mat" component={frameMatLanding}/>
          <Route exact path="/wholesale-matboard-supplier" component={wholesaleMatboardSupplier}/>
          <Route exact path="/where-to-get-uncut-matboards" component={uncutMatboards}/>
          <Route exact path="/matboard-cutter" component={matboardCutter}/>
          <Route exact path="/how-and-where-to-order-matboards" component={whereAndHowToOrderMatboards}/>
          <Route exact path="/how-to-find-a-right-frame-for-your-artwork" component={howToFindFrame}/>
          <Route exact path="/diploma-frame-matboard-and-more" component={diplomaMatboardAndMore}/>
          <Route exact path="/stylish-and-affordable-ways-to-frame-your-diploma" component={stylishAndAffordableDegreeFrame}/>
          <Route exact path="/how-to-frame-your-artwork-on-a-budget" component={howToFrameYourArtowrkOnABudget}/>
          <Route exact path="/gallery-wall-with-repurposed-gallery-wall-frames" component={galleryWallWithVintageFrames}/>
          <Route exact path={"/black-and-white-photo-gallery-wall-framing"} component={blackAndWhitePhoto}/>
          <Route exact path={"/how-are-matboards-made"} component={howAreMatboardsMade}/>
          <Route exact path={"/how-to-choose-right-matboard"} component={howToChooseRightMatboard}/>
          <Route exact path={"/how-to-frame-circular-oval-artwork"} component={howToFrameCircularArtwork}/>
          <Route exact path={"/economic-way-to-custom-picture-framing"} component={economicCustomFraming}/>
          <Route exact path={"/five-ideas-wall-behind-your-tv"} component={tvWallFraming}/>
          <Route exact path={"/diy-wedding-photo-frames"} component={diyWeddingPhotoFrames}/>
          <Route exact path={"/backing-mat"} component={backingMat}/>
          <Route exact path={"/matboards"} component={matboards}/>
          <Route exact path={"/gallery-wall"} component={galleryWall}/>
          <Route exact path={"/shop-by-looks"} component={shopByLooks}/>
          <Route exact path={"/shop-by-looks-fr"} component={shopByLooksFr}/>




            {
            Object.keys(marketList).map((city) => {
              return marketList[city].neighbourList.map((neighbourhood) => {
                var neighbourhoodStringProcessed = neighbourhood.replace(' ', "");
                var cityStringProcessed = city.replace(' ', "");
                return <Route
                  path={`/custom-framing-matboard-${neighbourhoodStringProcessed}-${cityStringProcessed}`}
                  render={props => <FetchSeoArticle {...props} neighbourhood={neighbourhood} city={marketList[city].cityName} />}
                />
              })
            })
          }

          {
            Object.keys(keywordList).map((keyword) => {
              return <Route
                path={`/get-${keywordList[keyword].replace(/\s+/g, '-')}`}
                render={props => <SeoKeywordArticle {...props} keyword={keywordList[keyword].toUpperCase()}/>}
              />
            })
          }

          {
            Object.keys(preCutMatOpeningSizes).map((matsize) => {
              return Object.keys(preCutMatOpeningSizes[matsize].openSize).map((openingSize) => {
                return <Route
                    path={`/${matsize}-inch-matboard-${openingSize}-inch-opening`}
                    render={props => <PreCutMats
                        {...props}
                        selectedMatSize={matsize}
                        selectedOpeningSize={openingSize}
                        showShoppingCartModal={this.showShoppingCartModal}
                        language={this.state.language}
                        fxConversion={this.fxConversion}
                        currency={this.state.currency}
                        changeCurrency={this.changeCurrency}
                        fetchDiscountTier={this.fetchDiscountTier}
                        getDiscountMultiplier={this.getDiscountMultiplier}
                        location={this.state.location}
                        onGoingPromotion={this.state.onGoingPromotion}
                        promotionRate={this.state.promotionRate}
                    />}
                />
              })
            })
          }

          {
            Object.keys(preCutMatOpeningSizes).map((matsize) => {
              return Object.keys(preCutMatOpeningSizes[matsize].openSize).map((openingSize) => {
                return <Route
                    path={`/${matsize}-inch-matboard-${openingSize}-inch-opening-fr`}
                    render={props => <PreCutMats
                        {...props}
                        selectedMatSize={matsize}
                        selectedOpeningSize={openingSize}
                        showShoppingCartModal={this.showShoppingCartModal}
                        french={true}
                        fxConversion={this.fxConversion}
                        currency={this.state.currency}
                        changeCurrency={this.changeCurrency}
                        fetchDiscountTier={this.fetchDiscountTier}
                        getDiscountMultiplier={this.getDiscountMultiplier}
                        location={this.state.location}
                        onGoingPromotion={this.state.onGoingPromotion}
                        promotionRate={this.state.promotionRate}
                    />}
                />
              })
            })
          }

          {
            Object.keys(productDB.matboardByFrameSize).map((sortSize) => {

              var matWidth = productDB.matboardByFrameSize[sortSize].width;

              var matHeight = productDB.matboardByFrameSize[sortSize].height;

              return <Route
                  path={`/${matWidth}x${matHeight}-inch-matboard-for-${matWidth}x${matHeight}-inch-frames`}
                  render={props => <ShopByFrameSizeLineItem {...props}
                                                            matWidth={matWidth}
                                                            matHeight={matHeight}
                                                            location={this.state.location}
                  />}
              />
            })
          }

          {
            Object.keys(productDB.matboardByFrameSize).map((sortSize) => {

              var matWidth = productDB.matboardByFrameSize[sortSize].width;

              var matHeight = productDB.matboardByFrameSize[sortSize].height;

              return <Route
                  path={`/${matWidth}x${matHeight}-inch-matboard-for-${matWidth}x${matHeight}-inch-frames-fr`}
                  render={props => <ShopByFrameSizeLineItem {...props}
                                                            matWidth={matWidth}
                                                            matHeight={matHeight}
                                                            location={this.state.location}
                                                            french={true}
                  />}
              />
            })
          }

          {/*{*/}
          {/*  Object.keys(precutBackingSizes).map((matsize) => {*/}
          {/*    return <Route*/}
          {/*        path={`/${matsize}-inch-backing-mounting-matboards`}*/}
          {/*        render={props => <BackingMats*/}
          {/*            {...props}*/}
          {/*            selectedMatSize={matsize}*/}
          {/*            showShoppingCartModal={this.showShoppingCartModal}*/}
          {/*            language={this.state.language}*/}
          {/*            fxConversion={this.fxConversion}*/}
          {/*            currency={this.state.currency}*/}
          {/*            changeCurrency={this.changeCurrency}*/}
          {/*        />}*/}
          {/*    />*/}
          {/*  })*/}
          {/*}*/}

          {
            Object.keys(precutBackingSizes).map((size) => {
              return <Route
                  path={`/${precutBackingSizes[size]}-inch-backing-mounting-matboards`}
                  render={props => <BackingMats {...props}
                                               selectedMatSize={precutBackingSizes[size]}
                                               showShoppingCartModal={this.showShoppingCartModal}
                                               french={false}
                                               fxConversion={this.fxConversion}
                                               currency={this.state.currency}
                                               changeCurrency={this.changeCurrency}
                                                fetchDiscountTier={this.fetchDiscountTier}
                                                getDiscountMultiplier={this.getDiscountMultiplier}
                                                location={this.state.location}
                                                onGoingPromotion={this.state.onGoingPromotion}
                                                promotionRate={this.state.promotionRate}
                  />}
              />
            })
          }

          {
            Object.keys(precutBackingSizes).map((size) => {
              return <Route
                  path={`/${precutBackingSizes[size]}-inch-backing-mounting-matboards-fr`}
                  render={props => <BackingMats {...props}
                                                selectedMatSize={precutBackingSizes[size]}
                                                showShoppingCartModal={this.showShoppingCartModal}
                                                french={true}
                                                fxConversion={this.fxConversion}
                                                currency={this.state.currency}
                                                changeCurrency={this.changeCurrency}
                                                fetchDiscountTier={this.fetchDiscountTier}
                                                getDiscountMultiplier={this.getDiscountMultiplier}
                                                location={this.state.location}
                                                onGoingPromotion={this.state.onGoingPromotion}
                                                promotionRate={this.state.promotionRate}
                  />}
              />
            })
          }

          {
            Object.keys(preCutMatSizes).map((size) => {
              return <Route
                  path={`/${preCutMatSizes[size]}-inch-matboard`}
                  render={props => <PreCutMats {...props}
                                               selectedMatSize={preCutMatSizes[size]}
                                               showShoppingCartModal={this.showShoppingCartModal}
                                               french={false}
                                               fxConversion={this.fxConversion}
                                               currency={this.state.currency}
                                               changeCurrency={this.changeCurrency}
                                               fetchDiscountTier={this.fetchDiscountTier}
                                               getDiscountMultiplier={this.getDiscountMultiplier}
                                               location={this.state.location}
                                               onGoingPromotion={this.state.onGoingPromotion}
                                               promotionRate={this.state.promotionRate}
                  />}
              />
            })
          }

          {
            Object.keys(preCutMatSizes).map((size) => {
              return <Route
                  path={`/${preCutMatSizes[size]}-inch-matboard-fr`}
                  render={props => <PreCutMats {...props}
                                               selectedMatSize={preCutMatSizes[size]}
                                               showShoppingCartModal={this.showShoppingCartModal}
                                               french={true}
                                               fxConversion={this.fxConversion}
                                               currency={this.state.currency}
                                               changeCurrency={this.changeCurrency}
                                               fetchDiscountTier={this.fetchDiscountTier}
                                               getDiscountMultiplier={this.getDiscountMultiplier}
                                               location={this.state.location}
                                               onGoingPromotion={this.state.onGoingPromotion}
                                               promotionRate={this.state.promotionRate}
                  />}
              />
            })
          }



          {
            Object.keys(marketList).map((city) => {
              return marketList[city].neighbourList.map((neighbourhood) => {
                var neighbourhoodStringProcessed = neighbourhood.replace(' ', "");
                var cityStringProcessed = city.replace(' ', "");
                return <Route
                    path={`/custom-framing-matboard-${neighbourhoodStringProcessed}-${cityStringProcessed}-fr`}
                    render={props => <FetchSeoArticle {...props} neighbourhood={neighbourhood} city={marketList[city].cityName} />}
                />
              })
            })
          }

          {
            Object.keys(keywordList).map((keyword) => {
              return <Route
                  path={`/get-${keywordList[keyword].replace(/\s+/g, '-')}`}
                  render={props => <SeoKeywordArticle {...props} keyword={keywordList[keyword].toUpperCase()}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.ribba.inch).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.ribba.inch[keyword].replace(/\s+/g, '-')}-inch-ikea-ribba-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.ribba.inch[keyword]} unit={"inch"} frame={"Ribba"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.ribba.cm).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.ribba.cm[keyword].replace(/\s+/g, '-')}-cm-ikea-ribba-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.ribba.cm[keyword]} unit={"cm"} frame={"Ribba"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.lomviken.inch).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.lomviken.inch[keyword].replace(/\s+/g, '-')}-inch-ikea-lomviken-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.lomviken.inch[keyword]} unit={"inch"} frame={"lomviken"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.lomviken.cm).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.lomviken.cm[keyword].replace(/\s+/g, '-')}-cm-ikea-lomviken-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.lomviken.cm[keyword]} unit={"cm"} frame={"lomviken"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.knoppang.inch).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.knoppang.inch[keyword].replace(/\s+/g, '-')}-inch-ikea-knoppang-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.knoppang.inch[keyword]} unit={"inch"} frame={"knoppang"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.knoppang.cm).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.knoppang.cm[keyword].replace(/\s+/g, '-')}-cm-ikea-knoppang-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.knoppang.cm[keyword]} unit={"cm"} frame={"knoppang"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.hovsta.inch).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.hovsta.inch[keyword].replace(/\s+/g, '-')}-inch-ikea-hovsta-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.hovsta.inch[keyword]} unit={"inch"} frame={"hovsta"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.hovsta.cm).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.hovsta.cm[keyword].replace(/\s+/g, '-')}-cm-ikea-hovsta-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.hovsta.cm[keyword]} unit={"cm"} frame={"hovsta"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.fiskbo.inch).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.fiskbo.inch[keyword].replace(/\s+/g, '-')}-inch-ikea-fiskbo-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.fiskbo.inch[keyword]} unit={"inch"} frame={"fiskbo"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.fiskbo.cm).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.fiskbo.cm[keyword].replace(/\s+/g, '-')}-cm-ikea-fiskbo-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.fiskbo.cm[keyword]} unit={"cm"} frame={"fiskbo"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.edsbruk.inch).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.edsbruk.inch[keyword].replace(/\s+/g, '-')}-inch-ikea-edsbruk-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.fiskbo.inch[keyword]} unit={"inch"} frame={"edsbruk"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.edsbruk.cm).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.edsbruk.cm[keyword].replace(/\s+/g, '-')}-cm-ikea-edsbruk-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.fiskbo.cm[keyword]} unit={"cm"} frame={"edsbruk"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.dalskarr.inch).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.dalskarr.inch[keyword].replace(/\s+/g, '-')}-inch-ikea-dalskarr-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.dalskarr.inch[keyword]} unit={"inch"} frame={"dalskarr"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.dalskarr.cm).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.dalskarr.cm[keyword].replace(/\s+/g, '-')}-cm-ikea-dalskarr-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.dalskarr.cm[keyword]} unit={"cm"} frame={"dalskarr"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.silverhojden.inch).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.silverhojden.inch[keyword].replace(/\s+/g, '-')}-inch-ikea-silverhojden-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.silverhojden.inch[keyword]} unit={"inch"} frame={"silverhojden"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.silverhojden.cm).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.silverhojden.cm[keyword].replace(/\s+/g, '-')}-cm-ikea-silverhojden-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.silverhojden.cm[keyword]} unit={"cm"} frame={"silverhojden"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.virserum.inch).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.virserum.inch[keyword].replace(/\s+/g, '-')}-inch-ikea-virserum-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.virserum.inch[keyword]} unit={"inch"} frame={"virserum"}/>}
              />
            })
          }

          {
            Object.keys(ikeaFrameSizes.virserum.cm).map((keyword) => {
              return <Route
                  path={`/${ikeaFrameSizes.virserum.cm[keyword].replace(/\s+/g, '-')}-cm-ikea-virserum-matboards`}
                  render={props => <IkeaFrameMat {...props} keyword={ikeaFrameSizes.virserum.cm[keyword]} unit={"cm"} frame={"virserum"}/>}
              />
            })
          }


          {/*## Internal Applications*/}


        </Switch>
        <NavBarBottom
            language={this.state.language}
            location={this.state.location}
        />
      </div>
    </BrowserRouter>
  };
};

// const trackingPage = () => {
//   return <OrderTracking/>
// };



// const howItWorksPage = () => {
//   return <HowItWorksV2/>
// }
//
// const colourGuidePage = () => {
//   return <ColourGuide/>
// }

const policyPage = () => {
  return <UserPolicies/>
};

const refundPage = () => {
  return <RefundPolicy/>
};



const orderChangePage = () => {
  return <OrderChange/>
};



const errPage = () => {
  return <ErrPage/>
};



const galleryPage = () => {
  return <TheGallery/>
};



const pricingPage = () => {
  return <PricingAndShippingPage/>
};

const blogArticleOne = () => {
  return <BlogArticleOne/>
};

const blogArticleTwo = () => {
  return <BlogArticleTwo/>
};

const blogArticleThree = () => {
  return <BlogArticleThree/>
};

const blogArticleFour = () => {
  return <BlogArticleFour/>
};

const blogArticleFive = () => {
  return <BlogArticleFive/>
};

const blogArticleSix = () => {
  return <BlogArticleSix/>
};

const blogArticleSeven = () => {
  return <BlogArticleSeven/>
};

const blogArticleEight = () => {
  return <BlogArticleEight/>
};

const blogArticleNine = () => {
  return <BlogArticleNine/>
}

const blogArticleTen = () => {
  return <BlogArticleTen/>
}

const blogArticleEleven = () => {
  return <BlogArticleEleven/>
}

const blogArticleTwelve = () => {
  return <BlogArticleTwelve/>
}

const whatWeDoPage = () => {
  return <WhatWeDoSection/>
};

// const howItWorksPageV2 = () => {
//   return <HowItWorksSection/>
// };

const showAtlantaPage = () => {
  return  <ShowAtlantaPage/>
};

const showBostonPage = () => {
  return <ShowBostonPage/>
};

const showCalgaryPage = () => {
  return <ShowCalgaryPage/>
};

const showChicagoPage = () => {
  return <ShowChicagoPage/>
};

const showDallasPage = () => {
  return <ShowDallasPage/>
};

const showDcPage = () => {
  return <ShowDcPage/>
};

const showEdmontonPage = () => {
  return <ShowEdmonton/>
};

const showHoustonPage = () => {
  return <ShowHoustonPage/>
};

const showLaPage = () => {
  return <ShowLaPage/>
};

const showMiamiPage = () => {
  return <ShowMiamiPage/>
};

const showMontrealPage = () => {
  return <ShowMontrealPage/>
};

const showNycPage = () => {
  return <ShowNewYorkCityPage/>
};

const showOttawaPage = () => {
  return <ShowOttawaPage/>
};

const showPhillyPage = () => {
  return <ShowPhillyPage/>
};

const showPortlandPage = () => {
  return <ShowPortlandPage/>
};

const showSeattlePage = () => {
  return <ShowSeattle/>
};

const showSfPage = () => {
  return <ShowSfPage/>
};

const showTorontoPage = () => {
  return <ShowTorontoPage/>
};

const showVancouverPage = () => {
  return <ShowVancouverPage/>
};

const startDesigningPage = () => {
  return <StartDesigningPage/>
};

const selectRegularFramePage = () => {
  return <SelectRegularFrame/>
};

const selectPolaroidFrame = () => {
  return <SelectPolaroidFrame/>
};

const selectPolaroidType = () => {
  return <PolaroidTypeSelector/>
};

const measuringYourMat = () => {
  return <MeasuringYourMat/>
}

const inspirationPage = () => {
  return <Inspiration/>
}

const shippingPage = () => {
  return <ShippingPage/>
}



const customerReviewPage = () => {
  return <CustomerReviewSection/>
}

