import React from "react";
import landingPageArrowLeft from "../../../resources/logo/landing-page-arrow-left.svg";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import backingRectangle1 from "../../../resources/images/backingMats/BackingRectangle2.jpg";
import backingRectangle2 from "../../../resources/images/backingMats/BackingRectangle1.jpg";
import backingRectangle3 from "../../../resources/images/backingMats/BackingRectangle3.jpg";

import backingSquare1 from "../../../resources/images/backingMats/BackingSquare2.jpg";
import backingSquare2 from "../../../resources/images/backingMats/BackingSquare1.jpg";
import backingSquare3 from "../../../resources/images/backingMats/BackingSquare3.jpg";

import colourOptions1 from "../../../resources/precut-mat-options/colourOptions01-min.JPG";
import colourOptions2 from "../../../resources/precut-mat-options/colourOptions2-min.JPG";
import colourOptions3 from "../../../resources/precut-mat-options/colourOptions3-min.JPG";
import colourOptions4 from "../../../resources/precut-mat-options/colourOptions4.JPG";
import colourOptions5 from "../../../resources/precut-mat-options/colourOptions5.PNG";
import colourOptions6 from "../../../resources/precut-mat-options/colourOptions6.JPG";
import colourOptions7 from "../../../resources/precut-mat-options/colourOptions7.JPG";
import colourOptions8 from "../../../resources/precut-mat-options/colourOptions8.JPG";
import colourOptions9 from "../../../resources/precut-mat-options/colourOptions9.JPG";
import colourOptions10 from "../../../resources/precut-mat-options/colourOptions10.JPG";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {MenuItem} from "@material-ui/core";
import displayInputFields from "../app-matsize-rendering/components/displayInputFields";
import arrowUp from "../../../resources/svgIcons/select-mat-arrow-up.svg";
import arrowDown from "../../../resources/svgIcons/select-mat-arrow-down.svg";
import localStorageFunctions from "../comp-localStorage/localStroage";
import {Helmet} from "react-helmet";
import WhatIsMatboard from "../page-howItWorks/whatIsMatboard";
import InstagramLoader from "../page-landing/instagramLoader";
import ReviewComponent from "../app-review/reviewComponent";
import LandingPageReviewStars from "../page-landing/landingPageReviewStars";
import LandingPageFAQ from "../page-landing/landingPageFAQ";
import SatisfactionTransition from "../page-landing/satisfactionTransition";
import bone from "../../../resources/matColours/bone.jpg";
import ecru from "../../../resources/matColours/ecru.jpg";
import footballRed from "../../../resources/images/matboardColours/10FootballRedAQ5699.PNG";
import beaujolais from "../../../resources/images/matboardColours/11BeaujolaisAQ5151.PNG";

export default class BackingMats extends React.Component {

    constructor() {
        super();
        this.state = {
            orientation: "portrait",
            frameSize: {
                "5x7": {
                    img: backingRectangle1,
                    img2: backingRectangle1,
                    default: "4x6",
                    width: 5,
                    height: 7,
                    desc: '5x7" Matboard',
                    descDetail: '5x7" Matboards for 5x7" Frames with choice of interior dimensions',
                    descFr : 'Passe-partout 5x7"',
                    descDetailFr: '5x7" Matboards for 5x7" Frames with choice of interior dimensions',
                    priceCAD: 8.95,
                    photos: [backingRectangle1, backingRectangle2, backingRectangle3],
                },
                "9x9": {
                    img: backingSquare1,
                    img2: backingSquare1,
                    width: 9,
                    height: 9,
                    desc: '9x9" Matboard',
                    descDetail: '9x9" Matboards for 9x9" Frames with choice of interior dimensions',
                    descFr : 'Passe-partout 9x9"',
                    descDetailFr: '9x9" Matboards for 9x9" Frames with choice of interior dimensions',
                    priceCAD: 8.95,
                    default: "4x4",
                    photos: [backingRectangle1, backingRectangle2, backingRectangle3],

                },
                "8x10": {
                    img: backingRectangle1,
                    img2: backingRectangle1,
                    width: 8,
                    height: 10,
                    desc: '8x10" Matboard',
                    descDetail: '8x10" Matboards for 8x10" Frames with choice of interior dimensions',
                    descFr : 'Passe-partout 8x10"',
                    descDetailFr: '8x10" Matboards for 8x10" Frames with choice of interior dimensions',
                    priceCAD: 8.95,
                    photos: [backingRectangle1, backingRectangle2, backingRectangle3],

                },
                "11x14": {
                    img: backingRectangle1,
                    img2: backingRectangle1,
                    width: 11,
                    height: 14,
                    default: "4x6",
                    desc: '11x14" Matboard',
                    descDetail: '11x14" Matboards for 11x14" Frames with choice of interior dimensions',
                    descFr : 'Passe-partout 11x14"',
                    descDetailFr: '11x14" Matboards for 11x14" Frames with choice of interior dimensions',
                    priceCAD: 12.95,
                    photos: [backingRectangle1, backingRectangle2, backingRectangle3],

                },
                "12x16": {
                    img: backingRectangle1,
                    img2: backingRectangle1,
                    width: 12,
                    height: 16,
                    desc: '12x16" Matboard',
                    descDetail: '12x16" Matboards for 12x16" Frames with choice of interior dimensions',
                    descFr : 'Passe-partout 12x16"',
                    descDetailFr: '12x16" Matboards for 12x16" Frames with choice of interior dimensions',
                    priceCAD: 14.95,
                    default: "4x6",
                    photos: [backingRectangle1, backingRectangle2, backingRectangle3],

                },
                "14x18": {
                    img: backingRectangle1,
                    img2: backingRectangle1,
                    width: 14,
                    height: 18,
                    desc: '14x18" Matboard',
                    descDetail: '14x18" Matboards for 14x18" Frames with choice of interior dimensions',
                    descFr : 'Passe-partout 14x18"',
                    descDetailFr: '14x18" Matboards for 14x18" Frames with choice of interior dimensions',
                    priceCAD: 14.95,
                    photos: [backingRectangle1, backingRectangle2, backingRectangle3],

                },
                "16x20": {
                    img: backingRectangle1,
                    img2: backingRectangle1,
                    width: 16,
                    height: 20,
                    desc: '16x20" Matboard',
                    descDetail: '16x20" Matboards for 16x20" Frames with choice of interior dimensions',
                    descFr : 'Passe-partout 16x20"',
                    descDetailFr: '16x20" Matboards for 16x20" Frames with choice of interior dimensions',
                    priceCAD: 19.95,
                    photos: [backingRectangle1, backingRectangle2, backingRectangle3],

                },
                "18x24": {
                    img: backingRectangle1,
                    img2: backingRectangle1,
                    width: 18,
                    height: 24,
                    desc: '18x24" Matboard',
                    descDetail: '18x24" Matboards for 18x24" Frames with choice of interior dimensions',
                    descFr : 'Passe-partout 18x24"',
                    descDetailFr: '18x24" Matboards for 18x24" Frames with choice of interior dimensions',
                    priceCAD: 22.95,
                    photos: [backingRectangle1, backingRectangle2, backingRectangle3],

                },
                "19.75x19.75": {
                    img: backingSquare1,
                    img2: backingSquare1,
                    width: 19.75,
                    height: 19.75,
                    desc: '19.75x19.75" Matboard',
                    descDetail: '19.75x19.75" Matboards for 19.75x19.75" Frames with choice of interior dimensions',
                    descFr : 'Passe-partout 19.75x19.75"',
                    descDetailFr: '19.75x19.75" Matboards for 19.75x19.75" Frames with choice of interior dimensions',
                    priceCAD: 22.95,
                    photos: [backingSquare1, backingSquare2, backingSquare3],


                },
                "20x20": {
                    img: backingSquare1,
                    img2: backingSquare1,
                    width: 20,
                    height: 20,
                    desc: '20x20" Matboard',
                    descDetail: '20x20" Matboards for 20x20" Frames with choice of interior dimensions',
                    descFr : 'Passe-partout 20x20"',
                    descDetailFr: '20x20" Matboards for 20x20" Frames with choice of interior dimensions',
                    priceCAD: 22.95,
                    photos: [backingSquare1, backingSquare2, backingSquare3],

                },
                "19.75x27.5": {
                    img: backingRectangle1,
                    img2: backingRectangle1,
                    width: 19.75,
                    height: 27.5,
                    desc: '19.75x27.5" Matboard',
                    descDetail: '19.75x27.5" Matboards for 19.75x27.5" Frames with choice of interior dimensions',
                    descFr : 'Passe-partout 19.75x27.5"',
                    descDetailFr: '19.75x27.5" Matboards for 19.75x27.5" Frames with choice of interior dimensions',
                    priceCAD: 26.95,
                    photos: [backingRectangle1, backingRectangle2, backingRectangle3],

                },
                "20x28": {
                    img: backingRectangle1,
                    img2: backingRectangle1,
                    width: 20,
                    height: 28,
                    desc: '20x28" Matboard',
                    descDetail: '20x28" Matboards for 20x28" Frames with choice of interior dimensions',
                    descFr : 'Passe-partout 20x28"',
                    descDetailFr: '20x28" Matboards for 20x28" Frames with choice of interior dimensions',
                    priceCAD: 26.95,
                    photos: [backingRectangle1, backingRectangle2, backingRectangle3],

                },
                "24x35.75": {
                    img: backingRectangle1,
                    img2: backingRectangle1,
                    width: 24,
                    height: 35.75,
                    desc: '24x35.75" Matboard',
                    descDetail: '24x35.75" Matboards for 24x35.75" Frames with choice of interior dimensions',
                    descFr : 'Passe-partout 24x35.75"',
                    descDetailFr: '24x35.75" Matboards for 24x35.75" Frames with choice of interior dimensions',
                    priceCAD: 39.95,
                    photos: [backingRectangle1, backingRectangle2, backingRectangle3],

                },
                "24x36": {
                    img: backingRectangle1,
                    img2: backingRectangle1,
                    width: 24,
                    height: 36,
                    desc: '24x36" Matboard',
                    descDetail: '24x36" Matboards for 24x36" Frames with choice of interior dimensions',
                    descFr : 'Passe-partout 24x36"',
                    descDetailFr: '24x36" Matboards for 24x36" Frames with choice of interior dimensions',
                    priceCAD: 39.95,
                    photos: [backingRectangle1, backingRectangle2, backingRectangle3],
                },
                "32x40": {
                    img: backingRectangle1,
                    img2: backingRectangle1,
                    width: 32,
                    height: 40,
                    desc: '32x40" Matboard',
                    descDetail: '32x40" Matboards for 32x40" Frames with choice of interior dimensions',
                    descFr : 'Passe-partout 32x40"',
                    descDetailFr: '32x40" Matboards for 32x40" Frames with choice of interior dimensions',
                    priceCAD: 42.95,
                    photos: [backingRectangle1, backingRectangle2, backingRectangle3],
                },
            },
            selectedFrameSize: false,
            selectedOpeningSize: "4x6",
            selectedMatColour: "smoothWhite",
            orderQuantity: 1,
            fetchProductDetail: false,
            fetchDiscountDetail: false,
            fetchShippingInfo: false,
            matboardColourOptions: {
                smoothWhite: {
                    colourName: "Smooth White",
                    colourNameFr: "Blanc Lisse",
                },
                chinaWhite: {
                    colourName: "China White",
                    colourNameFr: "Blanc de Chine",
                },
                bone: {
                    colourName: "Bone",
                    colourNameFr: "Os",
                },
                ecru: {
                    colourName: "Ecru",
                    colourNameFr: "Écru",
                },
                avillonWhite: {
                    colourName: "Avillion",
                    colourNameFr: "Avillion",
                },

                seafoam: {
                    colourName: "Sea Foam",
                    colourNameFr: "Écume de Mer",
                },

                flagstone: {
                    colourName: "Flagstone (Matte)",
                    colourNameFr: "Dalle (Mat)",
                },

                straw: {
                    colourName: "Straw (Matte)",
                    colourNameFr: "Paille (Mat)",
                },

                silver: {
                    colourName: "Silver (Shiny Foil)",
                    colourNameFr: "Argent (Feuille Brillante)",
                },

                gold: {
                    colourName: "Gold (Shiny Foil)",
                    colourNameFr: "Or (Beuille Brillante)",
                },

                cricket: {
                    colourName: "Cricket Green",
                    colourNameFr: "Vert Grillon",
                },

                footballRed: {
                    colourName: "Fire",
                    colourNameFr: "Feu",
                },

                beaujolais: {
                    colourName: "Red",
                    colourNameFr: "Rouge",
                },

                royalBlue: {
                    colourName: "Royal Blue",
                    colourNameFr: "Bleu Royal",
                },

                navyBlue: {
                    colourName: "Navy Blue",
                    colourNameFr: "Bleu Marine",
                },

                wine: {
                    colourName: "Wine",
                    colourNameFr: "Vin",
                },

                mocha: {
                    colourName: "Chocolate",
                    colourNameFr: "Marron",
                },

                smoothBlack: {
                    colourName: "Black",
                    colourNameFr: "Noir",
                },

                icePink: {
                    colourName: "Ice Pink",
                    colourNameFr: "Rose Glacé",
                },

                persimmon: {
                    colourName: "Persimmon",
                    colourNameFr: "Persimmon",
                },

                purple: {
                    colourName: "Purple",
                    colourNameFr: "Violet",
                },

                cirrus: {
                    colourName: "Cirrus",
                    colourNameFr: "Cirrus",
                },

                powderBlue: {
                    colourName: "Powder Blue",
                    colourNameFr: "Bleu Poudre",
                },

                peach: {
                    colourName: "Peach",
                    colourNameFr: "Pêche",
                },

                lavender: {
                    colourName: "Lavender",
                    colourNameFr: "Lavande",
                },



            },
            openQuantityModal: true,
            ikeaFrameNames: {
                "5x7": ["Ribba", "Fiskbo", "Silverhojden", "Edsbruk", "Knoppang", "Vireserum"],
                "8x10": ["Ribba", "Fiskbo", "Himmelsby", "Edsbruk", "Knoppang", "Vireserum"],
                "9x9": ["Ribba", "Knoppang", "Sannahed", "Hovsta"],
                "12x16": ["Ribba", "Knoppang", "Lomviken", "Silverhojden", "Dalskarr", "Virserum", "Edsbruk", "Fiskbo"],
                "16x20": ["Ribba", "Knoppang", "Lomviken", "Edsburk", "Silverhojden", "Fiskbo"],
                "19.75x19.75": ["Ribba"],
                "19.75x27.5": ["Ribba", "Lomviken", "Hovsta", "Fiskbo", "Silverhojden", "Edsbruk", "Knoppang", "Ramsborg"],
                "24x35.75": ["Ribba", "Lomviken", "Hovsta", "Fiskbo", "Silverhojden", "Edsbruk", "Knoppang", "Skatteby"],
            }

        };
    };

    componentDidMount() {
        // windowaddEventListener("scroll", this.setState({showNotificationBanner: false})

        if (this.props.selectedMatSize === false) {
            var pathname = window.location.pathname;
            var matboardSize = pathname.substring(
                // pathname.indexOf("-inch") + 1,
                pathname.lastIndexOf("-inch-matboard")
            );
        }

        if (this.props.selectedMatSize) {
            this.setState({selectedFrameSize: this.props.selectedMatSize})
        }



        this.setState({
            french: (window.location.pathname.slice(-2) === "fr") ? true : false
        })
    };

    fetchProductSummary() {
        if (this.props.french) {
            return <div>
                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>Résumé du produit :<br/><br/></div>
                    <div>
                        Passe-partout de support / fixation de {this.state.selectedFrameSize} pouces, neutralisé à l'acide, de couleur {this.state.matboardColourOptions[this.state.selectedMatColour].colourNameFr}.<br/><br/>
                        Le carton de montage, également connu sous le nom de passe-partout, est un type de carton épais couramment utilisé dans le domaine artistique comme support pour encadrer ou exposer une œuvre d'art. Vous pouvez utiliser cet article avec un passe-partout standard (avec une découpe/fenêtre) ou seul en tant que carton de montage autonome. .<br/><br/>
                        Le principal objectif du carton de montage est de fournir une surface plane et stable à laquelle l'œuvre d'art peut être fixée, ainsi que de créer une séparation entre l'œuvre d'art et le cadre ou le verre.<br/><br/>
                        Il contribue également à protéger l'œuvre d'art contre la poussière, l'humidité et d'autres facteurs environnementaux qui pourraient l'endommager au fil du temps.<br/><br/>

                        Il n'y a pas de quantité minimale de commande. La livraison est gratuite pour les commandes de plus de 49 $ ou à un tarif fixe de 9,95 $ pour les commandes de moins.<br/><br/>
                        Des remises quantitatives sont disponibles pour votre commande. Les remises seront automatiquement appliquées lorsque vous commandez 2+, 4+ et 10+ articles. Si vous avez besoin de plus de 50 feuilles, veuillez nous contacter pour obtenir un devis. Nous livrons partout au Canada et aux États-Unis.
                        <br/><br/>
                    </div>
                    <div className={"product-desc-dividers-header"}>
                        Spécifications du produit :
                    </div>
                    <div>
                        <ul>
                            <li>Dimensions totales: {this.state.selectedFrameSize} pouces</li>
                            <li>Passe-partout sans acide : Surface, cœur et papiers de support neutralisés à l'acide pour répondre à vos besoins de préservation</li>
                            <li>Standard de l'industrie, 4 plis, d'une épaisseur de 1/16 de pouce</li>
                            <li>Découpes nettes et précises</li>
                            <li>Fabriqué et traité au Canada.</li>
                        </ul>
                    </div>
                </div>

                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        Autres informations :
                        <br/><br/>
                    </div>
                    <div>
                        Nos passe-partout de {this.state.selectedFrameSize} pouces sont conçus pour s'adapter aux cadres de taille standard de {this.state.selectedFrameSize} pouces vendus par Amazon, Costco, IKEA, Target, Michael's et d'autres magasins de détail. {this.fetchIKEAFrameNames()}
                    </div>
                </div>

                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        Politique de satisfaction à 100%:<br/><br/>
                    </div>
                    <div>
                        100 % de bonheur garanti. Si vous n'êtes pas satisfait de votre commande, veuillez nous en informer. Contactez-nous à l'adresse hello@custommat.ca et nous rectifierons la situation.
                    </div>
                </div>

                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        Besoin de personnalisation supplémentaire ou d'options de couleur?<br/><br/>
                    </div>
                    <div>
                        Essayez nos passe-partout personnalisés. Ils sont entièrement personnalisables - votre couleur, votre forme et votre design. Ils sont disponibles en plus de 20 options de couleur.
                    </div>
                </div>


                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        Des questions?<br/><br/>
                    </div>
                    <div>
                        Si vous avez des questions, veuillez nous envoyer un e-mail à hello@CustomMat.ca ou nous envoyer un SMS au 604•345•8428.<br/><br/>
                    </div>
                </div>



            </div>
        } else {
            return <div>
                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>Product Summary:<br/><br/></div>
                    <div>
                        {this.state.selectedFrameSize} inch acid-neutralized backing / mounting matboard in {this.state.matboardColourOptions[this.state.selectedMatColour].colourName} {this.props.location === "us" ? " color" : " colour"}.<br/><br/>
                        Mounting board, also known as mat board or matting board, is a type of paperboard that is often used in artwork as a backing or support for framing or displaying the artwork. You can use this item along with a regular matboard (with a cutout/window) or without it as a standalone mounting board.<br/><br/>
                        The main purpose of mounting board is to provide a flat and stable surface for the artwork to be attached to, as well as to provide a buffer between the artwork and the frame or glass. <br/><br/>
                        It also helps to protect the artwork from dust, moisture, and other environmental factors that could damage it over time.

                        There is no minimum order quantity. Free shipping across Canada & the USA <br/><br/>
                        Quantity discounts are available for your order. Discounts will be reflected automatically as you order 2+, 4+, and 10+ orders. If you need more than 50+ sheets, please contact us for pricing. We ship to anywhere in Canada and the United States.<br/><br/>
                    </div>
                    <div className={"product-desc-dividers-header"}>
                        Product Specifications:
                    </div>
                    <div>
                        <ul>
                            <li>Overall Dimension: {this.state.selectedFrameSize} inch</li>
                            <li>Acid-free matboard: Acid neutralized surface, core, and backing papers for your preservation needs</li>
                            <li>Industry standard, 4 PLY, 1/16 inch thick</li>
                            <li>Clean and crisp cut</li>
                            <li>Made and processed in Canada</li>
                        </ul>
                    </div>
                </div>

                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        Other Notes:<br/><br/>
                    </div>
                    <div>
                        Our {this.state.selectedFrameSize} inch matboards are designed to fit {this.state.selectedFrameSize} industry standard sized frames that are sold by Amazon, Costco, IKEA, Target, Michael’s and other retail stores. {this.fetchIKEAFrameNames()}<br/><br/>
                    </div>
                </div>

                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        100% Satisfaction Policy:<br/><br/>
                    </div>
                    <div>
                        100% Happiness Guaranteed. If you are not happy with your order, please let us know. Contact us through hello@custommat.ca and we will make it right.<br/><br/>
                    </div>
                </div>

                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        Need further customization or more {this.props.location === "us" ? " color " : " colour "} options?<br/><br/>
                    </div>
                    <div>
                        Try our custom matboards. They are 100% customizable – your {this.props.location === "us" ? " color " : " colour "}, your shape, and your design. They come in 20+ {this.props.location === "us" ? " color " : " colour "} options.<br/><br/>
                    </div>
                </div>


                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        Any Questions?<br/><br/>
                    </div>
                    <div>
                        If you have any questions, please email us hello@CustomMat.ca or text us at 604•345•8428.<br/><br/>
                    </div>
                </div>



            </div>
        }
    }

    fetchIKEAFrameNames() {
        var frameNames = this.props.french ? `Pour les cadres IKEA, nos passe-partout de ${this.state.selectedFrameSize} pouces sont conçus pour s'adapter aux cadres Ribba, Knoppang, Sannahed et Hovsta.` : `For IKEA frames, our ${this.state.selectedFrameSize} inch matboards are designed to fit `;

        if (this.state.ikeaFrameNames[this.state.selectedFrameSize]) {
            this.state.ikeaFrameNames[this.state.selectedFrameSize].map((frame, key) => {
                if (key > 0) {
                    frameNames = frameNames + ", " + frame;
                } else {
                    frameNames = frameNames + " " + frame;
                }
            });
            frameNames = frameNames + `${this.props.french ? " cadres." : " frames."}`
            return frameNames
        }

    }



    clickAddToCartButton() {

        var selectedProductData = this.state.frameSize[this.state.selectedFrameSize];
        var frameWidth = selectedProductData.width;
        var frameHeight = selectedProductData.height;
        var price = selectedProductData.priceCAD;


        var item = {
            prodType: "backingMat",
            frameType: "Not Applicable -- Matboard Only",
            frameSize: "Not Applicable -- Matboard Only",
            frameWidth: frameWidth,
            frameHeight: frameHeight,
            matColour: this.state.selectedMatColour,
            window: {
                rowCount: 1,
                columnCount: 1,
                window1: {
                    width: 0,
                    height: 0,
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    showModal: false,
                },
            },
            secondMatPrice: 0,
            secondMatColour: "none",
            secondMatWidth: 0,
            orderQuantity: parseInt(this.state.orderQuantity),
            // //for matboard price, we will have to run a pricing mechanism for matboard pricing. We might borrow this from our legacy system.
            price: price,
            shipping: [9.95, 9.95],
            canvasDrawing: null,
            additionalNoteContent: "",
            backingMatboard: false,
            isWindowOval: false,
        };

        this.addProductToLocalStorageCart(item);
        this.props.showShoppingCartModal("open");

        this.submitToGTag(price)
    };

    submitToGTag(price) {
        window.gtag('event', 'add_to_cart', {
            'send_to': 'AW-732793253/RjgUCLrI2qcBEKWTtt0C',
            'value': parseFloat(price),
            'currency': "CAD",
            'transaction_id': ''
        })
    }

    addProductToLocalStorageCart(item) {
        //check if a shopper has already shopped something on before this item
        var shoppingCart = [];

        if (localStorageFunctions("CJ92A38CCJXAE8C379C6F79758BQJXB88D14FEJKB", null) !== null ) {
            //this part will have to be parse json

            shoppingCart = JSON.parse(localStorageFunctions("CJ92A38CCJXAE8C379C6F79758BQJXB88D14FEJKB", null));

            shoppingCart.push(item);
        } else {
            shoppingCart.push(item);
        };

        localStorageFunctions("CJ92A38CCJXAE8C379C6F79758BQJXB88D14FEJKB", JSON.stringify(shoppingCart));
    };

    toggleModal(stateToToggle) {
        this.setState({[stateToToggle]: !this.state[stateToToggle]})
    };

    clickPrecutModal(sortSize) {
        window.location.href = this.props.french ? `${sortSize}-inch-backing-mounting-matboards-fr` : `${sortSize}-inch-backing-mounting-matboards`;
        // this.setState({selectedFrameSize: sortSize});
        //
        // if (sortSize === "9x9") {
        //     this.setState({selectedOpeningSize: "4x4"});
        // } else if (sortSize === "19.75x19.75") {
        //     this.setState({selectedOpeningSize: "4x4"});
        // } else if (sortSize === "20x20") {
        //     this.setState({selectedOpeningSize: "4x4"});
        // }
    };

    fetchPreCutMats(FrameOrArtworkSize) {

        var array = Object.keys(this.state.frameSize);

        return array.map((sortSize, key) => {
            return <div className={"pre-cut-mat-box"} onClick={() => {this.clickPrecutModal(sortSize)}}>
                <img style={{width: "100%"}} src={this.state.frameSize[sortSize].img} className={"pre-cut-mat-box-img"}
                     alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                />
                <div className={"fetch-precut-mats-title-container"}>
                    <div className={"fetch-precut-mats-title"}>
                        {`${sortSize}" ${this.props.french ? "SUPPORT PASSE-PARTOUTS" : "Mounting Mats"}`}
                    </div>
                    <div className={"fetch-precut-mats-subtitle"}>
                        {this.props.currency}{this.props.fxConversion(this.state.frameSize[sortSize].priceCAD * this.props.getDiscountMultiplier(1)).toFixed(2)}
                        {/*adding 1 to get discount mulitplire as if you add 1 price will change likely*/}
                    </div>
                </div>
            </div>
        })
    };

    fetchWindowSizeDropdown(dropDownList, title, stateToUpdate) {
        // var list = Object.keys(productDB.taxRates[this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw]);

        var list = Object.keys(dropDownList)

        return <FormControl
            variant="outlined" className={"precut-window-selector"}>
            <div className={"add-text-fields-text-field-title"}>
                {title}
            </div>
            <Select value={this.state[stateToUpdate]}
                    onChange={(evt) => {
                        // this.setState({[stateToUpdate]: evt.target.value})
                        window.location.pathname = `${this.props.selectedMatSize}-inch-matboard-${evt.target.value}-inch-opening`
                    }}
            >
                <MenuItem value={"Select"}>
                    Select
                </MenuItem>
                {
                    list.map((item, key) => {
                        return <MenuItem value={item} key={`select-province-state-${key}`} >
                            {item}" - {dropDownList[item].desc}
                        </MenuItem>
                    })
                }
            </Select>
        </FormControl>
    };

    fetchListDropDown(dropDownList, title, stateToUpdate) {
        var list = dropDownList;

        return <FormControl
            variant="outlined" className={"precut-window-selector"}>
            <div className={"add-text-fields-text-field-title"}>
                {title}
            </div>
            <Select value={this.state[stateToUpdate]}
                    onChange={(evt) => {
                        this.setState({[stateToUpdate]: evt.target.value})
                    }}
            >
                <MenuItem value={"Select"}>
                    {this.props.french ? "Sélectionner" : "Select"}
                </MenuItem>
                {
                    list.map((item, key) => {
                        return <MenuItem value={item} key={`select-province-state-${key}`} >
                            {this.props.french ? this.state.matboardColourOptions[item].colourNameFr : this.state.matboardColourOptions[item].colourName}
                        </MenuItem>
                    })
                }
            </Select>
        </FormControl>
    };

    reduceQuantity() {
        if (this.state.orderQuantity > 1) {
            this.setState({orderQuantity: this.state.orderQuantity - 1})
        } else {
            alert("Order quantity cannot be less than 1.")
        }

    };

    fetchAddToBagButton() {
        return <div className="add-to-bag-button" style={{width: "auto"}} onClick={() => {this.clickAddToCartButton()}}>
            {this.props.french ? "Ajouter au adc" : "Add to bag"}
        </div>
    }

    validateQuantity(value) {
        console.log(parseInt(value), Number.isInteger(parseInt(value)))
        if (Number.isInteger(parseInt(value)) === false || parseInt(value) <= 0) {
            alert("Please enter a valid quantity.")
        } else if (parseInt(value) > 0) {
            this.setState({orderQuantity: parseInt(value)})
        }
    }

    fetchPreCutMatDetails() {

        var selectedFrame = this.state.frameSize[this.state.selectedFrameSize];

        if (this.props.french) {
            return <div className={"precut-container"}>
                <div className={"precut-sub-container"}>

                    <div className={"precut-container-left"}>

                        <Carousel>
                            <div className={"carousel-image-item"}>
                                <img src={selectedFrame.photos[0]} className={"precut-container-left-photo"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={selectedFrame.photos[1]} className={"precut-container-left-photo"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={selectedFrame.photos[2]} className={"precut-container-left-photo"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions1}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions2}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions3}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions4}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions5}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions6}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions7}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions8}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions9}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions10}/>
                            </div>

                        </Carousel>

                    </div>

                    <div className={"precut-container-right"}>

                        <div style={{width: "auto", overflow: "auto", paddingBottom: "16px"}}>

                            {this.fetchAddToBagButton()}

                            <div className="select-mat-container-row" style={{width: "auto", cursor: "pointer"}}>
                                {this.props.french ? "Devise" : "Currency"}:
                                <span className={this.props.currency === "CA$" ? "bold" : ""} onClick={() => {this.props.changeCurrency("CA$")}}> CAD </span>
                                |
                                <span className={this.props.currency === "US$" ? "bold" : ""} onClick={() => {this.props.changeCurrency("US$")}}> USD </span>
                                <br/>
                                {this.props.french ? "Prix unitaire" : "Unit Price"}: <b>$<span>{this.props.fxConversion(this.state.frameSize[this.state.selectedFrameSize].priceCAD * this.props.getDiscountMultiplier(this.state.orderQuantity)).toFixed(2)}</span></b>
                                <div className={this.props.onGoingPromotion ? "promotion-messages" : "no-display"}>
                                    {this.props.french ? `Réduction extra ${this.props.promotionRate} à la caisse` : `Additional ${this.props.promotionRate} off at checkout`}
                                </div>
                            </div>
                        </div>

                        <div className={"precut-size-header"}>
                            {this.state.selectedFrameSize}" {this.props.french ? "Passe-partout de SUPPORT / FIXATION" : "Backing / Mounting Matboard"}
                        </div>

                        {/*<img className={"select-matboard-window-caption-arrow"}*/}
                        {/*     onClick={() => this.setState({openQuantityModal: !this.state.openQuantityModal})}*/}
                        {/*     src={this.state.openQuantityModal ? arrowUp : arrowDown}*/}
                        {/*     alt={"Click to open Quantity Modal"}*/}
                        {/*/>*/}

                        <div className="add-text-fields-text-field-title" style={{width: "100%", borderBottom: "1px solid #828282", paddingBottom: "12px", marginBottom: "12px", fontWeight: "500", color: "#3b3b3b"}}>
                            {this.props.french === "fr" ? "Quantité" : "Quantity"}<br/>
                        </div>

                        <div style={{width: "100%", overflow: "auto", fontWeight: 400}}>
                            <div className={"quantity-change-div"} style={{float: "left"}}>
                                <span style={{textTransform: "none", color: "#3B3B3B"}}>{this.props.fetchDiscountTier(this.state.orderQuantity)}</span>
                                <a target={"_blank"} href={"/pricing-and-discounts"} className={"select-mat-request-samples"}>{this.props.french ? "Remise quantitative" : "Quantity Discount"}</a>
                            </div>

                            <div className={"quantity-change-div"}  style={{float: "right"}}>
                                <div className={"cart-slider-shopped-item-quantity-button quantity-change-div-button"}  style={{width: "auto", margin: "10px", marginRight: 0, float: "right"}}  onClick={() => {this.reduceQuantity()}}>-</div>
                                <div className={"order-quantity-input-box"} style={{float: "right"}}>
                                    {displayInputFields("", parseInt(this.state.orderQuantity), (event) => this.validateQuantity(event.target.value), "outlined")}
                                </div>
                                <div className={"cart-slider-shopped-item-quantity-button quantity-change-div-button"} style={{width: "auto", margin: "10px", float: "right"}} onClick={() => {this.setState({orderQuantity: this.state.orderQuantity + 1})}}>+</div>
                            </div>
                        </div>


                        {/*<div style={{width: "100%", overflow: "auto", paddingBottom: "16px"}}>*/}
                        {/*    {this.fetchWindowSizeDropdown(this.state.frameSize[this.state.selectedFrameSize].openSize, "Window Size", "selectedOpeningSize")}*/}
                        {/*    <div className={"select-mat-input-field-direction-long"}>*/}
                        {/*        Your actual window opening size will be <b>{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height}"</b>.*/}
                        {/*        The window opening should be smaller than your artwork.<br/><br/>*/}
                        {/*        Don't have the opening you are looking for? <a href={"/customize-matboards"}>Click here to get a custom opening.</a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div style={{width: "100%", overflow: "auto", paddingBottom: "16px"}}>
                            {this.fetchListDropDown(Object.keys(this.state.matboardColourOptions), this.props.french ? "Couleur du passe-partout" : `Matboard ${this.props.location === "us" ? "Color" : "Colour"}`, "selectedMatColour")}
                            <div className={"select-mat-input-field-direction-long"}>
                                {this.props.french ? `Plus d'options de couleur et de taille sont disponibles avec l'option de ` : `More ${this.props.location === "us" ? " color " : " colour "} options available  `}

                                <a href={"/customize-matboards"}>{this.props.french ? "passe-partout personnalisé" : "with custom matting option."} </a>
                            </div>
                        </div>


                        {/*<div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b"}}>*/}
                        {/*        Quantity*/}

                        {/*</div>*/}

                        {/*<div className={"cart-slider-shopped-item-quantity"} style={{margin: 0, paddingBottom: "12px", width: "100%", borderBottom: "solid 1px #828282"}}>*/}
                        {/*    <div className={"cart-slider-shopped-item-quantity-button"} style={{marginTop: "10px"}} onClick={() => {this.setState({selectedQuantity: this.state.selectedQuantity + 1})}}>+</div>*/}
                        {/*    <div className={"select-window-multiple-window-dimensions"}>*/}
                        {/*        /!*{this.state.selectedQuantity}*!/*/}
                        {/*        {displayInputFields("", this.state.selectedQuantity, (event) => this.setState({selectedQuantity: event.target.value}))}*/}
                        {/*    </div>*/}
                        {/*    <div className={"cart-slider-shopped-item-quantity-button"} style={{marginTop: "10px", marginLeft: "10px"}} onClick={() => {this.reduceQuantity()}}>-</div>*/}
                        {/*</div>*/}

                        <div style={{width: "100%", overflow: "auto", paddingBottom: "16px", cursor: "pointer"}}>

                            <div className="select-mat-row" style={{paddingBottom: "4px", marginTop: "12px", width: "100%", borderBottom: "solid 1px #828282"}} onClick={() => this.setState({fetchDiscountDetail: !this.state.fetchDiscountDetail})}>
                                <img className="select-matboard-window-caption-arrow"
                                     style={{marginTop: "-2px", paddingTop: 0}}
                                     src={this.state.fetchDiscountDetail ? arrowUp : arrowDown} alt="Click to add a note." />
                                <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b", fontSize: "13px", overflow: "auto"}}>
                                    {this.props.french ? "Remises Quantitatives" : "Quantity Discounts"}
                                </div>
                                <div className={this.state.fetchDiscountDetail ? "display" : "no-display"} style={{marginLeft: "40px"}}>
                                    <li>
                                        {this.props.french ? "2 articles ou plus - 20% de réduction" : " 2+ items - 20% off"}
                                    </li>
                                    <li>
                                        {this.props.french ? "4 articles ou plus - 30% de réduction" : "4+ items - 30% off"}
                                    </li>
                                    <li>
                                        {this.props.french ? "10 articles ou plus - 40% de réduction" : "10+ items - 40% off"}
                                    </li>
                                    <li>
                                        {this.props.french ? "20 articles ou plus - 50% de réduction" : "20+ items - 50% off"}
                                    </li>
                                    <li>
                                        {this.props.french ? "50 articles ou plus - 60% de réduction" : "50+ items - 60% off"}
                                    </li>
                                    <li>
                                        {this.props.french ? "100 articles ou plus - 64% de réduction" : "100 items - 64% off"}
                                    </li>
                                    <li>
                                        {this.props.french ? "250 articles ou plus - 66% de réduction" : "250+ items - 66% off"}
                                    </li>
                                    <li>
                                        {this.props.french ? "500 articles ou plus - 68% de réduction" : "500+ items - 68% off"}
                                    </li>
                                    <li>
                                        {this.props.french ? "1000 articles ou plus - 70% de réduction" : "1000+ items - 70% off"}

                                    </li>
                                </div>
                            </div>


                            <div className="select-mat-row" style={{paddingBottom: "4px", marginTop: "12px", width: "100%", borderBottom: "solid 1px #828282"}} onClick={() => this.setState({fetchProductDetail: !this.state.fetchProductDetail})}>
                                <img className="select-matboard-window-caption-arrow"
                                     style={{marginTop: "-2px", paddingTop: 0}}
                                     src={this.state.fetchProductDetail ? arrowUp : arrowDown} alt="Click to add a note." />
                                <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b", fontSize: "13px", overflow: "auto"}}>
                                    {this.props.french ? "Détails du produit" : "Product Details"}
                                </div>
                                <div className={this.state.fetchProductDetail ? "display" : "no-display"} style={{marginLeft: "40px" }}>
                                    <li>
                                        {this.props.french ? "Taille du passe-partout :" : "Matboard Size: "}  {this.state.selectedFrameSize}
                                    </li>

                                    <li>
                                        {this.props.french ? "Standard 4 plis (1/16 de pouce) d'épaisseur" : " Standard 4-ply (1/16 inch) thick"}

                                    </li>
                                    <li>
                                        {this.props.french ? "Sans acide (surface tamponnée pour neutraliser l'acidité) pour répondre à vos besoins de préservation" : "Acid-free (acid-neutralized) buffered surface to meet your preservation needs"}
                                    </li>
                                    <li>
                                        {this.props.french ? "Résistant à la décoloration et à l'écoulement de l'encre" : "Fade and bleed resistant"}
                                    </li>
                                    <li>
                                        {this.props.french ? "Fabriqué et expédié depuis le Canada" : "Made and shipped from Canada"}
                                    </li>
                                </div>
                            </div>


                            <div className="select-mat-row" style={{paddingBottom: "4px", marginTop: "12px", width: "100%", borderBottom: "solid 1px #828282"}} onClick={() => this.setState({fetchShippingInfo: !this.state.fetchShippingInfo})}>
                                <img className="select-matboard-window-caption-arrow"
                                     style={{marginTop: "-2px", paddingTop: 0}}
                                     src={this.state.fetchShippingInfo ? arrowUp : arrowDown} alt="Click to add a note." />
                                <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b", fontSize: "13px", overflow: "auto"}}>
                                    {this.props.french ? "Livraison et retours" : "Shipping & Returns"}
                                </div>
                                <div className={this.state.fetchShippingInfo ? "display" : "no-display"} style={{marginLeft: "40px"}}>
                                    <li>
                                        {this.props.french ? "Expédition au Canada et aux États-Unis" : "Ship to Canada and the USA"}
                                    </li>
                                    <li>
                                        {this.props.french ? "Livraison gratuite pour les commandes de plus de 49$" : "Free shipping over CA$49 or US$39"}
                                    </li>
                                    <li>
                                        {this.props.french ? "Free shipping across Canada & the USA" : "Free shipping across Canada & the USA"}
                                    </li>
                                    <li>
                                        {this.props.french ? "Nous acceptons les retours" : "We accept returns"}
                                    </li>
                                    <li>
                                        {this.props.french ? "Expédié dans un délai d'un jour ouvrable" : "Dispatches within 1 business day"}
                                    </li>
                                    <li>
                                        {this.props.french ? "Emballage résistant aux dommages" : "Damage-resistant packaging"}
                                    </li>
                                    <li>
                                        {this.props.french ? "En cas de dommages pendant le transport, des passe-partout de remplacement seront envoyés." : " If damaged while in transit, replacements mats will be sent"}
                                    </li>
                                </div>
                            </div>

                        </div>

                        <div style={{width: "auto", overflow: "auto", paddingBottom: "16px"}}>
                            {this.fetchAddToBagButton()}
                            {/*<div className="select-mat-container-row" style={{width: "auto"}}>*/}
                            {/*    Currency: <b>CAD</b><br/>*/}
                            {/*    Unit Price: <b>$<span>{this.state.frameSize[this.state.selectedFrameSize].priceCAD}</span></b>*/}
                            {/*</div>*/}
                        </div>


                    </div>
                </div>
                {this.fetchProductSummary()}

            </div>

        } else {
            return <div className={"precut-container"}>
                <div className={"precut-sub-container"}>

                    <div className={"precut-container-left"}>

                        <Carousel>
                            <div className={"carousel-image-item"}>
                                <img src={selectedFrame.photos[0]} className={"precut-container-left-photo"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={selectedFrame.photos[1]} className={"precut-container-left-photo"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={selectedFrame.photos[2]} className={"precut-container-left-photo"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions1}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions2}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions3}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions4}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions5}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions6}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions7}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions8}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions9}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions10}/>
                            </div>

                        </Carousel>

                    </div>

                    <div className={"precut-container-right"}>

                        <div style={{width: "auto", overflow: "auto", paddingBottom: "16px"}}>

                            {this.fetchAddToBagButton()}



                            <div className="select-mat-container-row" style={{width: "auto", cursor: "pointer"}}>
                                Currency:
                                <span className={this.props.currency === "CA$" ? "bold" : ""} onClick={() => {this.props.changeCurrency("CA$")}}> CAD </span>
                                |
                                <span className={this.props.currency === "US$" ? "bold" : ""} onClick={() => {this.props.changeCurrency("US$")}}> USD </span>
                                <br/>
                                Unit Price: <b>$<span>{this.props.fxConversion(this.state.frameSize[this.state.selectedFrameSize].priceCAD * this.props.getDiscountMultiplier(this.state.orderQuantity)).toFixed(2)}</span></b>
                            </div>
                            {/*//Black Friday*/}
                            <div className={this.props.onGoingPromotion ? "promotion-messages" : "no-display"}>
                                {this.props.french ? `Réduction extra ${this.props.promotionRate} à la caisse` : `Additional ${this.props.promotionRate} off at checkout`}
                            </div>
                        </div>

                        <div className={"precut-size-header"}>
                            {this.state.selectedFrameSize}" Backing / Mounting Matboard
                        </div>

                        {/*<img className={"select-matboard-window-caption-arrow"}*/}
                        {/*     onClick={() => this.setState({openQuantityModal: !this.state.openQuantityModal})}*/}
                        {/*     src={this.state.openQuantityModal ? arrowUp : arrowDown}*/}
                        {/*     alt={"Click to open Quantity Modal"}*/}
                        {/*/>*/}

                        <div className="add-text-fields-text-field-title" style={{width: "100%", borderBottom: "1px solid #828282", paddingBottom: "12px", marginBottom: "12px", fontWeight: "500", color: "#3b3b3b"}}>
                            {this.props.french === "fr" ? "Quantité" : "Quantity"}<br/>
                        </div>

                        <div style={{width: "100%", overflow: "auto", fontWeight: 400}}>
                            <div className={"quantity-change-div"} style={{float: "left"}}>
                                <span style={{textTransform: "none", color: "#3B3B3B"}}>{this.props.fetchDiscountTier(this.state.orderQuantity)}</span>
                                <a target={"_blank"} href={"/pricing-and-discounts"} className={"select-mat-request-samples"}>Quantity Discount</a>
                            </div>

                            <div className={"quantity-change-div"}  style={{float: "right"}}>
                                <div className={"cart-slider-shopped-item-quantity-button quantity-change-div-button"}  style={{width: "auto", margin: "10px", marginRight: 0, float: "right"}}  onClick={() => {this.reduceQuantity()}}>-</div>
                                <div className={"order-quantity-input-box"} style={{float: "right"}}>
                                    {displayInputFields("", this.state.orderQuantity, (event) => this.validateQuantity(event.target.value), "outlined")}
                                </div>
                                <div className={"cart-slider-shopped-item-quantity-button quantity-change-div-button"} style={{width: "auto", margin: "10px", float: "right"}} onClick={() => {this.setState({orderQuantity: this.state.orderQuantity + 1})}}>+</div>
                            </div>
                        </div>


                        {/*<div style={{width: "100%", overflow: "auto", paddingBottom: "16px"}}>*/}
                        {/*    {this.fetchWindowSizeDropdown(this.state.frameSize[this.state.selectedFrameSize].openSize, "Window Size", "selectedOpeningSize")}*/}
                        {/*    <div className={"select-mat-input-field-direction-long"}>*/}
                        {/*        Your actual window opening size will be <b>{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height}"</b>.*/}
                        {/*        The window opening should be smaller than your artwork.<br/><br/>*/}
                        {/*        Don't have the opening you are looking for? <a href={"/customize-matboards"}>Click here to get a custom opening.</a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div style={{width: "100%", overflow: "auto", paddingBottom: "16px"}}>
                            {this.fetchListDropDown(Object.keys(this.state.matboardColourOptions), `Matboard ${this.props.location === "us" ? "Color" : "Colour"}`, "selectedMatColour")}
                            <div className={"select-mat-input-field-direction-long"}>
                                More {this.props.location === "us" ? " color " : " colour "} options available  <a href={"/customize-matboards"}>with custom matting option.</a>
                            </div>
                        </div>


                        {/*<div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b"}}>*/}
                        {/*        Quantity*/}

                        {/*</div>*/}

                        {/*<div className={"cart-slider-shopped-item-quantity"} style={{margin: 0, paddingBottom: "12px", width: "100%", borderBottom: "solid 1px #828282"}}>*/}
                        {/*    <div className={"cart-slider-shopped-item-quantity-button"} style={{marginTop: "10px"}} onClick={() => {this.setState({selectedQuantity: this.state.selectedQuantity + 1})}}>+</div>*/}
                        {/*    <div className={"select-window-multiple-window-dimensions"}>*/}
                        {/*        /!*{this.state.selectedQuantity}*!/*/}
                        {/*        {displayInputFields("", this.state.selectedQuantity, (event) => this.setState({selectedQuantity: event.target.value}))}*/}
                        {/*    </div>*/}
                        {/*    <div className={"cart-slider-shopped-item-quantity-button"} style={{marginTop: "10px", marginLeft: "10px"}} onClick={() => {this.reduceQuantity()}}>-</div>*/}
                        {/*</div>*/}

                        <div style={{width: "100%", overflow: "auto", paddingBottom: "16px", cursor: "pointer"}}>

                            <div className="select-mat-row" style={{paddingBottom: "4px", marginTop: "12px", width: "100%", borderBottom: "solid 1px #828282"}} onClick={() => this.setState({fetchDiscountDetail: !this.state.fetchDiscountDetail})}>
                                <img className="select-matboard-window-caption-arrow"
                                     style={{marginTop: "-2px", paddingTop: 0}}
                                     src={this.state.fetchDiscountDetail ? arrowUp : arrowDown} alt="Click to add a note." />
                                <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b", fontSize: "13px", overflow: "auto"}}>
                                    Quantity Discounts
                                </div>
                                <div className={this.state.fetchDiscountDetail ? "display" : "no-display"} style={{marginLeft: "40px"}}>
                                    <li>
                                        2+ items - 20% off
                                    </li>
                                    <li>
                                        4+ items - 30% off
                                    </li>
                                    <li>
                                        10 items - 40% off
                                    </li>
                                    <li>
                                        20 items - 50% off
                                    </li>
                                    <li>
                                        50 items - 60% off
                                    </li>
                                    <li>
                                        100 items - 64% off
                                    </li>
                                    <li>
                                        250 items - 66% off
                                    </li>
                                    <li>
                                        500 items - 68% off
                                    </li>
                                    <li>
                                        1000+ items - 70% off
                                    </li>
                                </div>
                            </div>


                            <div className="select-mat-row" style={{paddingBottom: "4px", marginTop: "12px", width: "100%", borderBottom: "solid 1px #828282"}} onClick={() => this.setState({fetchProductDetail: !this.state.fetchProductDetail})}>
                                <img className="select-matboard-window-caption-arrow"
                                     style={{marginTop: "-2px", paddingTop: 0}}
                                     src={this.state.fetchProductDetail ? arrowUp : arrowDown} alt="Click to add a note." />
                                <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b", fontSize: "13px", overflow: "auto"}}>
                                    Product Details
                                </div>
                                <div className={this.state.fetchProductDetail ? "display" : "no-display"} style={{marginLeft: "40px" }}>
                                    <li>
                                        Matboard Size: {this.state.selectedFrameSize}
                                    </li>
                                    {/*<li>*/}
                                    {/*    Designed for {this.state.selectedOpeningSize} inch Prints*/}
                                    {/*</li>*/}
                                    {/*/!*<li>*!/*/}
                                    {/*/!*    Actual window cutout: {this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height} inch**<br/>*!/*/}
                                    {/*/!*</li>*!/*/}
                                    <li>
                                        Standard 4-ply (1/16 inch) thick
                                    </li>
                                    <li>
                                        45 degree, crisp, white-core bevel
                                    </li>
                                    <li>
                                        Acid-free (acid-neutralized) surface
                                    </li>
                                    <li>
                                        Fade and bleed resistant
                                    </li>
                                    <li>
                                        Made and shipped from Canada
                                    </li>
                                    <br/>
                                    ** Please note that our window cutout is smaller than your artwork. Without the overlap, the artwork is going to fall through the opening.
                                </div>
                            </div>


                            <div className="select-mat-row" style={{paddingBottom: "4px", marginTop: "12px", width: "100%", borderBottom: "solid 1px #828282"}} onClick={() => this.setState({fetchShippingInfo: !this.state.fetchShippingInfo})}>
                                <img className="select-matboard-window-caption-arrow"
                                     style={{marginTop: "-2px", paddingTop: 0}}
                                     src={this.state.fetchShippingInfo ? arrowUp : arrowDown} alt="Click to add a note." />
                                <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b", fontSize: "13px", overflow: "auto"}}>
                                    Shipping & Returns
                                </div>
                                <div className={this.state.fetchShippingInfo ? "display" : "no-display"} style={{marginLeft: "40px"}}>
                                    <li>
                                        Free shipping across Canada & the USA
                                    </li>
                                    <li>
                                        We accept returns
                                    </li>
                                    <li>
                                        Dispatches within 1 business day
                                    </li>
                                    <li>
                                        Damage-resistant packaging
                                    </li>
                                    <li>
                                        If damaged while in transit, replacements mats will be sent
                                    </li>

                                </div>
                            </div>

                        </div>

                        <div style={{width: "auto", overflow: "auto", paddingBottom: "16px"}}>
                            {this.fetchAddToBagButton()}
                            {/*<div className="select-mat-container-row" style={{width: "auto"}}>*/}
                            {/*    Currency: <b>CAD</b><br/>*/}
                            {/*    Unit Price: <b>$<span>{this.state.frameSize[this.state.selectedFrameSize].priceCAD}</span></b>*/}
                            {/*</div>*/}
                        </div>


                    </div>
                </div>
                <div>
                    <div className={"product-desc-dividers"}>
                        <div className={"product-desc-dividers-header"}>Product Summary:<br/><br/></div>
                        <div>
                            {this.state.selectedFrameSize} inch acid-neutralized backing / mounting matboard in {this.state.matboardColourOptions[this.state.selectedMatColour].colourName} {this.props.location === "us" ? " color" : " colour"}.<br/><br/>
                            Mounting board, also known as mat board or matting board, is a type of paperboard that is often used in artwork as a backing or support for framing or displaying the artwork. You can use this item along with a regular matboard (with a cutout/window) or without it as a standalone mounting board.<br/><br/>
                            The main purpose of mounting board is to provide a flat and stable surface for the artwork to be attached to, as well as to provide a buffer between the artwork and the frame or glass. <br/><br/>
                            It also helps to protect the artwork from dust, moisture, and other environmental factors that could damage it over time.

                            There is no minimum order quantity. Free shipping across Canada & the USA. <br/><br/>
                            Quantity discounts are available for your order. Discounts will be reflected automatically as you order 2+, 4+, and 10+ orders. If you need more than 50+ sheets, please contact us for pricing. We ship to anywhere in Canada and the United States.<br/><br/>
                        </div>
                        <div className={"product-desc-dividers-header"}>
                            Product Specifications:
                        </div>
                        <div>
                            <ul>
                                <li>Overall Dimension: {this.state.selectedFrameSize} inch</li>
                                <li>Acid-free matboard: Acid neutralized surface, core, and backing papers for your preservation needs</li>
                                <li>Industry standard, 4 PLY, 1/16 inch thick</li>
                                <li>All our window openings are cut with 45 degree bevels</li>
                                <li>Clean and crisp cut</li>
                                <li>Made and processed in Canada</li>
                            </ul>
                        </div>
                    </div>

                    <div className={"product-desc-dividers"}>
                        <div className={"product-desc-dividers-header"}>
                            Other Notes:<br/><br/>
                        </div>
                        <div>
                            Our {this.state.selectedFrameSize} inch matboards are designed to fit {this.state.selectedFrameSize} industry standard sized frames that are sold by Amazon, Costco, IKEA, Target, Michael’s and other retail stores. {this.fetchIKEAFrameNames()}<br/><br/>
                        </div>
                    </div>

                    <div className={"product-desc-dividers"}>
                        <div className={"product-desc-dividers-header"}>
                            100% Satisfaction Policy:<br/><br/>
                        </div>
                        <div>
                            100% Happiness Guaranteed. If you are not happy with your order, please let us know. Contact us through hello@custommat.ca and we will make it right.<br/><br/>
                        </div>
                    </div>

                    <div className={"product-desc-dividers"}>
                        <div className={"product-desc-dividers-header"}>
                            Need further customization or more {this.props.location === "us" ? " color " : " colour "} options?<br/><br/>
                        </div>
                        <div>
                            Try our custom matboards. They are 100% customizable – your {this.props.location === "us" ? " color " : " colour "}, your shape, and your design. They come in 20+ {this.props.location === "us" ? " color " : " colour "} options.<br/><br/>
                        </div>
                    </div>


                    <div className={"product-desc-dividers"}>
                        <div className={"product-desc-dividers-header"}>
                            Any Questions?<br/><br/>
                        </div>
                        <div>
                            If you have any questions, please email us hello@CustomMat.ca or text us at 604•345•8428.<br/><br/>
                        </div>
                    </div>



                </div>
            </div>
        }


    };

    fetchHeadline() {
        if (this.props.french) {
            return <div>
                <h1 className={"inspiration-listing-header"}>
                    SUPPORT / FIXATION<br/>
                    PASSE-PARTOUTS
                </h1>
                <h2 className={"inspiration-listing-desc"}>
                    IL S'AGIT DE PASSE-PARTOUTS SANS DÉCOUPE.
                    <br className={"mobile-no-display"}/>
                    ILS SONT UTILISÉS POUR FIXER VOTRE ŒUVRE ET ÉVITER LES PLIS.
                    <br className={"mobile-no-display"}/>
                    <br/>
                    LIVRÉS CHEZ VOUS AU CANADA ET AUX ÉTATS-UNIS
                    <br className={"mobile-no-display"}/>
                    LIVRAISON RAPIDE ET RETOURS FACILES
                    <br className={"mobile-no-display"}/>
                    <br/>
                    VOUS POUVEZ LES UTILISER AVEC VOTRE PASSE-PARTOUT <br className={"mobile-no-display"}/>
                    OU POUR UN MONTAGE EN FLOATING ÉGALEMENT.
                </h2>
                <div style={{width: "100%", overflow: "auto", margin: "32px 0"}}>
                    {this.state.selectedFrameSize ? null : this.fetchPreCutMats()}
                </div>
            </div>
        } else {
            return <div>
                <h1 className={"inspiration-listing-header"}>
                    Backing / Mounting<br/>
                    Matboards
                </h1>
                <h2 className={"inspiration-listing-desc"}>
                    These are Matboards without an opening. <br className={"mobile-no-display"}/>
                    They are used to mount your artwork to prevent wrinkles. <br className={"mobile-no-display"}/>
                    <br/>
                    Delivered to your door in Canada & USA <br className={"mobile-no-display"}/>
                    Fast Shipping & Easy Returns <br className={"mobile-no-display"}/>
                    <br/>
                    You can use them with your matting <br className={"mobile-no-display"}/>
                    or for float mounting as well.
                </h2>
                <div style={{width: "100%", overflow: "auto", margin: "32px 0"}}>
                    {this.state.selectedFrameSize ? null : this.fetchPreCutMats()}
                </div>
            </div>
        }
    }

    render() {
        return <div>
            <Helmet>
                <title>
                    {this.props.french ? `Passe-partouts de soutien et de montage de ${this.state.selectedFrameSize ? this.state.selectedFrameSize : ""} pouces | Expédition rapide` :
                        `${this.state.selectedFrameSize ? this.state.selectedFrameSize : ""} Backing & Mounting Matboards | Fast Shipping`}
                </title>
                <meta name="description" content={
                    this.props.french ? `Découvrez les passe-partouts de soutien et de montage de ${this.state.selectedFrameSize} pouces en couleur ${this.state.selectedMatColour}. Expédition rapide avec livraison gratuite et retours faciles. Livraison partout au Canada et aux États-Unis. Idéaux pour prévenir les plis et monter des œuvres d'art. Utilisation avec un passe-partout ou pour un montage en flottaison.` :
                        `Explore ${this.state.selectedFrameSize} inch backing and mounting matboards in ${this.state.selectedMatColour} colour. Ships fast with free shipping and easy returns. Delivering across Canada & the USA. Ideal for preventing wrinkles and mounting artwork. Use with matting or for float mounting.`
                } />
            </Helmet>

            <div className={`precut-mat-container`}>

                <div className={this.state.selectedFrameSize ? "no-display" : "display"} style={{margin: "16px 0"}}>
                    {this.fetchHeadline()}

                </div>

                <div className={this.state.selectedFrameSize ? "display" : "no-display"}>
                    {this.state.selectedFrameSize ? this.fetchPreCutMatDetails() : null}
                </div>
            </div>

            <LandingPageReviewStars
                location = {this.props.location}
                french={this.props.french}
            />

            <InstagramLoader
                location = {this.props.location}
                french={this.props.french}
            />

            <ReviewComponent
                location = {this.props.location}
                french={this.props.french}
            />

            <SatisfactionTransition
                french={this.props.french}
                location = {this.props.location}
            />


            <LandingPageFAQ
                french={this.props.french}
                location = {this.props.location}
            />



        </div>
    }
}